import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { Link } from '@mui/material';
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Toolbar,

} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Cookies from 'universal-cookie';
import axios from 'axios';

import Instructors from '../instructors/Instructors';
import OnlineDates from '../onlineDates/OnlineDates';
import Messages from '../messages/Messages';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import FeelingLonely from '../feelingLonely/FeelingLonely';
import AvailableSlots from '../availableSlots/AvailableSlots';
import ShowInstructros from '../showInstructors/ShowInstructors';
import HeadingLogin from '../heading/HeadingLogin';
import Settings from '../settings/Settings';
import TeacherAddDates from '../addDates/TeacherAddDates';

const cookies = new Cookies();

export default function Navbar(props) {
  // const initialTab = props.index ? props.index : "0";
  // const toShowMessage = props.successMessage && props.successMessage.length > 0 ? true : false;
  const [selectedTab, setSelectedTab] = useState("0");
  const [showAvailableSlots, setShowAvailableSlots] = useState(false);
  const [responseCame, setResponseCame] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [datesBooked, setDatesBooked] = useState(false);
  const [balance, setBalance] = useState(0);

  const { state } = useLocation();
  //const { username, userEmail } = state;
  let navigate = useNavigate();

  useEffect(() => {
    console.log('getOnlineDatesLength');

    if (!cookies.get("rater")) {
      const data = {
        userEmail: cookies.get("email"),
        accessToken: cookies.get("access-token"),
      };

      if (data?.userEmail) {
        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDatesLength`, data)
          .then(res => {
            if (res.data.count && res.data.count > 0) {
              setResponseCame(true);
              setDatesBooked(true);
            } else {
              setResponseCame(true);
              setDatesBooked(false);
            }
          })
          .catch(error => console.log(error));

        axios.post(`${URL_PREFIX()}/datepracticals/getBalance`, data)
          .then(res => {
            const balance = res.data.balance;
            if (!isNaN(balance)) {
              setBalance(balance)
            }
          })
          .catch(error => console.log(error));
      }
    } else {
      const data = {
        teacherEmail: cookies.get("email"),
        accessToken: cookies.get("access-token"),
      };

      if (data?.teacherEmail) {
        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDatesLengthForTeacher`, data)
          .then(res => {
            if (res.data.count && res.data.count > 0) {
              setResponseCame(true);
              setDatesBooked(true);
            } else {
              setResponseCame(true);
              setDatesBooked(false);
            }
          })
          .catch(error => console.log(error));

        axios.post(`${URL_PREFIX()}/datepracticals/getBalanceForTeacher`, data)
          .then(res => {
            const balance = res.data.balance;
            if (!isNaN(balance)) {
              setBalance(balance)
            }
          })
          .catch(error => console.log(error));
      }

    }
  })

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    //props.setPropsNull(true, newValue);
  };

  const setAvailableSlots = (isAvailableSlots) => {
    setShowAvailableSlots(isAvailableSlots);
  }

  const selectInstructors = (event, newValue) => {
    setSelectedTab("0");
  };

  const selectMessages = (event, newValue) => {
    setSelectedTab("1");
  };

  const selectSettings = (event, newValue) => {
    setSelectedTab("3");
  };

  const setShowMessages = (suceessMessage) => {
    setShowMessage(suceessMessage);
  }


  return (
    <div>

      {responseCame &&
        datesBooked ?
        <>
          < HeadingLogin setAvailableSlots={setAvailableSlots} selectInstructors={selectInstructors} selectMessages={selectMessages} selectSettings={selectSettings} showMessage={showMessage}
            balance={balance} />
          <Divider />
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} indicatorColor="secondary">
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>My dates</Typography></span>} value={"0"} />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Messages</Typography></span>} value="1" />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Instructors</Typography></span>} value={"2"} />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Settings</Typography></span>} value="3" />
              </TabList >
            </Box>
            <TabPanel value={datesBooked ? "2" : "0"}><Instructors showAvailableSlots={showAvailableSlots} balance={balance ? balance : 0} /></TabPanel>
            <TabPanel value="1"><Messages /></TabPanel>
            <TabPanel value={datesBooked ? "0" : "2"}><OnlineDates setAvailableSlots={setAvailableSlots} /></TabPanel>
            <TabPanel value="3"><Settings /></TabPanel>
          </TabContext>
          {showAvailableSlots ?
            <ShowInstructros showDialog={showAvailableSlots} setAvailableSlots={setAvailableSlots} setShowMessages={setShowMessages} balance={balance ? balance : 0} /> : null}
        </>
        : <>
          <HeadingLogin setAvailableSlots={setAvailableSlots} selectInstructors={selectInstructors} selectMessages={selectMessages} selectSettings={selectSettings} showMessage={showMessage}
            balance={balance} />
          <Divider />
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} indicatorColor="secondary">
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Instructors</Typography></span>} value={"0"} />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Messages</Typography></span>} value="1" />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>My dates</Typography></span>} value={"2"} />
                <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Settings</Typography></span>} value="3" />
              </TabList >
            </Box>
            <TabPanel value={"2"}><OnlineDates setAvailableSlots={setAvailableSlots} /></TabPanel>
            <TabPanel value="1"><Messages /></TabPanel>
            <TabPanel value={"0"}><Instructors showAvailableSlots={showAvailableSlots} balance={balance ? balance : 0} /></TabPanel>
            <TabPanel value="3"><Settings /></TabPanel>
          </TabContext>
          {showAvailableSlots && !cookies.get("rater") ?
            <ShowInstructros showDialog={showAvailableSlots} setAvailableSlots={setAvailableSlots} setShowMessages={setShowMessages} balance={balance ? balance : 0} /> :
            <TeacherAddDates openAvailableSlots={showAvailableSlots} setAvailableSlots={setAvailableSlots} />}
        </>
      }
    </div >
  )
}