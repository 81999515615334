import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { Link } from '@mui/material';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Toolbar,

} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Instructors from '../instructors/Instructors';
import OnlineDates from '../onlineDates/OnlineDates';
import Messages from '../messages/Messages';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import FeelingLonely from '../feelingLonely/FeelingLonely';
import AvailableSlots from '../availableSlots/AvailableSlots';
import HeadingLogin from '../heading/HeadingLogin';
import Settings from '../settings/Settings';


export default function PaymentSuccessHomePage(props) {
    // const initialTab = props.index ? props.index : "0";
    // const toShowMessage = props.successMessage && props.successMessage.length > 0 ? true : false;
    const [selectedTab, setSelectedTab] = useState("0");
    const [showAvailableSlots, setShowAvailableSlots] = useState(false);
    const [showMessage, setShowMessage] = useState(true);

    const { state } = useLocation();
    //const { username, userEmail } = state;
    let navigate = useNavigate();

    useEffect(() => {

    })

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        //props.setPropsNull(true, newValue);
    };

    const setAvailableSlots = (isAvailableSlots) => {
        setShowAvailableSlots(isAvailableSlots);
    }

    const selectInstructors = (event, newValue) => {
        setSelectedTab("0");
    };

    const selectMessages = (event, newValue) => {
        setSelectedTab("1");
    };

    const selectSettings = (event, newValue) => {
        setSelectedTab("3");
    };

    const setShowMessages = (suceessMessage) => {
        setShowMessage(suceessMessage);
    }

    return (
        <div>
            <HeadingLogin setAvailableSlots={setAvailableSlots} selectInstructors={selectInstructors} selectMessages={selectMessages} selectSettings={selectSettings} showMessage={showMessage} />

            {/* <Grid container> */}
            {/* <AppBar style={{ background: 'white', marginTop: '60px' }} > */}
            <Divider />
            <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} indicatorColor="secondary">
                        {/* <Tab style={{ minWidth: '15%', width: '15%' }} label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Dating profile review</Typography></span>} />
            <Tab style={{ minWidth: '15%', width: '15%' }} label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Text Message Review</Typography></span>} />
            <Tab style={{ minWidth: '15%', width: '15%' }} label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Dating advice</Typography></span>} /> */}
                        <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Online dates</Typography></span>} value="0" />
                        <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Messages</Typography></span>} value="1" />
                        <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Dating practice</Typography></span>} value="2" />
                        {/* <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Dating profile review</Typography></span>} />
          <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Feeling lonely</Typography></span>} />
          <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Heart break</Typography></span>} /> */}
                        <Tab label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Settings</Typography></span>} value="3" />
                        {/* <Tab style={{ minWidth: '20%', width: '20%' }} label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Chat rooms</Typography></span>} /> */}
                        {/* <Tab style={{ minWidth: '20%', width: '20%' }} label={<span style={{ textTransform: 'capitalize', fontSize: 20 }}><Typography>Dating coaching</Typography></span>} /> */}
                    </TabList >
                </Box>
                <TabPanel value="0"><OnlineDates /></TabPanel>
                <TabPanel value="1"><Messages /></TabPanel>
                <TabPanel value="2"><Instructors showAvailableSlots={showAvailableSlots} /></TabPanel>
                <TabPanel value="3"><Settings /></TabPanel>
            </TabContext>
            {showAvailableSlots ?
                <AvailableSlots showDialog={showAvailableSlots} setAvailableSlots={setAvailableSlots} setShowMessages={setShowMessages} /> : null}
        </div >
    )
}