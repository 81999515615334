import React from "react";
import { withTheme, withMediaQuery } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
//import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
//import axios from 'axios';
//import { ReactCountryDropdown } from 'react-country-dropdown';
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Slider from '@mui/material/Slider';
//import DatePicker from "react-datepicker";
//import DatePicker from 'react-date-picker';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
//import moment from 'moment';

//import UploadImages from '../upload/Upload';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

import * as Chance from 'chance';

// import {


//     Grid,
//     Paper,
//     AppBar,
//     Typography,
//     Toolbar,

// } from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";


import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import { TroubleshootRounded } from "@mui/icons-material";

import { useTheme } from '@mui/material/styles';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import Heading from '../heading/Heading';

const cookies = new Cookies();
const chance = new Chance();

// const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
//     defaultMatches: true
// });

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
}));

const TenHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%',
    width: 20
}

const TenHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%',
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleEnableMobile = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginLeft: '30%',
    width: '95%',
    height: '50px'
}

const oneHourOr30MinTimeSelectStyleNormalMobile = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginLeft: '30%',
    width: '95%',
    height: '50px'
}

const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    //padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const teacherSignupNormal = {
    borderRadius: 35,
    backgroundColor: "#52BE80",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const priceMarks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 100,
        label: 100
    },
    {
        value: 150,
        label: 150
    }, {
        value: 200,
        label: 200
    }
]

const splitScreen = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}

const heading = {
    height: '10%',
}

const topPane = {
    height: '60%',
}

const backButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '8.5%',
}

const submitButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '9%',
}

const nextButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '10%',
}

class AddDates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            username: "",
            password: "",
            authflag: 1,
            years: [],
            age: 2003,
            country: 'US',
            birthday: new Date(),
            userEmail: '',
            disablePicturesretrieval: false,
            openUpload: false,
            pictures: [],
            aboutYou: '',
            screenVal: 0,
            priceSlider: 10,
            selectedWeekDays: [],
            selectedTimes: [],
            selectedTimesArray: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: [],
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            selectedWeekDays: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: [],
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showInstructorsProfile: false,
            rater: cookies.get("rater"),
            todayAvailableTimes: []
        };
    }

    componentDidMount() {
        this.isMobile = window.innerWidth <= 600;
        this.isLaptop = window.innerWidth >= 600 && window.innerWidth <= 1000;
        const { handleIsloginOrSignUpPage } = this.props;
        //handleIsloginOrSignUpPage(isloginOrSignUpPage);
        this.getInitialValues();
        if (!this.isMobile) {
            this.initializeValues();
        }
    }

    initializeValues = () => {
        this.getAvailableTimeSlots(moment());
        this.getBookedTimeSlots(moment());
    }

    getInitialValues = () => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: this.state.email
        }, () => {
        })
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today,
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = currentDay.diff(today, 'days');
            this.setState({
                currentDayDiff
            })

            this.setState({
                //availableTimesNew: this.getamPmArray(),
                availableTimes: this.getDates()
            }, () => {
                if (this.isMobile) {
                    this.setState({
                        availableTimesMobile: this.state.availableTimes[0],
                        selectedWeekDayMobile: weekArray[0],
                        selectedWeekDayMobileIndex: 0
                    }, () => {
                        this.getAvailableTimeSlotsForSelectedDay(moment());
                        this.getBookedTimeSlotsForSelectedDay(moment());
                    })
                }
            })
        })
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray(weekArray[i]);
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    // getDatesForOneDay = () => {
    //     const { weekArray } = this.state;
    //     const weekDayArrayMapping = [];
    //     for (let i = 0; i < 1; i++) {
    //         const map = {};
    //         map[weekArray[i]] = this.getamPmArray();
    //         weekDayArrayMapping.push(map);
    //     }
    //     console.log('dates ' + JSON.stringify(weekDayArrayMapping));
    //     return weekDayArrayMapping;
    // }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getTimeToday = () => {
        let amOrpmArray = [];
        const date = new Date();
        let currentHour = date.getHours();
        const currentMinutes = date.getMinutes();
        const amOrPm = currentHour >= 12 ? 'PM' : 'AM';

        if (amOrPm === 'PM') {
            if (currentHour !== "12") {
                currentHour = Number(currentHour) - 12;
            }

            if (currentHour === "12") {
                if (currentMinutes == 0) {
                    amOrpmArray.push(12 + ':00' + amOrPm);
                } else if (currentMinutes < 30) {
                    amOrpmArray.push(12 + ':30' + amOrPm);
                }
                currentHour = Number(currentHour) - 12;
            }

            let index = 0;
            for (let i = currentHour; i < 12; i++) {
                if (index === 0) {
                    if (currentMinutes == 0) {
                        amOrpmArray.push(i + ':00' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':00' + amOrPm);
                }
                if (index === 0) {
                    if (currentMinutes <= 30) {
                        amOrpmArray.push(i + ':30' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':30' + amOrPm);
                }
                index++;
            }
        } else {
            let index = 0;
            for (let i = currentHour; i < 12; i++) {
                if (index === 0) {
                    if (currentMinutes == 0) {
                        amOrpmArray.push(i + ':00' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':00' + amOrPm);
                }

                if (index === 0) {
                    if (currentMinutes <= 30) {
                        amOrpmArray.push(i + ':30' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':30' + amOrPm);
                }
                index++;
            }

            amOrpmArray.push(12 + ':00' + 'PM');
            amOrpmArray.push(12 + ':30' + 'PM');

            for (let i = 1; i < 12; i++) {
                amOrpmArray.push(i + ':00' + 'PM');
                amOrpmArray.push(i + ':30' + 'PM');
            }
        }

        this.setState({
            todayAvailableTimes: amOrpmArray
        })

        return amOrpmArray;
    }

    getTimeNew = (amOrpm) => {
        const amOrpmArray = [];
        let currentHours = new Date().getHours();
        let currentMinutes = new Date().getMinutes();

        if (currentHours < 12 && currentMinutes <= 0) {
            amOrpmArray.push(12 + ':00' + amOrpm);
        } else if (currentHours < 12 && currentMinutes <= 30) {
            amOrpmArray.push(12 + ':30' + amOrpm);
        }

        for (let i = 1; i < 12; i++) {
            if (currentHours < i && currentMinutes <= 0) {
                amOrpmArray.push(i + ':00' + amOrpm);
            }
            if (currentHours < i && currentMinutes <= 30) {
                amOrpmArray.push(i + ':30' + amOrpm);
            }
        }
        return amOrpmArray;
    }

    getamPmArray = (weekDay) => {
        if (this.checkCurrentDate(weekDay)) {
            const availableDates = this.getTimeToday();

            //adding empty spaces
            for (let i = availableDates.length - 1; i < 48; i++) {
                availableDates.push('-');
            }
            return availableDates;
        } else {
            const amArray = this.getTime('AM');
            const pmArray = this.getTime('PM');
            const amPmArray = amArray.concat(pmArray);
            this.setState({
                amPmArray
            })
            return amPmArray;
        }
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })


        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
        })
        this.setWeeklyTimesAndDays();
    }

    handle24HourButton = () => {
        let { twentyFourHourClick } = this.state;
        twentyFourHourClick = !twentyFourHourClick;
        this.setState({
            twentyFourHourClick,
        })

        if (twentyFourHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false
            })
        }
    }

    handle10HourButton = () => {
        let { tenHourClick } = this.state;
        tenHourClick = !tenHourClick;
        this.setState({
            tenHourClick,
        })

        if (tenHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle5HourButton = () => {
        let { fiveHourClick } = this.state;
        fiveHourClick = !fiveHourClick;
        this.setState({
            fiveHourClick,
        })

        if (fiveHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle3HourButton = () => {
        let { threeHourClick } = this.state;
        threeHourClick = !threeHourClick;
        this.setState({
            threeHourClick,
        })

        if (threeHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle2HourButton = () => {
        let { twoHourClick, } = this.state;
        twoHourClick = !twoHourClick;
        this.setState({
            twoHourClick,
        })

        if (twoHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    handleBookDateResponse = (res) => {
        console.log('handleBookDateResponse');
        this.props.showMessage(true);
        // this.props.showMessage(true);
        // let { username, password, lastname, videoLink, country, birthday, aboutYou, priceSlider, pictures,
        //     bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher, email, firstname, teacherEmail, selectedWeekDays } = this.state;
        // this.setState({
        //     dateBookingSuccessful: true,
        //     bookDate: true
        // }, () => {
        //     this.props.showMessage(true);
        //     setTimeout(() => {
        //         this.setState({
        //             selectedWeekDays: [],
        //             selectedTimes: []
        //         })
        //         this.props.setBookDates(false);
        //         // this.props.navigate("/home", {
        //         //     state: {
        //         //         username: firstname,
        //         //         userEmail: email,
        //         //         isloginOrSignUpPage: false
        //         //     }
        //         // });
        //     }, 1000);
        // })

    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            this.getAvailableTimeSlots(moment());
            this.getBookedTimeSlots(moment());
        })
    }

    getAvailableTimeSlots = (weekStartDate) => {
        this.props.setInstructorChange(false);
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: cookies.get("rater") ? cookies.get("email") : this.props.selectedTeacher?.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
            .then(res => this.handleAvailableTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    getAvailableTimeSlotsForSelectedDay = (date) => {
        this.props.setInstructorChange(false);
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: cookies.get("rater") ? cookies.get("email") : this.props.selectedTeacher?.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(date).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(date).add(1, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
            .then(res => this.handleAvailableTimeSlotResponseForSelectedDay(res, date))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleAvailableTimeSlotResponse = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    handleAvailableTimeSlotResponseForSelectedDay = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 1; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    handleClose = () => {
        this.setState({
            open: false,
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        })
    }

    getBookedTimeSlots = (weekStartDate) => {
        let { selectedTeacher } = this.state;

        const data = {
            accessToken: cookies.get("access-token"),
            email: cookies.get("rater") ? cookies.get("email") : this.props.selectedTeacher?.email,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
    }

    getBookedTimeSlotsForSelectedDay = (date) => {
        let { selectedTeacher } = this.state;

        const data = {
            accessToken: cookies.get("access-token"),
            email: cookies.get("rater") ? cookies.get("email") : this.props.selectedTeacher?.email,
            weekStartDate: new Date(moment(date).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(date).add(1, 'days').format('MM/DD/YYYY'))
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponseForSelectedDay(res, date))
            .catch(error => console.log(error));
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
        this.props.setInstructorChange(false);
    }

    handleBookedTimeSlotResponseForSelectedDay = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 1; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            // this.setState({
            //     availableTimes: this.getDates
            // })
        })
        this.props.setInstructorChange(false);
    }


    getAgeYears = () => {
        const { years } = this.state;
        for (let i = 1920; i < 2010; i++) {
            years.push(i);
        }
        this.setState({
            years
        })
    }

    getCountries = () => {
        let countries = countryList().getData();

        countries = countries.map((country, index) => {
            return country.value
        })

        this.setState({
            countries
        })
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    bookDatesForTeacher = () => {
        console.log('bookDate hit');
        this.props.setBookDates(false);
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;
        const { navigate } = this.props;

        let count = 0;
        for (let i = 0; i < selectedWeekDays.length; i++) {
            // for (let j = 0; j < selectedTimes.length; j++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                count++;
            }
        }

        //save teacher available dates and times
        let actualCount = 0;
        for (let i = 0; i < selectedWeekDays.length; i++) {
            // for (let j = 0; j < selectedTimes.length; j++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                actualCount++;
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

                const teacherData = {
                    teacherName: cookies.get("username"),
                    teacherEmail: cookies.get("email"),
                    createdDate: new Date(),
                    availableDate: bookedDate,
                    availableTimes: selectedTimes[i],
                    accessToken: cookies.get("access-token"),
                }

                axios.post(`${URL_PREFIX()}/datepracticals/saveTeacherAvailableDates`, teacherData)
                    .then(res => this.rediretToParentMessage(count, actualCount))
                    .catch(error => console.log('error'));
            }
        }
        console.log('all booked');
    }

    handleBookDateStudentLengthResponse = (res) => {
        const orderId = res?.data.bookDatesLength;
        this.handleBookDates(orderId);
    }

    bookDateForStudent = () => {
        console.log('bookDateForStudent hit');
        this.props.setBookDates(false);
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate, existingOrderId } = this.state;
        const { navigate } = this.props;

        if (selectedTimes && selectedTimes.length > 1) {
            axios.get(`${URL_PREFIX()}/datepracticals/bookDatesLength`)
                .then(res => this.handleBookDateStudentLengthResponse(res))
                .catch(error => console.log(error));
        } else {
            this.handleBookDates();
        }
    }

    handleBookDates = (orderId) => {
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;
        let actualCount = 0;
        let count = 0;

        for (let i = 0; i < selectedWeekDays.length; i++) {
            // for (let j = 0; j < selectedTimes.length; j++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                count++;
            }
        }

        for (let i = 0; i < selectedWeekDays.length; i++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                actualCount++;
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));
                const selectedTimeFirst = selectedTimes ? selectedTimes[i][0] : null;
                let bookedDateTime = new Date(bookedDate);

                if (selectedTimeFirst) {
                    const selectedTimeFirstLength = selectedTimeFirst.length;
                    const noOfHours = 0;
                    const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
                    const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
                    let selectedHour = selectedTimeFirstHoursMinutesArray[0];
                    const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

                    if (isAmOrPm === 'PM') {
                        selectedHour = Number(selectedHour) + 12;
                    }
                    bookedDateTime.setHours(selectedHour);
                    bookedDateTime.setMinutes(Number(selectedMinutes));
                }

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[i][0];

                const reminderDateTime = this.getReminderDateTime(bookedDate, selectedTimes[i]);

                this.setState({
                    bookDate,
                    dateBookingMessage
                })

                const bookingDateAndTime = {
                    userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
                    teacherName: this.props.selectedTeacher.firstname,
                    userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
                    teacherEmail: this.props.selectedTeacher.email,
                    bookedDate: bookedDate,
                    bookedDateTime: bookedDateTime,
                    reminderDateTime: reminderDateTime,
                    bookedTimes: selectedTimes[i],
                    createdDate: moment(),
                    roomId: chance.guid(),
                    price: this.props.selectedTeacher ? this.props.selectedTeacher.priceSlider : 10,
                    accessToken: cookies.get("access-token"),
                    orderId: orderId && orderId > 0 ? orderId : null
                }

                axios.post(`${URL_PREFIX()}/datepracticals/bookDate`, bookingDateAndTime)
                    .then(res => this.handleBookDateStudentResponse(res, count, actualCount))
                    .catch(error => console.log(error));
            }
        }
    }

    getReminderDateTime = (bookedDate, selectedTimes) => {
        const bookedDateArray = bookedDate?.toString().split(" ");
        let reminderDate = bookedDateArray ? bookedDateArray[1] + " " + bookedDateArray[2] : '';
        //for 30 minute session add first value as second too
        if (selectedTimes && selectedTimes.length === 1) {
            selectedTimes.push(selectedTimes[0])
        }
        const selectedTimesZeroArray = selectedTimes[0]?.split(":");
        const selectedTimesOneArray = selectedTimes[1]?.split(":");
        const timeString = selectedTimes[0] + " - " + (selectedTimesOneArray[0] === '12' ? 1 : Number(selectedTimesZeroArray[0]) + 1) + ":" + selectedTimesZeroArray[1];
        reminderDate = reminderDate + " " + timeString;
        return reminderDate;
    }

    handleBookDateStudentResponse = (res, count, actualCount) => {
        // if (res.data.orderId) {
        //     this.setState({
        //         existingOrderId: res.data.orderId
        //     })
        // }
        if (count === actualCount) {
            const id = res?.data?.orderId ? res.data.orderId : null;
            const teacherName = res?.data?.teacherName ? res.data.teacherName : null;
            const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;
            const price = res?.data?.price ? res.data.price * actualCount : null;
            const hourCost = res?.data?.price ? res.data.price : null;
            const balance = this.props.balance;

            this.props.navigate(`/beforePayment/${id}/${teacherName}/${price}/${teacherEmail}/${hourCost}/${balance}`, {});
            this.setState({
                dateBookingSuccessful: true,
                bookDate: true
            })
        }
    }

    rediretToParentMessage = (count, actualCount) => {
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;

        if (count === actualCount) {
            this.handleBookDateResponse();
        }
    }

    handleSubmit = (event) => {
        const { navigate } = this.props;
        const { username, password, firstname, lastname, videoLink, country, birthday, aboutYou, priceSlider, email, pictures } = this.state;
        const data = {
            username: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            email: email,
            rater: true,
            videoLink: videoLink,
            country: country,
            birthday: birthday,
            aboutYou: aboutYou,
            priceSlider: priceSlider
        }

        this.bookDate();

        if (pictures && pictures.length > 0) {
            this.upload(data);
        } else {
            this.handleResponse();
        }
    }

    handleBackButton = () => {
        let { screenVal } = this.state;

        if (screenVal > 0 && screenVal < 4) {
            screenVal -= 1;
        }

        this.setState({
            screenVal
        })
    }

    handleNextButton = () => {
        let { screenVal } = this.state;

        if (screenVal >= 0 && screenVal < 3) {
            screenVal += 1;
        }

        this.setState({
            screenVal
        })
    }

    handleAvailableSlotsDialog = () => {
        this.setState({
            openAvailableSlots: true,
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        })
    }

    handleCloseAvailableSlots = () => {
        this.props.setAvailableSlots(false);
        // this.setState({
        //     openAvailableSlots: false
        // })
    }

    showMessage = () => {
        console.log('showMessage');
    }

    checkCurrentTimeBefore = (availableTime) => {
        let { selectedTimes, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;

        const timeValArray = availableTime.split("-");
        const selectedWeekDay = timeValArray[0];
        const time = timeValArray[1];

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY hh:mm:ss'));
        const selectedTimeFirst = time;
        let bookedDateTime = new Date(bookedDate);

        if (selectedTimeFirst) {
            const selectedTimeFirstLength = selectedTimeFirst.length;
            const noOfHours = 0;
            const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
            const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
            let selectedHour = selectedTimeFirstHoursMinutesArray[0];
            const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

            if (isAmOrPm === 'PM') {
                if (selectedHour !== "12") {
                    selectedHour = Number(selectedHour) + 12;
                }
            } else {
                if (selectedHour === "12") {
                    selectedHour = "0";
                }
            }
            bookedDateTime.setHours(selectedHour);
            bookedDateTime.setMinutes(Number(selectedMinutes));
            bookedDateTime.setSeconds(0);

            const isBefore = new Date().getTime() > bookedDateTime.getTime();
            return isBefore;
        }
    }

    checkCurrentDate = (availableTime) => {
        let { selectedTimes, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;
        const timeValArray = availableTime.split("-");
        const selectedWeekDay = timeValArray[0];
        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        const isToday = bookedDateNoFormat.date() === new Date().getDate();
        return isToday;
    }

    //get the available dates for the selected day
    handleWeekSelection = (weekday, state) => {
        const { weekArray, today, availableTimes } = state;

        this.setState({
            selectedWeekDayMobile: weekday
        })

        for (let i = 0; i < 7; i++) {
            if (weekArray[i] === weekday) {
                let currentDay = Object.assign(moment(today).add(i, 'days'));
                this.setState({
                    availableTimesMobile: availableTimes[i],
                    selectedWeekDayMobileIndex: i
                }, () => {
                    this.getAvailableTimeSlotsForSelectedDay(currentDay);
                    this.getBookedTimeSlotsForSelectedDay(currentDay);
                })
                break;
            }
        }
    }

    getAvailableTimes = (weekday) => {
        let availableDates = [];
        const { availableTimesNew } = this.state;

        if (this.checkCurrentDate(weekday)) {
            availableDates = this.getTimeToday();
        } else {
            availableDates = availableTimesNew;
        }
        return availableDates;
    }

    render() {
        const { years, age, country, countries, birthday, userEmail, openUpload, screenVal, email, selectedWeeklyDays, selectedWeeklyTimes, openAvailableSlots,
            review, openLogin, bioReview, open, weekArray, weekDayArrayMapping, availableTimes, fiveHourClick, threeHourClick, tenHourClick, selectedWeekDays, twentyFourHourClick,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            twoHourClick, rater, availableTimeSlotDict, selectedWeekDayMobile, availableTimesMobile, selectedWeekDayMobileIndex, amPmArray, availableTimesNew } = this.state;

        if (this.props.bookDates) {
            if (rater) {
                this.bookDatesForTeacher();
            } else {
                this.bookDateForStudent();
            }
        }

        if (this.props.instructorChange) {
            this.initializeValues();
        }

        return (
            <>
                <div>
                    <Grid container>
                        <Grid item xs={12} md={5} lg={5}>
                            <Stack direction="row" spacing={2} style={!dateBookingSuccessful ? { marginTop: '0px' } : null}>
                                <Button variant="outlined" onClick={this.bringPreviousWeek} disabled={currentDayDiff <= 0}>
                                    {"<"}
                                </Button>
                                <span style={{ marginLeft: '8px', fontWeight: 'bold', }}>
                                    {todayDate + " - " + weekAfterDate + ", " + weekAfterYear}
                                </span>
                                <Button variant="outlined" onClick={this.bringNextWeek} style={{ marginLeft: '8px' }}>
                                    {">"}
                                </Button>
                            </Stack>
                        </Grid>
                        {rater ?
                            <>
                                <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                                    <Button variant="outlined" style={twentyFourHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle24HourButton}>
                                        {"24hour"}
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                                    <Button variant="outlined" style={tenHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle10HourButton}>
                                        {"10hour"}
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                                    <Button variant="outlined" style={fiveHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle5HourButton}>
                                        {"5 hour"}
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                                    <Button variant="outlined" style={threeHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle3HourButton}>
                                        {"3 hour"}
                                    </Button>
                                </Grid>
                                <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                                    <Button variant="outlined" style={twoHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle2HourButton}>
                                        {"2 hour"}
                                    </Button>
                                </Grid>
                            </>
                            : null}
                        <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                            <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                {"1 hour"}
                            </Button>
                        </Grid>
                        <Grid item style={{ marginTop: '0.0%', marginRight: '3px' }}>
                            {/* <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                {"30 min"}
                            </Button> */}
                        </Grid>
                    </Grid>

                    {!this.isMobile ?
                        <>
                            <Stack direction="row" spacing={2}>
                                {availableTimes.map((i, index) => (
                                    <React.Fragment>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Item>
                                                    {weekArray[index]}
                                                </Item>
                                            </Grid>
                                            <br />
                                        </Grid>
                                    </React.Fragment>
                                ))
                                }
                            </Stack>
                            <Stack direction="row">
                                {availableTimes.map((i, index) => (
                                    <React.Fragment>
                                        <Grid container>

                                            {i[weekArray[index]].map((time, ind) => (
                                                <>
                                                    <span style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Grid item xs={12}>
                                                            <br />
                                                            <Button variant="outlined" color='secondary'
                                                                disabled={(!(availableTimeSlotDict && availableTimeSlotDict[index] && availableTimeSlotDict[index].indexOf(time) !== -1)) ||
                                                                    (bookedTimeSlotDict && bookedTimeSlotDict[index] && bookedTimeSlotDict[index].indexOf(time) !== -1)}
                                                                style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[index] && (selectedWeeklyTimes[index].indexOf(time) > -1
                                                                    && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[index] && selectedWeeklyDays[index] === weekArray[index]) ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal}
                                                                onClick={this.handleTimeSelection} value={weekArray[index] + "-" + time + "-" + index}>
                                                                {time}
                                                            </Button>
                                                        </Grid>
                                                    </span>
                                                </>
                                            ))}
                                        </Grid>
                                    </React.Fragment>
                                ))
                                }
                            </Stack>
                        </>
                        :
                        <>
                            <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>
                                <>
                                    {weekArray && weekArray.map((i, index) => (

                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Item onClick={() => this.handleWeekSelection(weekArray[index], this.state)} value={weekArray[index]}>
                                                    <span style={{ cursor: 'pointer' }} onClick={() => this.handleWeekSelection(weekArray[index], this.state)} value={weekArray[index]}>{weekArray[index]}</span>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    ))
                                    }
                                </>
                            </Stack>
                            <br />
                            {availableTimesMobile && availableTimesMobile[selectedWeekDayMobile] && availableTimesMobile[selectedWeekDayMobile].map((time, index) => (
                                <>
                                    <span style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {/* <Grid item xs={1}>

                                        </Grid> */}
                                        <Grid item xs={10}>
                                            <br />
                                            <Button variant="outlined" color='secondary'
                                                disabled={(!(availableTimeSlotDict && availableTimeSlotDict[0] && availableTimeSlotDict[0].indexOf(time) !== -1)) ||
                                                    (bookedTimeSlotDict && bookedTimeSlotDict[0] && bookedTimeSlotDict[0].indexOf(time) !== -1)}
                                                style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[selectedWeekDayMobileIndex] && (selectedWeeklyTimes[selectedWeekDayMobileIndex].indexOf(time) > -1
                                                    && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[selectedWeekDayMobileIndex] && selectedWeeklyDays[selectedWeekDayMobileIndex] === weekArray[selectedWeekDayMobileIndex]) ? oneHourOr30MinTimeSelectStyleEnableMobile : oneHourOr30MinTimeSelectStyleNormalMobile}
                                                onClick={this.handleTimeSelection} value={weekArray[selectedWeekDayMobileIndex] + "-" + time + "-" + selectedWeekDayMobileIndex}>
                                                {time}
                                            </Button>
                                        </Grid>
                                    </span>
                                </>
                            ))
                            }
                        </>
                    }
                </div >
            </>
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <AddDates {...props} navigate={navigate} />;
}
