import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

import OnlineDates from '../onlineDates/OnlineDates';

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import * as Chance from 'chance';

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';


const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

const chance = new Chance();

class FeelingLonely extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            review: {
                imageTitle: '',
                email: '',
                userEmail: '',
                isProfilePic: false,
                attractivenessSliderVal: 5,
                confidenceSliderVal: 5,
                charmingSliderVal: 5,
                responsibleSliderVal: 5,
                funSliderVal: 5,
                intelligentSliderVal: 5,
                willingToDateSliderVal: 5,
                picOrderSliderVal: 0,
                needtoLookAtCamera: false,
                toSmile: false,
                toDelete: false,
                tooMuchFiltering: false,
                needToLookAtCamera: false,
                isLightingBad: false,
                isBadSelfie: false,
                toRecommendOthers: false,
                phoneNoOrEmail: '',
                suggestions: '',
                pictureEffectivenessSliderVal: 0,
                looksTooYoung: false,
                getHairCut: false,
                trimYourBeard: false,
                niceGuyVibe: false,
                reduceWeight: false
            },
            bioReview: {
                imageTitle: '',
                email: '',
                userEmail: '',
                promptEffectivenessSliderVal: 0,
                showsPersonality: false,
                isShortBio: false,
                wantToDate: false,
                notReleatedAnswer: false,
                deleteThisPrompt: false,
                gramatticalMistakes: false,
                difficultToUnderstand: false,
                boringAnswer: false,
                tooLongAnswer: false,
                deletePolitics: false,
                deleteReligion: false,
                whatYouAreLookingFor: false,
                hobbiesAndInterests: false,
                racistVibe: false,
                goldDiggerVibe: false,
                looksTooOld: false,
                givingTooMuchInfo: false,
                workOnYourself: false,
                mentalHealthIssues: false,
                bioSuggestions: '',

            },
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: []
        }
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        // //var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;       
        // // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // // if (this.videoRef.current) {
        // //     this.videoRef.current.srcObject = stream;
        // // }

        // const { state } = this.props.location;
        // //const { handleIsloginOrSignUpPage, navigate } = this.props;
        // const { handleIsloginOrSignUpPage, navigate } = this.props;

        // if (state) {
        //     if (state && state.username) {
        //         const { username, email } = state;
        //         this.setState({
        //             username,
        //             email
        //         }, () => {
        //             //this.retrieveImages(email);
        //             //this.getImageList(email);
        //         })
        //         console.log(`username is ${username}`);
        //         console.log(`username is ${email}`);
        //     } else {
        //         this.setState({
        //             username: cookies.get("username")
        //         })
        //     }
        //     //handleIsloginOrSignUpPage(state.isloginOrSignUpPage);
        // } else {
        //     this.setState({
        //         openLogin: true
        //     })
        //     //navigate("/login");
        // }
        // this.getTeachers();
        // this.getOnlineDates();
        // //this.getInitialValues();
    }

    getOnlineDates = () => {
        console.log('retrieving images list');
        const data = {
            userEmail: this.props?.location?.state?.userEmail
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/getOnlineDates", data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    handleOnlineDatesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((data) => {
            uniqueRaters.push(data);
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            datesArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveDateFirstImage(rater.teacherEmail, index)
            ));
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    getInitialValues = () => {
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        })

        const currentDay = moment();
        const currentDayDiff = currentDay.diff(today, 'days');
        this.setState({
            currentDayDiff
        })
    }

    getImageList = (emailToRetrieve) => {
        console.log('retrieving images list');
        const data = {
            email: emailToRetrieve
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/pictures", data)
            .then(res => this.handleImageResponse(res))
            .catch(error => console.log(error));
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value
        })
    }

    getRaters = () => {
        console.log('retrieving images list');
        const data = {
            //email: emailToRetrieve
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/getRaters", data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    getTeachers = () => {
        console.log('retrieving images list');
        const data = {
            //email: emailToRetrieve
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/getTeachers", data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    handleRatersResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((rater) => {
            if (uniqueratersEmail.indexOf(rater.email) === -1) {
                uniqueratersEmail.push(rater.email);
                uniqueRaters.push(rater)
            }
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(rater.email, index)
            ));
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveDateFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveFirstImage", data, { responseType: 'blob' })
            .then(res => this.handleDateFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleDateFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'dateimageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveFirstImage", data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveImage", data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveReview", data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    retrieveBioReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveBioReview", data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleNextButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber < imageList.length - 1) {
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: false
                });
            }
            imageNumber += 1;
            this.setState({
                imageNumber
            });

            if (imageNumber === imageList.length - 1) {
                this.setState({
                    removeNextButton: true
                });
            }
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeNextButton: true
            });
        }
    };

    handleBackButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber >= 0) {
            imageNumber -= 1;
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: true
                });
            }
            if (imageNumber < imageList.length - 1) {
                this.setState({
                    removeNextButton: false
                });
            }
            this.setState({
                imageNumber
            });
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeBackButton: true
            });
        }
    };

    handleDisablePicturesRetrieval = (picRetrieve) => {
        this.setState({
            disablePicturesretrieval: picRetrieve
        })
    }

    handleChange = (key, event) => {
        this.setState({
            [key]: event.target.value
        });
    };

    handleReviewChange = (key, event) => {
        const review = { ...this.state.review };
        review[key] = event.target.value;

        this.setState({
            review
        });
    };

    handleBioReviewChange = (key, event) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = event.target.value;

        this.setState({
            bioReview
        });
    };

    valueText = (value) => value;

    changeSliderVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewPhoneNoChange = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewSubmitButton = () => {
        const { imageList, imageNumber, review, email } = this.state;
        const imageTitle = imageList[imageNumber];
        review.imageTitle = imageTitle?.title;
        review.email = email;
        review.userEmail = imageTitle?.email;

        const data = {
            review: review
        };
        console.log('review Json is ', JSON.stringify(review));

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/postReview", data)
            .then(res => this.handleReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponse = (res) => {
        console.log('after review submit', res);
        const { review } = this.state;
        const { userEmail, imageTitle } = review;

        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveReview", data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponseRes = (res) => {
        console.log('after review submit', res);
        this.setState({
            review: res.data
        })
    }

    handleBioReviewSubmitButton = () => {
        const { imageList, imageNumber, bioReview, email } = this.state;
        const imageTitle = imageList[imageNumber];
        bioReview.imageTitle = imageTitle?.title;
        bioReview.email = email;
        bioReview.userEmail = imageTitle?.email;

        const data = {
            bioReview: bioReview
        };

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/postBioReview", data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    // handleBioReviewSubmitRes = (res) => {
    //     console.log('after review submit', res);
    //     const { bioReview } = this.state;
    //     const { userEmail, imageTitle } = bioReview;

    //     const data = {
    //         userEmail: userEmail,
    //         imageTitle: imageTitle
    //     };

    //     axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/retrieveBioReview", data)
    //         .then(res => this.handleBioReviewSubmitResponse(res))
    //         .catch(error => console.log(error));       
    // }

    handleBioReviewSubmitResponse = (res) => {
        console.log('after bio review submit', res);

        this.setState({
            bioReview: res.data
        })
    }

    bioChipClick = (e, key) => {
        const bioReview = { ... this.state.bioReview };
        let bioReviewKey = !bioReview[key];
        bioReview[key] = bioReviewKey;

        this.setState({
            bioReview: bioReview
        })
    }

    reviewChipClick = (e, key) => {
        const review = { ... this.state.review };
        let reviewKey = !review[key];
        review[key] = reviewKey;

        this.setState({
            review: review
        })
    }

    changeBioSliderVal = (key, val) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = val;

        this.setState({
            bioReview
        });
    }

    changePicSliderPicVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray();
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = () => {
        const amArray = this.getTime('AM');
        const pmArray = this.getTime('PM');
        const amPmArray = amArray.concat(pmArray);
        this.setState({
            amPmArray
        })
        return amPmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })


        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
        })
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
            selectedTimes: []
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
            selectedTimes: []
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false
            })
        }
    }

    handleTimeSelection = (e) => {
        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];
        const time = timeValArray[1];
        let { oneHourClick, amPmArray, selectedTimes } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        let selectedTimesArray = [];

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        }

        this.setState({
            selectedTimes: selectedTimesArray,
            selectedWeekDay: selectedWeekDay
        })



    }

    bookDate = () => {
        let { bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;
        bookDate = !bookDate;

        if (bookDate) {
            this.setState({
                cancelDate: false,
                dateBookingSuccessful: false,
            })
        }

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY'));
        //let todayDate = new Date(moment(today).format('MM/DD/YYYY'));
        let dateArray = bookedDateNoFormat.toString().split(" ");
        let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

        this.setState({
            bookDate,
            dateBookingMessage
        })

        const bookingDateAndTime = {
            userName: this.props.location.state.username,
            teacherName: selectedTeacher.firstname,
            userEmail: this.props.location.state.userEmail,
            teacherEmail: selectedTeacher.email,
            bookedDate: bookedDate,
            bookedTimes: selectedTimes,
            createdDate: moment(),
            roomId: chance.guid()
        }

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/bookDate", bookingDateAndTime)
            .then(res => this.handleBookDateResponse(res))
            .catch(error => console.log(error));
    }

    handleBookDateResponse = (res) => {
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            this.getAvailableTimeSlots(moment());
            this.getBookedTimeSlots(moment());
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleReviewOpen = () => {
        this.setState({
            reviewOpen: true,
            reviewSliderVal: 1,
            reviewPostingSuccessful: false
        })
    }

    handleReviewClose = () => {
        this.setState({
            reviewOpen: false
        })
    }

    postReview = () => {
        let { reviewText, reviewSliderVal } = this.state;
        console.log('review text ', reviewText);

        const review = {
            userName: 'sarat',
            teacherName: 'celina',
            userEmail: 'sarat.43@gmail.com',
            teacherEmail: 'celina.lemir@gmail.com',
            reviewText: reviewText,
            createdDate: moment(),
            rating: reviewSliderVal
        }

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/reviewPost", review)
            .then(res => this.handlePostReviewResponse(res))
            .catch(error => console.log(error));
    }

    valuetext = (value) => {
        // this.setState({
        //     reviewSliderVal: value
        // })
        return value;
    }

    handlePostReviewResponse = (res) => {
        this.setState({
            reviewPostingSuccessful: true
        })
    }

    handleReviewSliderChange = (e) => {
        this.setState({
            reviewSliderVal: e.target.value
        })
    }

    getReviews = () => {
        const data = {
            teacherEmail: "celina.lemir@gmail.com"
        }

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/getReviews", data)
            .then(res => this.handleReviewsResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewsResponse = (res) => {
        this.setState({
            reviewsArray: res.data,
            reviewsOpen: true
        })
    }

    handleReviewsClose = () => {
        this.setState({
            reviewsOpen: false
        })
    }

    handleVideoOpen = (index) => {
        const { selectedIndex, ratersArray } = this.state;
        this.setState({
            videoOpen: true,
            videoUrl: ratersArray[index].videoLink
        })
    }

    handleVideoClose = () => {
        this.setState({
            videoOpen: false
        })
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }

    retrieveDateImageUrl = (index) => {
        return this.state['dateimageUrl' + index];
    }

    retrieveCountryLogo = (index) => {
        const { ratersArray } = this.state;

        return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + ratersArray[index].country + ".svg";
    }

    retrieveVideoUrl = () => {
        const { selectedIndex, ratersArray } = this.state;

        this.setState({
            videoUrl: ratersArray[selectedIndex].videoLink
        })
        //return ratersArray[selectedIndex].videoLink;
    }

    getAvailableTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/availableTimeSlots", data)
            .then(res => this.handleAvailableTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post("https://datepracticalsbackend-bae2144a0f02.herokuapp.com/datepracticals/bookedTimeSlots", data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    handleAvailableTimeSlotResponse = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    getOnlineDateMonth = (bookedDate) => {
        const date = new Date(bookedDate);
        let month = date.toLocaleString('default', { month: 'long' });
        return month.substring(0, 3);
        //return 'hi';
    }

    getOnlineDateDate = (bookedDate) => {
        const date = new Date(bookedDate);
        //return 'hi';
        return date.getDate();
    }

    getOnlineDateDay = (bookedDate) => {
        const date = new Date(bookedDate);
        const weekday = date.toLocaleString('default', { weekday: 'long' });
        //return 'hi';
        return weekday;
    }

    render() {
        const { email, username, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, datesArray, availableTimeSlotDict
        } = this.state;
        const { isProfilePic, attractivenessSliderVal, confidenceSliderVal, charmingSliderVal, responsibleSliderVal,
            funSliderVal, intelligentSliderVal, willingToDateSliderVal, picOrderSliderVal, needtoLookAtCamera, toSmile, toDelete,
            tooMuchFiltering, isLightingBad, isBadSelfie, phoneNoOrEmail, suggestions, pictureEffectivenessSliderVal, looksTooYoung, getHairCut, trimYourBeard, niceGuyVibe, reduceWeight } = review;

        const { promptEffectivenessSliderVal, showsPersonality, isShortBio, wantToDate, notReleatedAnswer, deleteThisPrompt, replaceThisPrompt, gramatticalMistakes,
            difficultToUnderstand, boringAnswer, tooLongAnswer, deletePolitics, deleteReligion, whatYouAreLookingFor, hobbiesAndInterests, racistVibe,
            goldDiggerVibe, looksTooOld, givingTooMuchInfo, workOnYourself, mentalHealthIssues, bioSuggestions,
            buildMuscles, museumsAndEvents, goToYogaClasses, goOut, goToFitnessGroupClasses, getACoach, goToBars, joinsalsaClasses,
            dontTalk, askQuestions, lightlyFlirt, workOnYourConfidence, goToLibraries, askPhoneNo, showFunSide, beAuthentic, approachMoreWomen, getAJob,
            workOnCareer, loveYourself, getATherapist, getMoreFriends, helpPeople, beVulnerable, connectWithPeople, celebrateYourLife, workOnSpiritualSide,
            workOnSelfEsteem, practiceMeditation
        } = bioReview;

        return (
            <div  >
                <Grid container>
                    <Grid item xs={12} style={{ fontSize: 18, fontWeight: 'bold' }}>
                        In development
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <FeelingLonely {...props} location={location} navigate={navigate} />;
}
