import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {

  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,

} from "@material-ui/core";

import Stack from '@mui/material/Stack';
import Rating from "@mui/material/Rating";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Chance from 'chance';
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';

import CheckoutForm from "../checkoutForm/CheckoutForm";
import HeadingLogin from '../heading/HeadingLogin';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

const cookies = new Cookies();

const reviewStyleNormal = {
  borderRadius: 35,
  backgroundColor: "white",
  color: '#FFC300',
  padding: "1px 6px",
  fontSize: "12px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

function Payment({ }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  //const [teacherImageUrl, setTeacherImageUrl] = useState(null);
  const chance = new Chance();
  const location = useLocation();

  const loginPageLink = window.location.href.toString();
  const splitArray = loginPageLink.split("/paymentSubscription/");
  const splitBlobArray = loginPageLink.split("blob");
  const splitDetailsArray = splitArray[1].split("/");

  const id = splitDetailsArray ? splitDetailsArray[0] : null;
  const teacherName = splitDetailsArray ? splitDetailsArray[1] : null;
  const price = splitDetailsArray ? splitDetailsArray[2] : null;
  const teacherEmail = splitDetailsArray ? splitDetailsArray[3] : null;
  const hourCost = splitDetailsArray ? splitDetailsArray[4] : null;
  const teacherImageUrl = splitBlobArray ? "blob" + splitBlobArray[1] : null;
  const isMobile = window.innerWidth <= 600;
  const isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
  const isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
  const isDesktop = window.innerWidth >= 1300;

  useEffect(() => {
    fetch(`${URL_PREFIX()}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch(`${URL_PREFIX()}/create-payment-intent`, {
      method: "POST",
      body: JSON.stringify({ price: price, isProd: !window.location.href.toString().includes('localhost') }),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  return (
    <div>
      {/* <h1>React Stripe and the Payment Element</h1> */}
      <HeadingLogin />
      <br />
      <br />

      <Box sx={{
        width: "100%",
        height: 300,
        padding: "2%",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 4,

      }} >
        <Grid container>
          {!isMobile ?
            <Grid item xs={0} sm={6} md={3} lg={3}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={{ position: 'relative' }}>
                    <img src={teacherImageUrl} style={
                      {
                        width: '100%',
                        height: 180,
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}></img>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            : null}
          <br />
          {!isMobile ?
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span style={{ marginLeft: '1.5rem', marginBottom: '1.5 rem', textTransform: 'capitalize', }}>{teacherName}</span>
                  {/* <span style={{ marginLeft: '0.5rem', marginBottom: '1.8%', }}>
                    <img
                      alt="United States"
                      src={this.retrieveCountryLogo(index)}
                      style={{ width: 20, height: 14, marginTop: '1.5 rem' }} /></span> */}
                </Grid>
                <Grid item xs={12} lg={12} md={12} style={{ marginLeft: '1.5rem' }}>
                  <Grid container>
                    <Grid item xs={4} lg={5} style={{}}>
                      <Stack direction="row" spacing={2}>
                        <Button color={'primary'}
                          variant='contained' style={reviewStyleNormal}>
                          <Grid container>
                            <Grid item xs={7}>
                              <Rating name="half-rating" defaultValue={5} precision={0.5} max={1} />
                            </Grid>
                            <Grid item xs={4} style={{ fontWeight: 'bold', color: '#FFC300', fontSize: 16, fontWeight: 'bold', }}>
                              5.0
                            </Grid>
                          </Grid>
                        </Button>
                        {!isMobile ?
                          <span style={isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '3%', marginTop: '6px', fontSize: 15 }}>${hourCost}/hour</span>
                          : null}
                      </Stack>
                    </Grid>
                    {isMobile ?
                      <Grid item xs={12} md={5} lg={5} style={isMobile ? { marginTop: '11%' } : { marginLeft: '3%', marginTop: '6px' }}>
                        <span style={isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '4px', marginTop: '6px', fontSize: 15 }}>${hourCost}/hour</span>
                      </Grid>
                      : null}
                  </Grid>
                </Grid>
                {!isMobile ?
                  <Grid item xs={12} style={{ marginLeft: '1.5rem', marginTop: '6px' }}>
                    Going to cost ${price} for {price / hourCost} dates.
                  </Grid>
                  : null}
                {/* <Grid item xs={8} style={isMobile ? { marginLeft: '1.5rem', marginTop: '58%' } : rater.aboutYou ? { marginLeft: '1.5rem', marginTop: '15%' } :
                  { marginLeft: '1.5rem', marginTop: '18%' }}>

                </Grid> */}
              </Grid>
            </Grid>
            : null}
          <Grid item xs={12} sm={0} md={6} lg={6}>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm id={id} />
              </Elements>
            )}
          </Grid>
        </Grid>
        {!isDesktop && !isMobile ?
          <br /> : null}

      </Box>

    </div>
  );
}

export default Payment;
