import React, { useState } from 'react';
import * as Chance from 'chance';
import { useNavigate } from "react-router-dom"
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

const chance = new Chance();
//const navigate = useNavigate();

const Home = ({ history }) => {
  const [roomId, setRoomId] = useState('');
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: 10, marginLeft: 10 }}>
      <input
        type='text'
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      ></input>
      <button
        type='button'
        onClick={() => {
          if (!roomId) {
            alert('RoomId is required');
            return;
          }
          navigate(`/room/${roomId}`, { state: { 'roomId': roomId } });
        }}
      >
        Join Room
      </button>
      <button
        type='button'
        onClick={() => {
          const id = chance.guid();
          navigate(`/room/${id}`, { state: { 'roomId': id } });
        }}
      >
        Create Room
      </button>
    </div>
  );
};

export default Home;
