import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import LandingPage from './components/profileReview/LandingPage'
import LoginPage from './components/login/LoginPage'
import SignUpPage from './components/signup/SignUpPage'
import ForgetPasswordPage from './components/forgetPassword/ForgetPasswordPage'
// import HomePage from './components/profileReview/HomePage'
import Instructors from './components/instructors/Instructors'
import OnlineDates from './components/onlineDates/OnlineDates'
import Messages from './components/messages/Messages'
import HeartBreak from './components/heartBreak/HeartBreak'
import FeelingLonely from './components/feelingLonely/FeelingLonely'
// import RaterLoginPage from './components/login/RaterLoginPage'
// import RaterSignUpPage from './components/login/RaterSignUpPage'
import TeacherSignUpPage from './components/signup/TeacherSignUpPage'
import UploadPage from './components/upload/Upload'
import NavBarHomePage from './components/navbar/NavBarHomePage'
import Navbar from './components/navbar/Navbar';
import PaymentSuccessHomePage from './components/paymentSuccess/PaymentSuccessHomePage'
import HomePage from './components/homePage/HomePage';
import { connect } from 'react-redux';

import Home from './components/room/Home';
import Room from './components/room/Room';
import Chat from './components/chat/Chat';
import Payment from "./components/payment/Payment";
import PaymentSubscription from "./components/paymentSubscription/PaymentSubscription";
import BeforePayment from "./components/beforePayment/BeforePayment";
import Completion from "./components/completion/Completion";
import BeforePaymentSubscription from "./components/beforePaymentSubscription/BeforePaymentSubscription";
import CompletionSubscription from "./components/completionSubscription/CompletionSubscription";
import AddDates from "./components/addDates/AddDates";
import AvailableSlots from "./components/availableSlots/AvailableSlots";


import './App.css'
import { ChatBubbleOutline } from '@mui/icons-material'
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from './const/const';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: cookies.get('username'),
            isloginOrSignUpPage: false,
            environment: 'dev'
        }
    }

    componentDidMount() {
        const windowUrl = window.location.href;

        if (windowUrl && windowUrl.includes("datepracticals.com")) {
            this.setState({
                environment: 'prod'
            })
        }

        const loginPageLink = window.location.href.toString();
        const loginPageLinkArray = loginPageLink.split("//");
        const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup') ||
            loginPageLink.includes('raterSignup') || loginPageLink.includes('teacherSignup');

        this.setState({
            isloginOrSignUpPage,
            loginPageLinkArrayLength: loginPageLinkArray.length
        })
    }

    handleNameChange = (username) => {
        this.setState({
            username
        })
    }

    handleIsloginOrSignUpPage = (isloginOrSignUpPage) => {
        this.setState({
            isloginOrSignUpPage
        })
    }

    render() {
        const { username, isloginOrSignUpPage, environment, loginPageLinkArrayLength } = this.state;

        return (
            <Router>
                <div>
                    {!isloginOrSignUpPage && loginPageLinkArrayLength !== 2 && <Navbar env={environment} />}
                    {/* `<Divider />` */}

                    <Routes>
                        {/* <Route exact path="/" element={cookies.get('username') ? <Navigate to={"/home"} /> : <Navigate to={"/login"} />} /> */}
                        <Route exact path="/" element={<Navigate to={"/home"} />} />
                        <Route path="/login" element={<LoginPage handleNameChange={this.handleNameChange} handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/signup" element={<SignUpPage handleNameChange={this.handleNameChange} handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/teacherSignup" element={<TeacherSignUpPage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/forget-password" element={<ForgetPasswordPage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/datePracticalsHome" element={<Instructors handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/dates" element={<NavBarHomePage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/onlineDates" element={<OnlineDates handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/home" element={cookies.get("access-token") ? <NavBarHomePage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} /> :
                            <HomePage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/paymentSuccess" element={<PaymentSuccessHomePage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/logout" element={<HomePage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/upload" element={<UploadPage handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/join" element={<Home handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/room/:roomId" element={<Room handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/chat" element={<Chat handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/messages" element={<Messages handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/chat/:chatId" element={<Chat handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/payment" element={<Payment handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/paymentSubscription/*" element={<PaymentSubscription handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/payment/*" element={<Payment handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/beforepayment/*" element={<BeforePayment handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/beforePaymentSubscription/*" element={<BeforePaymentSubscription handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/completionSubscription" element={<CompletionSubscription handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/completion" element={<Completion handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/completion/*" element={<Completion handleIsloginOrSignUpPage={this.handleIsloginOrSignUpPage} env={environment} />} />
                        <Route path="/feelingLonely" element={<FeelingLonely handleIsloginOrSignUpPage={true} env={environment} />} />
                        <Route path="/heartBreak" element={<HeartBreak handleIsloginOrSignUpPage={true} />} env={environment} />
                        <Route path="/addDates" element={<AddDates handleIsloginOrSignUpPage={true} />} env={environment} />
                        <Route path="/availableSlots" element={<AvailableSlots handleIsloginOrSignUpPage={true} />} env={environment} />
                    </Routes>
                </div>
            </Router>
        )
    }
}

export default App;
