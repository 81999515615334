import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

import Cookies from 'universal-cookie';

import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Subscribe from '../subscribe/Subscribe';
import Earnings from '../earnings/Earnings';
import AdminDues from '../adminDues/AdminDues';

const cookies = new Cookies();

class HeadingLogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: cookies.get('username'),
            isloginOrSignUpPage: false,
            environment: 'dev',
            loginModalOpen: false,
            showInstructorsDialog: false,
            balance: 0
        }
    }

    componentDidMount() {
        const windowUrl = window.location.href;
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;
        console.log('window innerwidth' + window.innerWidth);

        if (windowUrl && windowUrl.includes("datepracticals.com")) {
            this.setState({
                environment: 'prod'
            })
        }

        const loginPageLink = window.location.href.toString();
        const loginPageLinkArray = loginPageLink.split("//");
        const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup') ||
            loginPageLink.includes('raterSignup') || loginPageLink.includes('teacherSignup');

        this.setState({
            isloginOrSignUpPage,
            loginPageLinkArrayLength: loginPageLinkArray.length
        })
        //this.getBalance();
    }

    // getBalance = () => {
    //     const data = {
    //         userEmail: cookies.get("email"),
    //         accessToken: cookies.get("access-token"),
    //     };

    //     axios.post(`${URL_PREFIX()}/datepracticals/getBalance`, data)
    //         .then(res => {
    //             this.setState({
    //                 balance: res.data.balance
    //             })
    //         })
    //         .catch(error => console.log(error));
    // }

    handleNameChange = (username) => {
        this.setState({
            username
        })
    }

    handleIsloginOrSignUpPage = (isloginOrSignUpPage) => {
        this.setState({
            isloginOrSignUpPage
        })
    }

    handleLoginDialog = () => {
        this.setState({
            loginModalOpen: true
        })
    }

    handleLoginModalClose = () => {
        this.setState({
            loginModalOpen: false
        })
    }


    handleLogout = () => {
        cookies.set("access-token", null);
        cookies.set('username', null);
        cookies.set('email', null);
        cookies.set('rater', null);
        this.setState({
            loginModalOpen: false
        }, () => {
            this.props.navigate("/logout");
        })
    }

    handleAddDates = () => {
        this.setState({
            loginModalOpen: false
        }, () => {
            this.props.selectInstructors();
        })
    }

    setShowInstructorsDialog = (show) => {
        this.setState({
            showInstructorsDialog: show
        })
    }

    setShowEarningsDialog = (show) => {
        this.setState({
            showEarningsDialog: show
        })
    }

    setShowEarningsForAdminsDialog = (show) => {
        this.setState({
            showEarningsForAdminsDialog: show
        })
    }

    render() {
        const { username, isloginOrSignUpPage, environment, loginPageLinkArrayLength, loginModalOpen, showInstructorsDialog, showEarningsDialog, showEarningsForAdminsDialog } = this.state;
        const { navigate, setAvailableSlots, showMessage, balance } = this.props;

        return (
            <div>
                {showMessage ?
                    <>
                        <Grid container>
                            <Grid xs={0} md={4} lg={6}>
                            </Grid>

                            <Grid item xs={12} md={8} lg={6}>
                                <Alert severity="success" style={{ backgroundColor: '#A3E4D7', }}>
                                    Slots/Subsciption booked successfully.
                                </Alert>
                            </Grid>
                        </Grid>
                    </>
                    : null}
                <Grid container justify="left" direction="row">
                    <Grid item xs={9} sm={9} md={4} lg={2} style={{
                        justifyContent: 'left', justifyContent: 'left',
                    }}>
                        <span style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'left',
                            padding: '1px',
                            marginLeft: '1%',

                            fontSize: '24px',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                this.props.navigate("/home");
                            }}
                        >Tidy Belle
                        </span>
                    </Grid>
                    {this.isDesktop ?
                        <Grid item lg={2} style={{
                            justifyContent: 'left', justifyContent: 'left',
                        }}>

                            <Grid container>
                                <Grid item lg={8} style={{
                                    justifyContent: 'left', justifyContent: 'left',
                                }}>
                                    <span style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        padding: '5px',
                                        marginTop: '1%',
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        fontWeight: 'bold'
                                    }}
                                        onClick={this.props.selectInstructors}
                                    >{this.isDesktop ? 'Find instructors' : null}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null}


                    <Grid item xs={0} sm={0} md={6} lg={5} style={{
                        justifyContent: 'left', justifyContent: 'left',
                    }}>
                        <Grid container>
                            {this.isDesktop ?
                                <Grid item xs={0} sm={0} md={0} lg={2}>
                                    <span style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        padding: '5px',
                                        fontSize: '16px'
                                    }}>

                                    </span>
                                </Grid>
                                : null}
                            {!this.isMobile && !this.isSmallerScreen ?
                                <Grid item xs={0} md={6} lg={3}>
                                    <span style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        padding: '5px',
                                        marginTop: '1%',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    }}>
                                        Balance: ${balance ? balance : 0}
                                    </span>
                                </Grid>
                                : null}
                            {!cookies.get('rater') ?
                                <>
                                    {!this.isMobile && !this.isSmallerScreen ?
                                        <Grid item xs={0} sm={0} md={4} lg={6}>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '100px',
                                                height: '30px',
                                                padding: '6px',
                                                border: '1px solid grey',

                                                justifyContent: 'left',
                                                alignItems: 'left',
                                                padding: '5px',
                                                marginTop: this.isDesktop ? '0.1%' : this.isLaptop ? '1%' : null,
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                borderRadius: 14,
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => {
                                                    this.setState({ showInstructorsDialog: true });
                                                }}
                                            >
                                                <span style={{ marginLeft: '6px', cursor: 'pointer' }}>Subscribe</span>
                                            </span>
                                        </Grid>
                                        : null}
                                </> : <>
                                    {!this.isMobile && !this.isSmallerScreen ?
                                        <Grid item xs={0} md={4} lg={6}>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '100px',
                                                height: '30px',
                                                padding: '6px',
                                                border: '1px solid grey',

                                                justifyContent: 'left',
                                                alignItems: 'left',
                                                padding: '5px',
                                                marginTop: this.isDesktop ? '0.1%' : this.isLaptop ? '1%' : null,
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                borderRadius: 14,
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => {
                                                    !cookies.get('admin') ? this.setState({ showEarningsDialog: true }) : this.setState({ showEarningsForAdminsDialog: true });
                                                }}
                                            >
                                                <span style={{ marginLeft: '6px', cursor: 'pointer' }}>Earnings</span>
                                            </span>
                                        </Grid>
                                        : null}
                                </>
                            }
                        </Grid>
                    </Grid>
                    {this.isLaptop ?
                        <Grid item xs={0} sm={0} md={1} lg={4}>

                        </Grid> :
                        <Grid item xs={0} sm={0} md={1} lg={2}>

                        </Grid>
                    }

                    <Grid item xs={3} sm={3} md={1} lg={1} style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                    }}>
                        {!cookies.get('rater') && !cookies.get('admin') ?
                            <>
                                {this.isMobile || this.isSmallerScreen ?
                                    <IconButton onClick={() => {
                                        this.setState({ showInstructorsDialog: true });
                                    }}>
                                        <AddIcon />
                                    </IconButton>
                                    : null}
                            </> : <>
                                {this.isMobile || this.isSmallerScreen ?
                                    <IconButton onClick={() => {
                                        cookies.get('rater') ?
                                            this.setState({ showEarningsDialog: true }) :
                                            this.setState({ showEarningsForAdminsDialog: true })
                                    }}>
                                        <AttachMoneyIcon />
                                    </IconButton>
                                    : null}
                            </>}
                        <IconButton onClick={this.props.selectMessages}>
                            <MessageIcon />
                        </IconButton>
                        <IconButton onClick={this.props.selectSettings}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton onClick={this.handleLoginDialog}>
                            <AccountCircleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {
                            loginModalOpen ?
                                <Dialog
                                    fullWidth
                                    maxWidth="lg"
                                    open={loginModalOpen}
                                    onClose={this.handleLoginModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ width: this.isDesktop ? '12%' : '225px', height: this.isDesktop ? '40%' : "50%", marginLeft: this.isDesktop ? '88%' : this.isLaptop ? '80%' : this.isSmallerScreen ? '70%' : '45%', cursor: 'pointer', fontSize: 13, fontWeight: 'bold' }}
                                    PaperProps={this.isDesktop ? { sx: { mb: "70%" } } : this.isMobile ? { sx: { mb: "50%" } } : { sx: { mb: "60%" } }}
                                >
                                    <DialogTitle>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11}>
                                                <Grid container>
                                                    <Grid item>
                                                        <span onClick={() => { navigate("/login"); }}>{'Login'}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Grid container>
                                                    <Grid item>
                                                        <span onClick={this.handleLogout}>{'Logout'}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Grid container>
                                                    <Grid item>
                                                        <span onClick={this.handleLogout}>Balance: ${balance ? balance : 0}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Grid container>
                                                    <Grid item>
                                                        <span onClick={() => {
                                                            this.props.setAvailableSlots(true);
                                                        }}> {'Add dates'}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </DialogTitle>
                                </Dialog>
                                : null
                        }
                    </Grid>
                </Grid>

                {
                    showInstructorsDialog ?
                        <Subscribe showInstructorsDialog={showInstructorsDialog} setShowInstructorsDialog={this.setShowInstructorsDialog} /> : null
                }
                {
                    showEarningsDialog ?
                        <Earnings showEarningsDialog={showEarningsDialog} setShowEarningsDialog={this.setShowEarningsDialog} /> : null
                }

                {
                    showEarningsForAdminsDialog ?
                        <AdminDues showEarningsForAdminsDialog={showEarningsForAdminsDialog} setShowEarningsForAdminsDialog={this.setShowEarningsForAdminsDialog} /> : null
                }

            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <HeadingLogin {...props} location={location} navigate={navigate} />;
}
