import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

import OnlineDates from '../onlineDates/OnlineDates';
import AvailableSlots from '../availableSlots/AvailableSlots';

//import Cookies from 'universal-cookie';

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import * as Chance from 'chance';

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
// import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import io from 'socket.io-client';

import Chat from '../chat/Chat';

const cookies = new Cookies();
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';

const socket = io.connect(`${URL_PREFIX()}`, { transports: ['websocket', 'polling', 'flashsocket'] });
//const cookies = new Cookies();

const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

const chance = new Chance();

class InstructorsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showInstructorsProfile: false
        }
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        //var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // if (this.videoRef.current) {
        //     this.videoRef.current.srcObject = stream;
        // }
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        const { state } = this.props.location;
        const { handleIsloginOrSignUpPage, navigate } = this.props;

        if (state) {
            if (state && state.username) {
                const { username, email, env } = state;
                this.setState({
                    username,
                    email,
                    env
                }, () => {
                    //this.retrieveImages(email);
                    //this.getImageList(email);
                })
                console.log(`username is ${username}`);
                console.log(`username is ${email}`);
            } else {
                this.setState({
                    username: cookies.get("username"),
                    email: cookies.get("email"),
                })
            }
            //handleIsloginOrSignUpPage(cookies.get("username") !== null);
        } else {
            this.setState({
                openLogin: true
            })
            //navigate("/login");
        }
        this.getTeachers();
    }

    getTeachers = () => {
        console.log('retrieving images list');
        const data = {
            //accessToken: cookies.get("access-token"),
            accessToken: cookies.get("access-token"),
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getTeachers`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    handleRatersResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((rater) => {
            if (uniqueratersEmail.indexOf(rater.email) === -1) {
                uniqueratersEmail.push(rater.email);
                uniqueRaters.push(rater)
            }
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters,
            InstructorsEmail: uniqueRaters[0]?.email
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(rater.email, index)
            ));
        });
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveImage`, data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }


    render() {
        const { email, username, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, datesArray, availableTimeSlotDict, showAvailableDatesDialog, showModelChat, room, teacherName, userEmail, teacherEmail, sentBy, raterIndex,
            showInstructorsProfile, InstructorsEmail
        } = this.state;
        const { navigate } = this.props;

        return (
            <div >
                <React.Fragment>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Instructors</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={instructor}
                                value={InstructorsEmail}
                                label="Instructors"
                                onChange={this.handleInstructorChange}
                            >
                                {ratersArray && ratersArray.length > 0 && ratersArray.map((rater, index) => (
                                    <>
                                        <MenuItem value={rater.email}>
                                            <img src={this.retrieveImageUrl(index)} style={
                                                {
                                                    width: 50,
                                                    height: 50,
                                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderRadius: 4,
                                                    cursor: 'pointer',
                                                    marginRight: '3px'
                                                }} onClick={() => { this.setState({ showInstructorsProfile: true }) }}></img>
                                            <span> {rater.firstname}</span>
                                        </MenuItem>

                                    </>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </React.Fragment >
            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <InstructorsList {...props} location={location} navigate={navigate} />;
}
