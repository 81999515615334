import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from './Navbar';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

//
class NavBarHomePage extends React.Component {
    constructor(props) {
        super(props);
        // this.State = {
        //     successMessage: this.props?.location ? this.props?.location?.state?.successMessage : '',
        //     index: this.props?.location ? this.props?.location?.state?.index : "0"
        // }
    }

    async componentDidMount() {
        // this.setState({
        //     successMessage: this.props?.location?.state?.successMessage,
        //     index: this.props?.location?.state?.index
        // })
    }

    setPropsNull = (setDefault, index) => {
        // if (setDefault) {
        //     this.setState({
        //         successMessage: null,
        //         index: index
        //     })
        // }
    }

    render() {
        // const { successMessage, index } = this.state;

        return (
            <div >
                <Navbar />
            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <NavBarHomePage {...props} location={location} navigate={navigate} />;
}
