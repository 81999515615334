import React, { useEffect, useState } from 'react';
import * as Chance from 'chance';
import { useNavigate } from "react-router-dom"

import axios from 'axios';

import {

  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,

} from "@material-ui/core";
import HeadingLogin from '../heading/HeadingLogin';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const chance = new Chance();
//const navigate = useNavigate();

const Completion = ({ history }) => {
  const [roomId, setRoomId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loginPageLink = window.location.href.toString();
    const completionSplitArray = loginPageLink.split("/completion/");
    const splitArray = completionSplitArray ? completionSplitArray[1].split("?") : null;
    const splitIdArray = splitArray ? splitArray[0].split("/") : null;
    const id = splitIdArray ? splitIdArray[0] : null;

    const data = {
      id: id,
      accessToken: cookies.get("access-token"),
    };

    const handleOnlineDatesResponse = () => {
      // setTimeout(() => {
      //   navigate(`/home`, {
      //     state: {
      //       successMessage: 'Online date boked successfully'
      //     }
      //   });
      // }, 1000)
      navigate(`/paymentSuccess`);
    }

    axios.post(`${URL_PREFIX()}/datepracticals/updateBookDate`, data)
      .then(res => handleOnlineDatesResponse(res))
      .catch(error => console.log(error));
  }, []);

  return (
    <div style={{ marginLeft: 10 }}>
      <HeadingLogin />
      < br />
      <br />
      <br />
      {/* <Grid>
        <Grid item xs={12} md={12} lg={12}>
          <span style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>The online date has been booked successfully </span>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default Completion;
