import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";
import Backdrop from '@mui/material/Backdrop';

import OnlineDates from '../onlineDates/OnlineDates';
import AvailableSlots from '../availableSlots/AvailableSlots';
import Profile from '../profile/Profile';

//import Cookies from 'universal-cookie';

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import * as Chance from 'chance';
import CircularProgress from '@mui/material/CircularProgress';

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
// import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import io from 'socket.io-client';

import Chat from '../chat/Chat';

const cookies = new Cookies();
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';

const socket = io.connect(`${URL_PREFIX()}`, { transports: ['websocket', 'polling', 'flashsocket'] });
//const cookies = new Cookies();

const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const oneHourOr30MinTimeSelectStyleEnableMobile = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginLeft: '30%',
    width: '95%',
    height: '50px'
}

const oneHourOr30MinTimeSelectStyleNormalMobile = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginLeft: '30%',
    width: '95%',
    height: '50px'
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

const chance = new Chance();

class Instructors extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            balance: 0,
            review: {
                imageTitle: '',
                email: '',
                userEmail: '',
                isProfilePic: false,
                attractivenessSliderVal: 5,
                confidenceSliderVal: 5,
                charmingSliderVal: 5,
                responsibleSliderVal: 5,
                funSliderVal: 5,
                intelligentSliderVal: 5,
                willingToDateSliderVal: 5,
                picOrderSliderVal: 0,
                needtoLookAtCamera: false,
                toSmile: false,
                toDelete: false,
                tooMuchFiltering: false,
                needToLookAtCamera: false,
                isLightingBad: false,
                isBadSelfie: false,
                toRecommendOthers: false,
                phoneNoOrEmail: '',
                suggestions: '',
                pictureEffectivenessSliderVal: 0,
                looksTooYoung: false,
                getHairCut: false,
                trimYourBeard: false,
                niceGuyVibe: false,
                reduceWeight: false
            },
            bioReview: {
                imageTitle: '',
                email: '',
                userEmail: '',
                promptEffectivenessSliderVal: 0,
                showsPersonality: false,
                isShortBio: false,
                wantToDate: false,
                notReleatedAnswer: false,
                deleteThisPrompt: false,
                gramatticalMistakes: false,
                difficultToUnderstand: false,
                boringAnswer: false,
                tooLongAnswer: false,
                deletePolitics: false,
                deleteReligion: false,
                whatYouAreLookingFor: false,
                hobbiesAndInterests: false,
                racistVibe: false,
                goldDiggerVibe: false,
                looksTooOld: false,
                givingTooMuchInfo: false,
                workOnYourself: false,
                mentalHealthIssues: false,
                bioSuggestions: '',

            },
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            selectedWeekDays: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: [],
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showInstructorsProfile: false
        }
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        //var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // if (this.videoRef.current) {
        //     this.videoRef.current.srcObject = stream;
        // }
        //this.props.setDefaultIndex(true);
        this.setState({
            progress: true
        })
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        const { state } = this.props.location;
        const { handleIsloginOrSignUpPage, navigate } = this.props;

        const data = {
            userEmail: cookies.get("email"),
            accessToken: cookies.get("access-token"),
        };

        if (state) {
            if (state && state.username) {
                const { username, email, env } = state;
                this.setState({
                    username,
                    email,
                    env
                }, () => {
                    //this.retrieveImages(email);
                    //this.getImageList(email);
                })
                console.log(`username is ${username}`);
                console.log(`username is ${email}`);
            } else {
                this.setState({
                    username: cookies.get("username"),
                    email: cookies.get("email"),
                })
            }
            //handleIsloginOrSignUpPage(cookies.get("username") !== null);
        } else {
            this.setState({
                openLogin: true
            })
            //navigate("/login");
        }
        this.getTeachers();
        axios.post(`${URL_PREFIX()}/datepracticals/getBalance`, data)
            .then(res => {
                const balance = res.data.balance;
                if (!isNaN(balance)) {
                    this.setState({
                        balance
                    })
                }
            })
            .catch(error => console.log(error));
        this.setState({
            progress: false
        })
    }

    getOnlineDates = () => {
        console.log('retrieving images list');
        const data = {
            userEmail: this.props?.location?.state?.userEmail,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDates`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    getAvailableTimeSlotsForSelectedDay = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher?.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(1, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
            .then(res => this.handleAvailableTimeSlotResponseForSelectedDay(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    // getAvailableTimeSlotsForSelectedDay = (date) => {
    //     //this.props.setInstructorChange(false);
    //     // if (index >= 0) {
    //     let { selectedTeacher } = this.state;

    //     const data = {
    //         email: cookies.get("rater") ? cookies.get("email") : this.props.selectedTeacher?.email,
    //         //bookedDate: bookedDate,
    //         weekStartDate: new Date(moment(date).format('MM/DD/YYYY')),
    //         weekEndDate: new Date(moment(date).add(1, 'days').format('MM/DD/YYYY')),
    //         accessToken: cookies.get("access-token"),
    //     }

    //     axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
    //         .then(res => this.handleAvailableTimeSlotResponseForSelectedDay(res, date))
    //         .catch(error => console.log(error));
    //     //}
    //     //return value
    // }

    handleAvailableTimeSlotResponseForSelectedDay = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 1; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    getBookedTimeSlotsForSelectedDay = (weekStartDate, dateChange) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher?.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(1, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponseForSelectedDay(res, weekStartDate, dateChange))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponseForSelectedDay = (res, weekStartDate, dateChange) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 1; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            if (this.isMobile && !dateChange) {
                this.setState({
                    availableTimes: this.getDates()
                }, () => {
                    this.setState({
                        availableTimesMobile: this.state.availableTimes[0],
                        selectedWeekDayMobile: this.state.weekArray[0],
                        selectedWeekDayMobileIndex: 0
                    })
                })
            }
        })
        //this.props.setInstructorChange(false);
    }



    handleOnlineDatesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((data) => {
            uniqueRaters.push(data);
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            datesArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveDateFirstImage(rater.teacherEmail, index)
            ));
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    getInitialValues = () => {
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        })

        const currentDay = moment();
        const currentDayDiff = currentDay.diff(today, 'days');
        this.setState({
            currentDayDiff
        })

        if (this.isMobile) {
            this.getAvailableTimeSlotsForSelectedDay(moment());
            this.getBookedTimeSlotsForSelectedDay(moment());
        }
    }

    getImageList = (emailToRetrieve) => {
        console.log('retrieving images list');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/pictures`, data)
            .then(res => this.handleImageResponse(res))
            .catch(error => console.log(error));
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value
        })
    }

    getRaters = () => {
        console.log('retrieving images list');
        const data = {
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getRaters`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    getTeachers = () => {
        console.log('retrieving images list');
        const data = {
            //accessToken: cookies.get("access-token"),
            accessToken: cookies.get("access-token"),
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getTeachers`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    handleRatersResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((rater) => {
            if (uniqueratersEmail.indexOf(rater.email) === -1) {
                uniqueratersEmail.push(rater.email);
                uniqueRaters.push(rater)
            }
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(rater.email, index)
            ));
            if (!this.isMobile) {
                this.getInitialValues();
            }
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveDateFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleDateFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleDateFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'dateimageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveImage`, data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveReview`, data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    retrieveBioReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveBioReview`, data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleNextButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber < imageList.length - 1) {
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: false
                });
            }
            imageNumber += 1;
            this.setState({
                imageNumber
            });

            if (imageNumber === imageList.length - 1) {
                this.setState({
                    removeNextButton: true
                });
            }
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeNextButton: true
            });
        }
    };

    handleBackButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber >= 0) {
            imageNumber -= 1;
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: true
                });
            }
            if (imageNumber < imageList.length - 1) {
                this.setState({
                    removeNextButton: false
                });
            }
            this.setState({
                imageNumber
            });
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeBackButton: true
            });
        }
    };

    handleDisablePicturesRetrieval = (picRetrieve) => {
        this.setState({
            disablePicturesretrieval: picRetrieve
        })
    }

    handleChange = (key, event) => {
        this.setState({
            [key]: event.target.value
        });
    };

    handleReviewChange = (key, event) => {
        const review = { ...this.state.review };
        review[key] = event.target.value;

        this.setState({
            review
        });
    };

    handleBioReviewChange = (key, event) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = event.target.value;

        this.setState({
            bioReview
        });
    };

    valueText = (value) => value;

    changeSliderVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewPhoneNoChange = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewSubmitButton = () => {
        const { imageList, imageNumber, review, email } = this.state;
        const imageTitle = imageList[imageNumber];
        review.imageTitle = imageTitle?.title;
        review.email = email;
        review.userEmail = imageTitle?.email;

        const data = {
            review: review,
            accessToken: cookies.get("access-token"),
        };
        console.log('review Json is ', JSON.stringify(review));

        axios.post(`${URL_PREFIX()}/datepracticals/postReview`, data)
            .then(res => this.handleReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponse = (res) => {
        console.log('after review submit', res);
        const { review } = this.state;
        const { userEmail, imageTitle } = review;

        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveReview`, data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponseRes = (res) => {
        console.log('after review submit', res);
        this.setState({
            review: res.data
        })
    }

    handleBioReviewSubmitButton = () => {
        const { imageList, imageNumber, bioReview, email } = this.state;
        const imageTitle = imageList[imageNumber];
        bioReview.imageTitle = imageTitle?.title;
        bioReview.email = email;
        bioReview.userEmail = imageTitle?.email;

        const data = {
            bioReview: bioReview,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/postBioReview`, data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    // handleBioReviewSubmitRes = (res) => {
    //     console.log('after review submit', res);
    //     const { bioReview } = this.state;
    //     const { userEmail, imageTitle } = bioReview;

    //     const data = {
    //         userEmail: userEmail,
    //         imageTitle: imageTitle
    //     };

    //     axios.post("`${URL_PREFIX()}/datepracticals/retrieveBioReview", data)
    //         .then(res => this.handleBioReviewSubmitResponse(res))
    //         .catch(error => console.log(error));       
    // }

    handleBioReviewSubmitResponse = (res) => {
        console.log('after bio review submit', res);

        this.setState({
            bioReview: res.data
        })
    }

    bioChipClick = (e, key) => {
        const bioReview = { ... this.state.bioReview };
        let bioReviewKey = !bioReview[key];
        bioReview[key] = bioReviewKey;

        this.setState({
            bioReview: bioReview
        })
    }

    reviewChipClick = (e, key) => {
        const review = { ... this.state.review };
        let reviewKey = !review[key];
        review[key] = reviewKey;

        this.setState({
            review: review
        })
    }

    changeBioSliderVal = (key, val) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = val;

        this.setState({
            bioReview
        });
    }

    changePicSliderPicVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    checkCurrentDate = (availableTime) => {
        let { selectedTimes, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;
        const timeValArray = availableTime.split("-");
        const selectedWeekDay = timeValArray[0];
        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        const isToday = bookedDateNoFormat.date() === new Date().getDate();
        return isToday;
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray(weekArray[i]);
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = (weekDay) => {
        if (this.checkCurrentDate(weekDay)) {
            const availableDates = this.getTimeToday();

            //adding empty spaces
            for (let i = availableDates.length - 1; i < 48; i++) {
                availableDates.push('-');
            }
            return availableDates;
        } else {
            const amArray = this.getTime('AM');
            const pmArray = this.getTime('PM');
            const amPmArray = amArray.concat(pmArray);
            this.setState({
                amPmArray
            })
            return amPmArray;
        }
    }

    getTimeToday = () => {
        let amOrpmArray = [];
        const date = new Date();
        let currentHour = date.getHours();
        const currentMinutes = date.getMinutes();
        const amOrPm = currentHour >= 12 ? 'PM' : 'AM';

        if (amOrPm === 'PM') {
            if (currentHour !== "12") {
                currentHour = Number(currentHour) - 12;
            }

            if (currentHour === "12") {
                if (currentMinutes == 0) {
                    amOrpmArray.push(12 + ':00' + amOrPm);
                } else if (currentMinutes < 30) {
                    amOrpmArray.push(12 + ':30' + amOrPm);
                }
                currentHour = Number(currentHour) - 12;
            }

            let index = 0;
            for (let i = currentHour; i < 12; i++) {
                if (index === 0) {
                    if (currentMinutes == 0) {
                        amOrpmArray.push(i + ':00' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':00' + amOrPm);
                }
                if (index === 0) {
                    if (currentMinutes <= 30) {
                        amOrpmArray.push(i + ':30' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':30' + amOrPm);
                }
                index++;
            }
        } else {
            let index = 0;
            for (let i = currentHour; i < 12; i++) {
                if (index === 0) {
                    if (currentMinutes == 0) {
                        amOrpmArray.push(i + ':00' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':00' + amOrPm);
                }

                if (index === 0) {
                    if (currentMinutes <= 30) {
                        amOrpmArray.push(i + ':30' + amOrPm);
                    }
                } else {
                    amOrpmArray.push(i + ':30' + amOrPm);
                }
                index++;
            }

            amOrpmArray.push(12 + ':00' + 'PM');
            amOrpmArray.push(12 + ':30' + 'PM');

            for (let i = 1; i < 12; i++) {
                amOrpmArray.push(i + ':00' + 'PM');
                amOrpmArray.push(i + ':30' + 'PM');
            }
        }

        this.setState({
            todayAvailableTimes: amOrpmArray
        })

        return amOrpmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })


        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
            selectedTimes: []
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
            selectedTimes: []
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false
            })
        }
    }

    // handleTimeSelection = (e) => {
    //     this.setState({
    //         bookDate: false
    //     })
    //     const timeValArray = e.target.value.split("-");
    //     const selectedWeekDay = timeValArray[0];
    //     const time = timeValArray[1];
    //     let { oneHourClick, amPmArray, selectedTimes } = this.state;
    //     let timeIndex = amPmArray.indexOf(time);

    //     let selectedTimesArray = [];

    //     selectedTimesArray.push(time);
    //     if (oneHourClick) {
    //         selectedTimesArray.push(amPmArray[timeIndex + 1]);
    //     }

    //     this.setState({
    //         selectedTimes: selectedTimesArray,
    //         selectedWeekDay: selectedWeekDay
    //     })
    // }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    handleBookDateStudentLengthResponse = (res) => {
        const orderId = res?.data.bookDatesLength;
        this.handleBookDates(orderId);
    }

    bookDateForStudent = () => {
        console.log('bookDateForStudent hit');
        //this.props.setBookDates(false);
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate, existingOrderId } = this.state;
        const { navigate } = this.props;

        if (selectedTimes && selectedTimes.length > 1) {
            axios.get(`${URL_PREFIX()}/datepracticals/bookDatesLength`)
                .then(res => this.handleBookDateStudentLengthResponse(res))
                .catch(error => console.log(error));
        } else {
            this.handleBookDates();
        }
    }

    handleBookDates = (orderId) => {
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate, selectedTeacher } = this.state;
        let actualCount = 0;
        let count = 0;

        for (let i = 0; i < selectedWeekDays.length; i++) {
            // for (let j = 0; j < selectedTimes.length; j++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                count++;
            }
        }

        for (let i = 0; i < selectedWeekDays.length; i++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                actualCount++;
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));
                const selectedTimeFirst = selectedTimes ? selectedTimes[i][0] : null;
                let bookedDateTime = new Date(bookedDate);

                if (selectedTimeFirst) {
                    const selectedTimeFirstLength = selectedTimeFirst.length;
                    const noOfHours = 0;
                    const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
                    const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
                    let selectedHour = selectedTimeFirstHoursMinutesArray[0];
                    const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

                    if (isAmOrPm === 'PM') {
                        selectedHour = Number(selectedHour) + 12;
                    }
                    bookedDateTime.setHours(selectedHour);
                    bookedDateTime.setMinutes(Number(selectedMinutes));
                }

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[i][0];

                const reminderDateTime = this.getReminderDateTime(bookedDate, selectedTimes[i]);

                this.setState({
                    bookDate,
                    dateBookingMessage
                })

                const bookingDateAndTime = {
                    userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
                    teacherName: selectedTeacher.firstname,
                    userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
                    teacherEmail: selectedTeacher.email,
                    bookedDate: bookedDate,
                    bookedDateTime: bookedDateTime,
                    reminderDateTime: reminderDateTime,
                    bookedTimes: selectedTimes[i],
                    createdDate: moment(),
                    roomId: chance.guid(),
                    price: selectedTeacher ? selectedTeacher.priceSlider : 10,
                    accessToken: cookies.get("access-token"),
                    orderId: orderId && orderId > 0 ? orderId : null
                }

                axios.post(`${URL_PREFIX()}/datepracticals/bookDate`, bookingDateAndTime)
                    .then(res => this.handleBookDateStudentResponse(res, count, actualCount))
                    .catch(error => console.log(error));
            }
        }
    }

    handleBookDateStudentResponse = (res, count, actualCount) => {
        // if (res.data.orderId) {
        //     this.setState({
        //         existingOrderId: res.data.orderId
        //     })
        // }
        if (count === actualCount) {
            const id = res?.data?.orderId ? res.data.orderId : null;
            const teacherName = res?.data?.teacherName ? res.data.teacherName : null;
            const price = res?.data?.price ? res.data.price * actualCount : null;
            const hourCost = res?.data?.price ? res.data.price : null;
            const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;

            this.props.navigate(`/beforePayment/${id}/${teacherName}/${price}/${teacherEmail}/${hourCost}/${this.state.balance}`, {

            });
            this.setState({
                dateBookingSuccessful: true,
                bookDate: true
            })
        }
    }

    bookDate = () => {
        let { bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;
        bookDate = !bookDate;

        if (bookDate) {
            this.setState({
                cancelDate: false,
                dateBookingSuccessful: false,
            })
        }

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY hh:mm:ss'));
        const selectedTimeFirst = selectedTimes ? selectedTimes[0] : null;
        let bookedDateTime = new Date(bookedDate);

        if (selectedTimeFirst) {
            const selectedTimeFirstLength = selectedTimeFirst.length;
            const noOfHours = 0;
            const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
            const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
            let selectedHour = selectedTimeFirstHoursMinutesArray[0];
            const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

            if (isAmOrPm === 'PM') {
                selectedHour = Number(selectedHour) + 12;
            }
            bookedDateTime.setHours(selectedHour);
            bookedDateTime.setMinutes(Number(selectedMinutes));
            //saving date in EST, as it's adding 5 hours to the time
            // const myTimezone = "America/Toronto";
            // var myDatetimeFormat = "MM/DD/YYYY hh:mm:ss a z";
            // bookedDateTime = moment(bookedDateTime.getMilliseconds()).tz(myTimezone).format(myDatetimeFormat);
            //bookedDateTime.setTime(bookedDateTime.getTime() - bookedDateTime.getTimezoneOffset() * 60 * 1000);
        }

        //let todayDate = new Date(moment(today).format('MM/DD/YYYY'));
        let dateArray = bookedDateNoFormat.toString().split(" ");
        let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

        const reminderDateTime = this.getReminderDateTime(bookedDate, selectedTimes);

        this.setState({
            bookDate,
            dateBookingMessage
        })

        const bookingDateAndTime = {
            userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
            teacherName: selectedTeacher.firstname,
            userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
            teacherEmail: selectedTeacher.email,
            bookedDate: bookedDate,
            bookedDateTime: bookedDateTime,
            reminderDateTime: reminderDateTime,
            bookedTimes: selectedTimes,
            createdDate: moment(),
            roomId: chance.guid(),
            price: selectedTeacher ? selectedTeacher.priceSlider : 10,
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookDate`, bookingDateAndTime)
            .then(res => this.handleBookDateResponse(res))
            .catch(error => console.log(error));
    }

    checkCurrentTimeBefore = (availableTime) => {
        let { selectedTimes, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;

        const timeValArray = availableTime.split("-");
        const selectedWeekDay = timeValArray[0];
        const time = timeValArray[1];

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY hh:mm:ss'));
        const selectedTimeFirst = time;
        let bookedDateTime = new Date(bookedDate);

        if (selectedTimeFirst) {
            const selectedTimeFirstLength = selectedTimeFirst.length;
            const noOfHours = 0;
            const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
            const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
            let selectedHour = selectedTimeFirstHoursMinutesArray[0];
            const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

            if (isAmOrPm === 'PM') {
                if (selectedHour !== "12") {
                    selectedHour = Number(selectedHour) + 12;
                }
            } else {
                if (selectedHour === "12") {
                    selectedHour = "0";
                }
            }
            bookedDateTime.setHours(selectedHour);
            bookedDateTime.setMinutes(Number(selectedMinutes));
            bookedDateTime.setSeconds(0);

            const isBefore = new Date().getTime() > bookedDateTime.getTime();
            return isBefore;
        }
    }

    getReminderDateTime = (bookedDate, selectedTimes) => {
        const bookedDateArray = bookedDate?.toString().split(" ");
        let reminderDate = bookedDateArray ? bookedDateArray[1] + " " + bookedDateArray[2] : '';
        const selectedTimesZeroArray = selectedTimes[0]?.split(":");
        const selectedTimesOneArray = selectedTimes[1]?.split(":");
        const timeString = selectedTimes[0] + " - " + (selectedTimesOneArray[0] === '12' ? 1 : Number(selectedTimesZeroArray[0]) + 1) + ":" + selectedTimesZeroArray[1];
        reminderDate = reminderDate + " " + timeString;
        return reminderDate;
    }

    handleBookDateResponse = (res) => {
        const id = res?.data?.orderId ? res.data.orderId : null;
        const teacherName = res?.data?.teacherName ? res.data.teacherName : null;
        const price = res?.data?.price ? res.data.price : null;
        const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;

        this.props.navigate(`/beforePayment/${id}/${teacherName}/${price}/${teacherEmail}`, {

        });
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        const { navigate } = this.props;
        if (!cookies.get("access-token")) {
            navigate("/signup");
        }
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value),
            progress: true
        }, () => {
            if (this.isMobile) {
                this.getInitialValues();
            }
            if (!this.isMobile) {
                this.getAvailableTimeSlots(moment());
                this.getBookedTimeSlots(moment());
            }
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleReviewOpen = () => {
        this.setState({
            reviewOpen: true,
            reviewSliderVal: 1,
            reviewPostingSuccessful: false
        })
    }

    handleReviewClose = () => {
        this.setState({
            reviewOpen: false
        })
    }

    postReview = () => {
        let { reviewText, reviewSliderVal } = this.state;
        console.log('review text ', reviewText);

        const review = {
            userName: 'sarat',
            teacherName: 'celina',
            userEmail: 'sarat.43@gmail.com',
            teacherEmail: 'celina.lemir@gmail.com',
            reviewText: reviewText,
            createdDate: moment(),
            rating: reviewSliderVal,
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/reviewPost`, review)
            .then(res => this.handlePostReviewResponse(res))
            .catch(error => console.log(error));
    }

    valuetext = (value) => {
        // this.setState({
        //     reviewSliderVal: value
        // })
        return value;
    }

    handlePostReviewResponse = (res) => {
        this.setState({
            reviewPostingSuccessful: true
        })
    }

    handleReviewSliderChange = (e) => {
        this.setState({
            reviewSliderVal: e.target.value
        })
    }

    getReviews = () => {
        const data = {
            teacherEmail: "celina.lemir@gmail.com",
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/getReviews`, data)
            .then(res => this.handleReviewsResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewsResponse = (res) => {
        this.setState({
            reviewsArray: res.data,
            reviewsOpen: true
        })
    }

    handleReviewsClose = () => {
        this.setState({
            reviewsOpen: false
        })
    }

    handleVideoOpen = (index) => {
        const { selectedIndex, ratersArray } = this.state;
        this.setState({
            videoOpen: true,
            videoUrl: ratersArray[index].videoLink
        })
    }

    handleVideoClose = () => {
        this.setState({
            videoOpen: false
        })
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }

    retrieveDateImageUrl = (index) => {
        return this.state['dateimageUrl' + index];
    }

    retrieveCountryLogo = (index) => {
        const { ratersArray } = this.state;

        return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + ratersArray[index].country + ".svg";
    }

    retrieveVideoUrl = () => {
        const { selectedIndex, ratersArray } = this.state;

        this.setState({
            videoUrl: ratersArray[selectedIndex].videoLink
        })
        //return ratersArray[selectedIndex].videoLink;
    }

    getAvailableTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
            .then(res => this.handleAvailableTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates(),
                progress: false
            })
        })
    }

    handleAvailableTimeSlotResponse = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    getOnlineDateMonth = (bookedDate) => {
        const date = new Date(bookedDate);
        let month = date.toLocaleString('default', { month: 'long' });
        return month.substring(0, 3);
        //return 'hi';
    }

    getOnlineDateDate = (bookedDate) => {
        const date = new Date(bookedDate);
        //return 'hi';
        return date.getDate();
    }

    getOnlineDateDay = (bookedDate) => {
        const date = new Date(bookedDate);
        const weekday = date.toLocaleString('default', { weekday: 'long' });
        //return 'hi';
        return weekday;
    }

    goToChatRoom = (roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages) => {
        const data = {
            userEmail: userEmail,
            teacherEmail: teacherEmail,
            accessToken: cookies.get("access-token"),
        }
        axios.post(`${URL_PREFIX()}/datepracticals/getMessages`, data)
            .then(res => this.handleresponseForMessages(roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages, res))
            .catch(error => console.log('error', error));
    }

    handleresponseForMessages = (roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages, res) => {
        socket.emit("join_message_room", roomId);
        this.setState({
            showChat: fromTheMessages,
            showModelChat: fromTheBox,
            room: roomId,
            username: userName,
            teacherName: teacherName,
            userEmail: userEmail,
            teacherEmail: teacherEmail,
            sentBy: cookies.get('email'),
            raterIndex: index,
            messageList: res.data
        })
    }

    handleChatClose = () => {
        this.setState({
            showModelChat: false
        })
    }

    setShowInstructorDialog = () => {
        this.setState({
            showInstructorsProfile: false
        })
    }

    setMessageList = (list) => {
        this.setState({
            messageList: list
        })
    }

    //get the available dates for the selected day
    handleWeekSelection = (weekday, state) => {
        const { weekArray, today, availableTimes } = state;

        this.setState({
            selectedWeekDayMobile: weekday
        })

        for (let i = 0; i < 7; i++) {
            if (weekArray[i] === weekday) {
                let currentDay = Object.assign(moment(today).add(i, 'days'));
                this.setState({
                    availableTimesMobile: availableTimes[i],
                    selectedWeekDayMobileIndex: i
                }, () => {
                    this.getAvailableTimeSlotsForSelectedDay(currentDay);
                    this.getBookedTimeSlotsForSelectedDay(currentDay, true);
                })
                break;
            }
        }
    }

    render() {
        const { email, username, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, datesArray, availableTimeSlotDict, showAvailableDatesDialog, showModelChat, room, teacherName, userEmail, teacherEmail, sentBy, raterIndex,
            showInstructorsProfile, selectedWeeklyTimes, selectedWeeklyDays, selectedIndex, messageList, selectedWeekDayMobile, availableTimesMobile, selectedWeekDayMobileIndex,
            progress
        } = this.state;
        const { navigate } = this.props;
        const { isProfilePic, attractivenessSliderVal, confidenceSliderVal, charmingSliderVal, responsibleSliderVal,
            funSliderVal, intelligentSliderVal, willingToDateSliderVal, picOrderSliderVal, needtoLookAtCamera, toSmile, toDelete,
            tooMuchFiltering, isLightingBad, isBadSelfie, phoneNoOrEmail, suggestions, pictureEffectivenessSliderVal, looksTooYoung, getHairCut, trimYourBeard, niceGuyVibe, reduceWeight } = review;

        const { promptEffectivenessSliderVal, showsPersonality, isShortBio, wantToDate, notReleatedAnswer, deleteThisPrompt, replaceThisPrompt, gramatticalMistakes,
            difficultToUnderstand, boringAnswer, tooLongAnswer, deletePolitics, deleteReligion, whatYouAreLookingFor, hobbiesAndInterests, racistVibe,
            goldDiggerVibe, looksTooOld, givingTooMuchInfo, workOnYourself, mentalHealthIssues, bioSuggestions,
            buildMuscles, museumsAndEvents, goToYogaClasses, goOut, goToFitnessGroupClasses, getACoach, goToBars, joinsalsaClasses,
            dontTalk, askQuestions, lightlyFlirt, workOnYourConfidence, goToLibraries, askPhoneNo, showFunSide, beAuthentic, approachMoreWomen, getAJob,
            workOnCareer, loveYourself, getATherapist, getMoreFriends, helpPeople, beVulnerable, connectWithPeople, celebrateYourLife, workOnSpiritualSide,
            workOnSelfEsteem, practiceMeditation
        } = bioReview;

        return (
            <div >
                <React.Fragment>
                    {/* {progress ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={progress}

                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : null} */}
                    <Grid container>
                        <Grid item xs={12} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Book your online Date
                        </Grid>
                    </Grid>

                    {ratersArray && ratersArray.length > 0 && ratersArray.map((rater, index) => (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <React.Fragment>
                                        <Box sx={{
                                            width: "100%",
                                            height: 300,
                                            padding: "3%",
                                            border: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderRadius: 4,
                                            marginTop: '2%'
                                        }} onClick={() => this.setState({ selectedIndex: index })}>
                                            <Grid container>
                                                <Grid item xs={6} md={3} lg={3}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div style={{ position: 'relative' }}>
                                                                <img src={this.retrieveImageUrl(index)} style={
                                                                    {
                                                                        width: '100%',
                                                                        height: 200,
                                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                        borderRadius: 4,
                                                                        cursor: 'pointer',
                                                                    }} onClick={() => { this.setState({ showInstructorsProfile: true }) }}></img>
                                                                <Button style={{ marginLeft: '5px', color: "black", position: 'absolute', left: '23%', top: '72%', backgroundColor: '#D3D3D3' }} variant="contained"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            videoOpen: true,
                                                                            videoUrl: ratersArray[index].videoLink,
                                                                            videoUsername: rater.firstname
                                                                        })
                                                                    }}>
                                                                    <PlayCircleOutlineIcon />
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={6} md={9} lg={9}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <span style={{ marginLeft: '1.5rem', marginBottom: '1.5 rem', textTransform: 'capitalize', }}>{rater.firstname}</span>
                                                            <span style={{ marginLeft: '0.5rem', marginBottom: '1.8%', }}>
                                                                <img
                                                                    alt="United States"
                                                                    src={this.retrieveCountryLogo(index)}
                                                                    style={{ width: 20, height: 14, marginTop: '1.5 rem' }} /></span>
                                                        </Grid>
                                                        <Grid item xs={12} lg={12} md={12} style={{ marginLeft: '1.5rem' }}>
                                                            <Grid container>
                                                                <Grid item xs={4} lg={5} style={{}}>
                                                                    <Stack direction="row" spacing={2}>
                                                                        <Button color={'primary'}
                                                                            variant='contained' style={reviewStyleNormal} onClick={this.getReviews}>
                                                                            <Grid container>
                                                                                <Grid item xs={7}>
                                                                                    <Rating name="half-rating" defaultValue={5} precision={0.5} max={1} />
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ fontWeight: 'bold', color: '#FFC300', fontSize: 16, fontWeight: 'bold', }}>
                                                                                    5.0
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Button>
                                                                        {!this.isMobile ?
                                                                            <span style={this.isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '3%', marginTop: '6px', fontSize: 15 }}>${rater.priceSlider}/hour</span>
                                                                            : null}
                                                                    </Stack>
                                                                </Grid>
                                                                {this.isMobile ?
                                                                    <Grid item xs={12} md={5} lg={5} style={this.isMobile ? { marginTop: '11%' } : { marginLeft: '3%', marginTop: '6px' }}>
                                                                        <span style={this.isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '4px', marginTop: '6px', fontSize: 15 }}>${rater.priceSlider}/hour</span>
                                                                    </Grid>
                                                                    : null}
                                                            </Grid>
                                                        </Grid>
                                                        {!this.isMobile ?
                                                            <Grid item xs={12} style={{ marginLeft: '1.5rem', marginTop: '6px' }}>
                                                                {rater.aboutYou}
                                                            </Grid>
                                                            : null}
                                                        <Grid item xs={8} style={this.isMobile ? { marginLeft: '1.5rem', marginTop: '58%' } : rater.aboutYou ? { marginLeft: '1.5rem', marginTop: '15%' } :
                                                            { marginLeft: '1.5rem', marginTop: '18%' }}>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {!this.isDesktop && !this.isMobile ?
                                                <br /> : null}
                                            <Grid container>
                                                <Grid item xs={2} md={3} lg={3}>
                                                    <Button color='primary' variant="outlined" value={JSON.stringify(rater)} style={{
                                                        width: '100%', height: '40px', marginBottom: '15px',
                                                        backgroundColor: this.isMobile ? 'white' : '#FF00FF', color: 'white',
                                                    }} onClick={() => this.goToChatRoom(rater.room, username, rater.firstname, cookies.get("email"), rater.email, index, true, false)}>
                                                        {this.isMobile ?
                                                            <IconButton onClick={this.handleClose}>
                                                                <MessageIcon />
                                                            </IconButton> : 'Send message'}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={10} md={9} lg={9}>
                                                    <Button color='primary' variant="outlined" onClick={this.handleOpen} value={JSON.stringify(rater)} style={{
                                                        width: this.isMobile ? '90%' : '95%', height: '40px', marginBottom: '15px',
                                                        backgroundColor: '#FF00FF', color: 'white', marginLeft: '1.5rem'
                                                    }}>
                                                        Book now
                                                    </Button>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                        {/* <Grid container spacing={2} style={{ marginTop: '0.01%' }} >
                                            < Grid item xs={3}>

                                            </Grid>
                                            <Grid item xs={8} >

                                                <Button color='primary' variant="outlined" onClick={this.handleOpen} value={JSON.stringify(rater)}>
                                                    Book now
                                                </Button>
                                            </Grid>
                                        </Grid> */}
                                    </React.Fragment>
                                </Grid>
                                {!this.isMobile && !this.isSmallerScreen ?
                                    <Grid item sm={0} md={6} lg={6}>
                                        <React.Fragment>
                                            <Box sx={{
                                                width: "100%",
                                                height: 300,
                                                padding: "3%",
                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                borderRadius: 4,
                                                marginTop: '2%'
                                            }} onClick={() => this.setState({ selectedIndex: index })}>
                                                <Grid container style={{ marginLeft: '10px' }}>
                                                    <Grid item xs={12}>
                                                        <ReactPlayer width='100%' height={250} url={ratersArray[index].videoLink} controls />
                                                    </Grid>

                                                    <Grid item xs={12} style={{ marginTop: '6px' }}>

                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </React.Fragment>
                                    </Grid>
                                    : null}
                            </Grid>
                            {this.isMobile ? <br /> : null}
                        </>
                    ))
                    }
                    <Grid container>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                    {
                        open ?
                            <Dialog
                                fullWidth
                                maxWidth="md"
                                open={open}
                                onClose={this.handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '80%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container>
                                                <Grid item>
                                                    Book your date
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                                        {"1 hour"}
                                                    </Button>
                                                </Grid>
                                                {/* <Grid item>
                                                    <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                                        {"30 min"}
                                                    </Button>
                                                </Grid> */}
                                                {dateBookingSuccessful ?
                                                    <Grid item>
                                                        <Alert severity="success" style={{ backgroundColor: '#A3E4D7', marginLeft: '100px' }}>
                                                            Date has booked successfully on {dateBookingMessage}.
                                                        </Alert>
                                                    </Grid>
                                                    : null}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={this.handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Stack direction="row" spacing={2} style={!dateBookingSuccessful ? { marginTop: '15px' } : null}>
                                        <Button variant="outlined" onClick={this.bringPreviousWeek} disabled={currentDayDiff <= 0}>
                                            {"<"}
                                        </Button>
                                        <span style={{ marginLeft: '8px', fontWeight: 'bold', }}>
                                            {todayDate + " - " + weekAfterDate + ", " + weekAfterYear}
                                        </span>
                                        <Button variant="outlined" onClick={this.bringNextWeek} style={{ marginLeft: '8px' }}>
                                            {">"}
                                        </Button>
                                        {/* <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                            {"1 hour"}
                                        </Button>
                                        <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                            {"30 min"}
                                        </Button> */}
                                    </Stack>
                                </DialogTitle>
                                <DialogContent>
                                    {!this.isMobile ?
                                        <Stack direction="row" spacing={2}>
                                            {availableTimes.map((i, index) => (
                                                <React.Fragment>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Item>
                                                                {weekArray[index]}
                                                            </Item>
                                                        </Grid>
                                                        <br />
                                                        {i[weekArray[index]].map((time, ind) => (
                                                            <>
                                                                <span style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Grid item xs={12}>
                                                                        <br />
                                                                        <Button variant="outlined" color='secondary'
                                                                            disabled={this.checkCurrentTimeBefore(weekArray[index] + "-" + time) || (!(availableTimeSlotDict && availableTimeSlotDict[index] && availableTimeSlotDict[index].indexOf(time) !== -1)) ||
                                                                                (bookedTimeSlotDict && bookedTimeSlotDict[index] && bookedTimeSlotDict[index].indexOf(time) !== -1)}
                                                                            style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[index] && (selectedWeeklyTimes[index].indexOf(time) > -1
                                                                                && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[index] && selectedWeeklyDays[index] === weekArray[index]) ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal}
                                                                            onClick={this.handleTimeSelection} value={weekArray[index] + "-" + time + "-" + index}>
                                                                            {time}
                                                                        </Button>
                                                                    </Grid>
                                                                </span>
                                                            </>
                                                        ))}
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                            }
                                        </Stack>
                                        :
                                        <>
                                            <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>
                                                <>
                                                    {weekArray && weekArray.map((i, index) => (

                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Item onClick={() => this.handleWeekSelection(weekArray[index], this.state)} value={weekArray[index]}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={() => this.handleWeekSelection(weekArray[index], this.state)} value={weekArray[index]}>{weekArray[index]}</span>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                    }
                                                </>
                                            </Stack>
                                            <br />
                                            {availableTimesMobile && availableTimesMobile[selectedWeekDayMobile] && availableTimesMobile[selectedWeekDayMobile].map((time, index) => (
                                                <>
                                                    <span style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Grid item xs={10}>
                                                            <br />
                                                            <Button variant="outlined" color='secondary'
                                                                disabled={this.checkCurrentTimeBefore(weekArray[selectedWeekDayMobileIndex] + "-" + time) || (!(availableTimeSlotDict && availableTimeSlotDict[0] && availableTimeSlotDict[0].indexOf(time) !== -1)) ||
                                                                    (bookedTimeSlotDict && bookedTimeSlotDict[0] && bookedTimeSlotDict[0].indexOf(time) !== -1)}
                                                                style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[selectedWeekDayMobileIndex] && (selectedWeeklyTimes[selectedWeekDayMobileIndex].indexOf(time) > -1
                                                                    && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[selectedWeekDayMobileIndex] && selectedWeeklyDays[selectedWeekDayMobileIndex] === weekArray[selectedWeekDayMobileIndex]) ? oneHourOr30MinTimeSelectStyleEnableMobile : oneHourOr30MinTimeSelectStyleNormalMobile}
                                                                onClick={this.handleTimeSelection} value={weekArray[selectedWeekDayMobileIndex] + "-" + time + "-" + selectedWeekDayMobileIndex}>
                                                                {time}
                                                            </Button>
                                                        </Grid>
                                                    </span>
                                                </>
                                            ))
                                            }
                                        </>
                                    }
                                </DialogContent>

                                <DialogActions>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sm={5} md={5} lg={5}>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={1} lg={1}>
                                            <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.bookDateForStudent}>Book</Button>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={1} lg={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.cancelDate}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>
                            : null
                    }
                    {
                        reviewOpen ?
                            <Dialog
                                fullWidth
                                maxWidth="md"
                                open={reviewOpen}
                                onClose={this.handleReviewClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '80%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container>
                                                <Grid item>
                                                    Post your review
                                                </Grid>

                                                {reviewPostingSuccessful ?
                                                    <Grid item>
                                                        <Alert severity="success" style={{ backgroundColor: '#A3E4D7', marginLeft: '100px' }}>
                                                            Review has posted successfully.
                                                        </Alert>
                                                    </Grid>
                                                    : null}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={this.handleReviewClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                </DialogTitle>
                                <DialogContent>

                                    <Grid container spacing={1} style={{ marginTop: '6px' }}>
                                        <Grid item xs={2} style={{ marginTop: '6px' }}>
                                            <span style={{ marginTop: '22px' }}></span>{'Rating:'}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Slider aria-label="Custom marks" defaultValue={reviewSliderVal} step={1} marks={marks} min={1} max={5} onChange={this.handleReviewSliderChange} />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '6px' }}>
                                            Comments:
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '6px' }}>
                                            <TextField
                                                fullWidth
                                                placeholder=""
                                                multiline
                                                rows={10}
                                                sx={{
                                                    width: '400px',
                                                    height: '200px'
                                                }}
                                                variant="outlined"
                                                onChange={(e) => this.setState({
                                                    reviewText: e.target.value
                                                })}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>

                                <DialogActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.postReview}>Post</Button>
                                        </Grid>
                                        <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.handleReviewClose}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>

                            </Dialog>
                            : null
                    }
                    {
                        reviewsOpen ?
                            <Dialog
                                fullWidth
                                maxWidth="md"
                                open={reviewsOpen}
                                onClose={this.handleReviewsClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '50%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container>
                                                <Grid item>
                                                    Reviews
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={this.handleReviewsClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                </DialogTitle>
                                <DialogContent>
                                    <React.Fragment>
                                        {reviewsArray.map((review) => (
                                            <Box sx={{
                                                width: "100%",
                                                height: 100,
                                                padding: "10px 10px 10px 10px",
                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                borderRadius: 4,

                                            }}>
                                                <Grid container>
                                                    <Grid item xs={8}>
                                                        <Grid container>
                                                            <Grid item xs={1} style={{ marginTop: '2px' }}>
                                                                <Typography>{review.userName[0].toUpperCase() + review.userName.slice(1)}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} >
                                                                <Grid container>
                                                                    <Grid item xs={3}>
                                                                        <Rating name="half-rating" defaultValue={5} precision={0.5} max={1} />
                                                                    </Grid>
                                                                    <Grid item xs={4} style={{ marginTop: '6px', fontWeight: 'bold', color: '#FFC300' }}>
                                                                        {review.rating + ".0"}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} style={{ marginTop: '6px' }}>
                                                        <Typography>{review.reviewText}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </React.Fragment>

                                </DialogContent>

                                <DialogActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                        </Grid>
                                        <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.handleReviewsClose}>Close</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>

                            </Dialog>
                            : null
                    }
                    {
                        videoOpen ?
                            <Dialog
                                fullWidth
                                maxWidth="lg"
                                open={videoOpen}
                                onClose={this.handleVideoClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '100%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container>
                                                <Grid item>
                                                    {this.state.videoUsername}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={this.handleVideoClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ReactPlayer width='100%' url={videoUrl} controls />
                                        </Grid>
                                    </Grid>

                                </DialogTitle>
                                {/* <DialogContent>
                                    <React.Fragment>
                                        <Box sx={{
                                            width: "80%",
                                            height: "100%",
                                            padding: "20px 20px 10px 10px",
                                            border: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderRadius: 4,
                                            marginLeft: '30px'

                                        }}>
                                            <Grid container style={{ marginLeft: '10px' }}>
                                                <Grid item xs={12}>
                                                    <ReactPlayer width='100%' url={videoUrl} controls />
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '6px' }}>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </React.Fragment>
                                </DialogContent> */}

                                <DialogActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                        </Grid>
                                        <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" onClick={this.handleVideoClose}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>

                            </Dialog>
                            : null
                    }
                    {
                        showModelChat ?
                            <Dialog
                                fullWidth
                                maxWidth="lg"
                                open={showModelChat}
                                onClose={this.handleChatClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '100%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1} >
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={this.retrieveImageUrl(raterIndex)} width="100%" height="40px" sx={{
                                                            width: 30,
                                                            height: 20,
                                                            border: "1px solid rgba(0, 0, 0, 0.12)",
                                                            borderRadius: 4,
                                                        }}></img>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    {teacherName}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            alignItems: 'right',
                                        }}>
                                            <IconButton onClick={this.handleChatClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy} directMessage={true} messageList={messageList}
                                        setMessageList={this.setMessageList}
                                    />
                                </DialogTitle>
                                {/* <DialogContent>
                            <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy}
                            />
                        </DialogContent> */}
                                <DialogActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                        </Grid>
                                        <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" onClick={this.handleChatClose}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>
                            : null
                    }

                    {showInstructorsProfile ?
                        <Profile showDialog={showInstructorsProfile} rater={ratersArray[selectedIndex]} setShowDialog={this.setShowInstructorDialog} /> : null}
                </React.Fragment >





            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <Instructors {...props} location={location} navigate={navigate} />;
}
