import React from "react";
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
//import { ReactCountryDropdown } from 'react-country-dropdown';
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Slider from '@mui/material/Slider';
import DatePicker from "react-datepicker";
//import DatePicker from 'react-date-picker';

//import DatePicker from 'react-date-picker';
//import 'react-date-picker/dist/DatePicker.css';
//import 'react-calendar/dist/Calendar.css';
// import { isProd } from '../../util/Const';
import UploadImages from '../upload/Upload';
import { FamilyRestroomRounded } from "@mui/icons-material";
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';

import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import lovers from '../../assets/images/lovers.jpg';
import Heading from '../heading/Heading';

const cookies = new Cookies();


const styles = {
    loginform:
    {
        justifyContent: 'left',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'left',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'left',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    //padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const teacherSignupNormal = {
    borderRadius: 35,
    backgroundColor: "#52BE80",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    marginLeft: '10px'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const priceMarks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 100,
        label: 100
    },
    {
        value: 150,
        label: 150
    }, {
        value: 200,
        label: 200
    }
]

// const isProd = () => {
//     if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//         return false;
//     } else {
//         return true;
//     }
// }


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            username: "",
            password: "",
            email: '',
            authflag: 1,
            years: [],
            age: 2003,
            country: 'US',
            birthday: new Date(),
            userEmail: '',
            disablePicturesretrieval: false,
            openUpload: false,
            pictures: [],
            aboutYou: ''
        };
    }

    componentDidMount() {
        const { handleIsloginOrSignUpPage } = this.props;
        handleIsloginOrSignUpPage(isloginOrSignUpPage);
        this.getAgeYears();
        this.getCountries();
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;
    }

    getAgeYears = () => {
        const { years } = this.state;
        for (let i = 1920; i < 2010; i++) {
            years.push(i);
        }
        this.setState({
            years
        })
    }

    getCountries = () => {
        let countries = countryList().getData();

        countries = countries.map((country, index) => {
            return country.value
        })

        this.setState({
            countries
        })
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    handleResponse = (res) => {
        console.log('handleResponse hit');
        const { handleNameChange } = this.props;
        const { data } = res;
        const { firstname, password, email } = this.state;
        const { navigate } = this.props;
        handleNameChange(firstname);
        cookies.set("access-token", data?.authToken, {
            maxAge: 60 * 60 * 24 * 30 * 1000,
        });
        //cookies.set('email', email);
        cookies.set('username', firstname);
        cookies.set('email', email);
        cookies.set('rater', data.rater);

        this.setState({
            firstname: firstname,
            // username: firstname,
            // userEmail: email,
            password: password
        });

        navigate("/home", {
            state: {
                username: firstname,
                userEmail: email,
                isloginOrSignUpPage: false
            }
        });
    }

    handleSubmit = (event) => {
        const { navigate } = this.props;
        const { username, password, firstname, lastname, country, birthday, email } = this.state;
        const data = {
            username: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            email: email,
            rater: false,
            //videoLink: videoLink,
            country: country,
            birthday: birthday,
            // aboutYou: aboutYou,
            // priceSlider: priceSlider
        }
        console.log('IS_PROD', IS_PROD());

        let isProd = false;

        // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //     isProd = false;
        // } else {
        //     isProd = true;;
        // }

        // const isProd1 = isProd;
        // // REACT_APP_PROD_URL = "`${URL_PREFIX()}/"
        // // REACT_APP_DEV_URL = "http://localhost:5000/"
        // console.log(process.env.REACT_APP_PROD_URL);
        // console.log(process.env.REACT_APP_DEV_URL);

        axios.post(`${URL_PREFIX()}/datepracticals/signupUser`, data)
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));

        this.upload();
    }

    handleAgeChange = (e) => {
        const age = e.target.value;

        this.setState({
            age
        })
    }

    handleCountrySelect = (country) => {
        this.setState({
            country
        })
    }

    handleBirthdayChange = (day) => {
        this.setState({
            birthday: day
        })
    }

    handleDisablePicturesRetrieval = (picRetrieve) => {
        this.setState({
            disablePicturesretrieval: picRetrieve
        })
    }

    handleOpenUpload = (openUpload) => {
        this.setState({
            uploadOpen: openUpload
        })
    }

    setUploadOpen = (toOpen) => {
        this.setState({
            openUpload: toOpen
        })
    }

    handlePictures = (pictures) => {
        this.setState({
            pictures: pictures
        })
    }

    upload = () => {
        const { pictures, email } = this.state;
        //setShowProgress(true);
        let filesUploaded = 0;
        const pictureLength = pictures.length;

        pictures.forEach((picture, index) => {
            const file = picture.data;

            // Create an object of formData
            let formData = new FormData();

            // Update the formData object
            formData.append('file', file);
            formData.append('email', email);

            axios.post(`${URL_PREFIX()}/datepracticals/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((res) => {
                    filesUploaded += 1;
                    if (pictureLength === filesUploaded) {
                        this.setState({
                            pictures: []
                        })
                        console.log(file + " uploaded successfully");
                    }
                })
                .catch((error) => {
                    this.setState({ errormessage: error.response.statusText + " Please select the file" })
                })
        });
    }

    render() {
        const { years, age, country, countries, birthday, userEmail, openUpload } = this.state;
        const { navigate } = this.props;

        return (
            <div >
                <Heading />
                {this.isMobile ?
                    <>
                        <Grid container style={{ marginLeft: '3%' }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11} lg={4} md={6}>
                                        <Box sx={{
                                            width: "100%",
                                            height: 300,
                                            padding: "3%",
                                            border: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderRadius: 4,
                                            marginTop: '1%'
                                        }}>
                                            <img src={lovers} width="100%" height={'100%'} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: "100%",
                                                height: "100%",
                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                borderRadius: 4,
                                            }}></img>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginLeft: '3%', marginTop: '2%' }} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={11} style={{ fontSize: 30 }}>
                                        Let's practice social skills/dates?
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={11} >
                                Let us help practice online dates with women and gain the confidence to meet the women in the real life.
                                You can book online sessions with women and practice with them.
                            </Grid>

                            <Grid item xs={11} >
                                <Button color='primary' variant="outlined" onClick={() => {
                                    navigate("/dates");
                                }} style={{
                                    width: '98%', height: '50px', marginBottom: '5px',
                                    backgroundColor: '#FF00FF', color: 'white', marginLeft: '0.5rem', marginRight: '0.5rem'
                                }}>
                                    Book Instructors</Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container style={{ marginLeft: '1%' }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={0} sm={5} md={6} lg={6} >
                                        <Grid container style={{ marginLeft: '3%', marginTop: '2%' }} spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={11} style={{ fontSize: 30 }}>
                                                        Do you want to practice social skills/dates?
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={11} >
                                                Let us help practice online dates with women and gain the confidence to meet the women in the real life.
                                                You can book online sessions with women and practice with them.
                                            </Grid>

                                            <Grid item xs={11} >
                                                <Button color='primary' variant="outlined" onClick={() => {
                                                    navigate("/dates");
                                                }} style={{
                                                    width: '98%', height: '50px', marginBottom: '5px',
                                                    backgroundColor: '#FF00FF', color: 'white', marginLeft: '0.5rem', marginRight: '0.5rem'
                                                }}>
                                                    Book online</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={11} sm={6} lg={4} md={6}>
                                        <Box sx={{
                                            width: "100%",
                                            height: 300,
                                            padding: "3%",
                                            border: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderRadius: 4,
                                            marginTop: '1%'
                                        }}>
                                            <img src={lovers} width="100%" height={'100%'} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: "100%",
                                                height: "100%",
                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                borderRadius: 4,
                                            }}></img>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
            </div >
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <HomePage {...props} navigate={navigate} />;
}
