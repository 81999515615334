export const NODE_ENV = "development";
export const PROD_URL = "https://datepracticalsbackend-bae2144a0f02.herokuapp.com";
export const DEV_URL = "http://localhost:5000";


export const IS_PROD = () => {
    const loginPageLink = window.location.href.toString();
    if (loginPageLink.includes('localhost')) {
        return false;
    } else {
        return true;
    }
}

export const URL_PREFIX = () => {
    if (IS_PROD()) {
        return PROD_URL;
    } else {
        return DEV_URL;
    }
}

export const IS_MOBILE = () => {
    return window.innerWidth <= 600;
}

export const IS_LAPTOP = () => {
    return window.innerWidth >= 600 && window.innerWidth <= 1000;
}

export const IS_DESKTOP = () => {
    return window.innerWidth >= 1000;
}

