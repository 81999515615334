import React from "react";
import { withTheme, withMediaQuery } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
//import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import * as Chance from 'chance';

//import axios from 'axios';
//import { ReactCountryDropdown } from 'react-country-dropdown';
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Slider from '@mui/material/Slider';
//import DatePicker from "react-datepicker";
//import DatePicker from 'react-date-picker';


import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import ReactPlayer from 'react-player';
import { ThreeSixty, TroubleshootRounded } from "@mui/icons-material";

import { useTheme } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
import AddDates from '../addDates/AddDates';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Heading from '../heading/Heading';
import InstructorsList from '../instructorsList/InstructorsList';

const cookies = new Cookies();
const chance = new Chance();

// const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
//     defaultMatches: true
// });

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const TenHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%',
    width: 20
}

const TenHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    //marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    //marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    //padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const teacherSignupNormal = {
    borderRadius: 35,
    backgroundColor: "#52BE80",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const priceMarks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 100,
        label: 100
    },
    {
        value: 150,
        label: 150
    }, {
        value: 200,
        label: 200
    }
]

const splitScreen = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}

const heading = {
    height: '10%',
}

const topPane = {
    height: '60%',
}

const backButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '8.5%',
}

const submitButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '9%',
}

const nextButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '10%',
}

class ShowInstructors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            username: "",
            password: "",
            authflag: 1,
            years: [],
            age: 2003,
            country: 'US',
            birthday: new Date(),
            userEmail: '',
            disablePicturesretrieval: false,
            openUpload: false,
            pictures: [],
            aboutYou: '',
            screenVal: 0,
            priceSlider: 10,
            selectedWeekDays: [],
            selectedTimes: [],
            selectedTimesArray: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: [],
            bookDates: false,
            selectedTimesAndWeeks: false,
            slotBookedSuccessfully: false,
            age1: 20,
            rater: cookies.get("rater")
        };


    }

    componentDidMount() {
        this.isMobile = window.innerWidth <= 600;
        this.isLaptop = window.innerWidth >= 600 && window.innerWidth <= 1000;
        const { handleIsloginOrSignUpPage } = this.props;
        //handleIsloginOrSignUpPage(isloginOrSignUpPage);
        //this.getInitialValues();
        this.getTeachers();
        this.setState({
            showDatesDialog: this.props.showDialog
        })
    }

    getTeachers = () => {
        console.log('retrieving images list');
        const data = {
            //accessToken: cookies.get("access-token"),
            accessToken: cookies.get("access-token"),
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getTeachers`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    handleRatersResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((rater) => {
            if (uniqueratersEmail.indexOf(rater.email) === -1) {
                uniqueratersEmail.push(rater.email);
                uniqueRaters.push(rater)
            }
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters,
            selectedTeacher: uniqueRaters[0],
            instructorsEmail: uniqueRaters[0]?.email,
            showDatesDialog: true
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(rater.email, index)
            ));
        });
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }


    getInitialValues = () => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            //selectedTeacher: this.state.email
        }, () => {
        })
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = currentDay.diff(today, 'days');
            this.setState({
                currentDayDiff
            })

            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray();
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = () => {
        const amArray = this.getTime('AM');
        const pmArray = this.getTime('PM');
        const amPmArray = amArray.concat(pmArray);
        this.setState({
            amPmArray
        })
        return amPmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })

        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    handle24HourButton = () => {
        let { twentyFourHourClick } = this.state;
        twentyFourHourClick = !twentyFourHourClick;
        this.setState({
            twentyFourHourClick,
        })

        if (twentyFourHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false
            })
        }
    }

    handle10HourButton = () => {
        let { tenHourClick } = this.state;
        tenHourClick = !tenHourClick;
        this.setState({
            tenHourClick,
        })

        if (tenHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle5HourButton = () => {
        let { fiveHourClick } = this.state;
        fiveHourClick = !fiveHourClick;
        this.setState({
            fiveHourClick,
        })

        if (fiveHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle3HourButton = () => {
        let { threeHourClick } = this.state;
        threeHourClick = !threeHourClick;
        this.setState({
            threeHourClick,
        })

        if (threeHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle2HourButton = () => {
        let { twoHourClick, } = this.state;
        twoHourClick = !twoHourClick;
        this.setState({
            twoHourClick,
        })

        if (twoHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
        })
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    handleBookDateResponse = (res) => {
        let { username, password, lastname, videoLink, country, birthday, aboutYou, priceSlider, pictures,
            bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher, email, firstname, teacherEmail, selectedWeekDays } = this.state;
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
        // this.props.navigate("/home", {
        //     state: {
        //         username: firstname,
        //         userEmail: email,
        //         isloginOrSignUpPage: false
        //     }
        // });
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            //selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            this.getBookedTimeSlots(moment());
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    getBookedTimeSlots = (weekStartDate) => {
        let { selectedTeacher } = this.state;

        const data = {
            email: cookies.get("email"),
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    getAgeYears = () => {
        const { years } = this.state;
        for (let i = 1920; i < 2010; i++) {
            years.push(i);
        }
        this.setState({
            years
        })
    }

    getCountries = () => {
        let countries = countryList().getData();

        countries = countries.map((country, index) => {
            return country.value
        })

        this.setState({
            countries
        })
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    bookDate = () => {
        console.log('bookDate hit');
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;
        const { navigate } = this.props;

        this.setState({
            bookDates: true
        })
    }

    handleSubmit = (event) => {
        const { navigate } = this.props;
        const { username, password, firstname, lastname, videoLink, country, birthday, aboutYou, priceSlider, email, pictures } = this.state;
        const data = {
            username: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            email: email,
            rater: true,
            videoLink: videoLink,
            country: country,
            birthday: birthday,
            aboutYou: aboutYou,
            priceSlider: priceSlider
        }

        this.bookDate();

        if (pictures && pictures.length > 0) {
            this.upload(data);
        } else {
            this.handleResponse();
        }
    }

    handleBackButton = () => {
        let { screenVal } = this.state;

        if (screenVal > 0 && screenVal < 4) {
            screenVal -= 1;
        }

        this.setState({
            screenVal
        })
    }

    handleNextButton = () => {
        let { screenVal } = this.state;

        if (screenVal >= 0 && screenVal < 3) {
            screenVal += 1;
        }

        this.setState({
            screenVal
        })
    }

    handleAvailableSlotsDialog = () => {
        this.setState({
            openAvailableSlots: true,
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        })
    }

    handleCloseAvailableSlots = () => {
        this.props.setAvailableSlots(false);
    }

    setBookDates = (bookDates) => {
        this.setState({
            bookDates: bookDates
        }, () => {
            this.props.setAvailableSlots(false);
        })
    }

    showMessage = (suceessMessage) => {
        if (suceessMessage) {
            // this.setState({
            //     slotBookedSuccessfully: true
            // })
            this.props.setShowMessages(suceessMessage);
            this.props.setAvailableSlots(false);

            setTimeout(() => {
                this.props.setShowMessages(false);
            }, 5000)
        }
    }

    handleInstructorChange = (e) => {
        this.setState({
            instructor: e.target.value
        });
    }

    retrieveCountryLogo = (index) => {
        const { ratersArray } = this.state;

        return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + ratersArray[index].country + ".svg";
    }

    handleInstructorSelection = (index) => {
        const { ratersArray } = this.state;
        // const index = e.target.value;
        const instructorsMail = ratersArray && ratersArray.length > 0 ? ratersArray[index]?.email : '';
        this.setState({ instructorsEmail: instructorsMail, instructorsIndex: index })
    }

    handleInstructorsChange = (e) => {
        const { ratersArray } = this.state;

        this.setState({
            instructorsEmail: e.target.value,
            selectedTeacher: ratersArray.filter((rater) => rater.email === e.target.value)[0],
            showDatesDialog: true,
            ratersArray: ratersArray,
            instructorChange: true
        })
    }

    setInstructorChange = (instructorChange) => {
        this.setState({
            instructorChange: instructorChange
        })
    }

    handleCloseAddDates = () => {
        this.setState({
            selectedIndex: null
        })
    }


    render() {
        const { years, age, country, countries, birthday, userEmail, openUpload, screenVal, email, selectedWeeklyDays, selectedWeeklyTimes, openAvailableSlots,
            review, openLogin, bioReview, open, weekArray, weekDayArrayMapping, availableTimes, fiveHourClick, threeHourClick, tenHourClick, selectedWeekDays, twentyFourHourClick,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            twoHourClick, bookDates, selectedTimesAndWeeks, slotBookedSuccessfully, instructorsEmail, instructorsIndex, rater, selectedTeacher, showDatesDialog, instructorChange, selectedIndex } = this.state;
        return (
            <>
                <div>
                    <Dialog fullWidth
                        maxWidth="lg"
                        open={this.props.showDialog}
                        onClose={this.handleCloseAvailableSlots}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ width: '100%' }}
                        classes={{ paperFullWidth: { 'max-width': '100%' } }}>

                        <DialogTitle>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item>
                                            Instructors
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={this.handleCloseAvailableSlots}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        <DialogContent>
                            <>
                                {ratersArray && ratersArray.length > 0 && ratersArray.map((rater, index) => (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <React.Fragment>
                                                    <Box sx={{
                                                        width: "100%",
                                                        height: this.isMobile ? 130 : 110,
                                                        padding: "1%",
                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderRadius: 4,
                                                        marginTop: '5px'
                                                    }} onClick={() => this.setState({ selectedIndex: index })}>
                                                        <Grid container>
                                                            <Grid item xs={5} sm={5} md={3} lg={2}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <div style={{ position: 'relative' }}>
                                                                            <img src={this.retrieveImageUrl(index)} style={
                                                                                {
                                                                                    width: '100%',
                                                                                    height: 80,
                                                                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                                    borderRadius: 4,
                                                                                    cursor: 'pointer',
                                                                                }} ></img>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={7} sm={7} md={4} lg={4}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <span style={{ marginLeft: '1.5rem', marginBottom: '1.5 rem', textTransform: 'capitalize', }}>{rater.firstname}</span>
                                                                        <span style={{ marginLeft: '0.5rem', marginBottom: '1.8%', }}>
                                                                            <img
                                                                                alt="United States"
                                                                                src={this.retrieveCountryLogo(index)}
                                                                                style={{ width: 20, height: 14, marginTop: '1.5 rem' }} /></span>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={12} md={12} style={{ marginLeft: '1.5rem', marginTop: '5px' }}>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={4} lg={5} style={{}}>
                                                                                <Stack direction="row">
                                                                                    <Button color={'primary'}
                                                                                        variant='contained' style={reviewStyleNormal} onClick={this.getReviews}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={7}>
                                                                                                <Rating name="half-rating" defaultValue={5} precision={0.5} max={1} />
                                                                                            </Grid>
                                                                                            <Grid item xs={4} style={{ fontWeight: 'bold', color: '#FFC300', fontSize: 16, fontWeight: 'bold', }}>
                                                                                                5.0
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Button>
                                                                                    {!this.isMobile || this.isMobile ?
                                                                                        <span style={this.isMobile ? { marginTop: '6%', fontSize: 15, marginLeft: '6px', } : { marginLeft: '6px', marginTop: '6px', fontSize: 15 }}>${rater.priceSlider}/hour</span>
                                                                                        : null}
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={5} lg={6}>
                                                                <Button color='primary' variant="outlined" onClick={() => {
                                                                    const { navigate } = this.props;
                                                                    if (!cookies.get("access-token")) {
                                                                        navigate("/signup");
                                                                    }
                                                                    this.setState({ showSubscriptionPlansDialog: true })
                                                                }} value={JSON.stringify(rater)} style={{
                                                                    width: this.isMobile ? '95%' : '98%', height: this.isMobile ? '30px' : '50px', marginBottom: '5px',
                                                                    backgroundColor: '#FF00FF', color: 'white', marginLeft: '0.5rem', marginRight: '0.5rem'
                                                                }}>
                                                                    Book
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid >
                                        {this.isMobile ? <br /> : null}
                                    </>
                                ))
                                }
                                <Grid container>
                                    <Grid item xs={12}>
                                    </Grid>
                                </Grid>
                            </>
                        </DialogContent>
                    </Dialog>

                    <div>
                        {showDatesDialog && ratersArray && ratersArray[selectedIndex] ?
                            <Dialog
                                fullWidth
                                maxWidth="md"
                                open={ratersArray[selectedIndex]}
                                onClose={this.handleCloseAddDates}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ width: '100%' }}
                                classes={{ paperFullWidth: { 'max-width': '80%' } }}
                            >
                                <DialogTitle>
                                    <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                            <Grid container>
                                                <Grid item xs={8} md={6} lg={5}>
                                                    Book your available slots
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={this.handleCloseAddDates}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <AddDates bookDates={bookDates} setBookDates={this.setBookDates} showMessage={this.showMessage} selectedTeacher={ratersArray[selectedIndex]} instructorChange={instructorChange} setInstructorChange={this.setInstructorChange} balance={this.props.balance} />
                                </DialogContent>
                                <DialogActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2} sm={3} md={5} lg={5}>
                                        </Grid>
                                        <Grid item xs={3} sm={1} md={1} lg={1}>
                                            <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.bookDate}>Book</Button>
                                        </Grid>
                                        <Grid item xs={3} sm={1} md={1} lg={1} style={{ marginLeft: '0.3em' }}>
                                            <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.cancelDate}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>
                            : null}
                    </div>
                    {oneHourClick ?
                        <InstructorsList />
                        : null}
                </div >
            </>
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <ShowInstructors {...props} navigate={navigate} />;
}
