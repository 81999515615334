import React from "react";
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@material-ui/core";
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';



const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});


class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { username: "", password: "", authflag: 1 };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({ username: event.state.username, password: event.state.password });
    }
    handleSubmit(event) {
        const { navigate } = this.props;
        event.preventDefault();
        navigate("/login");
    }
    render() {
        //const { classes } = this.props;
        return (
            <div>
                <Grid container style={{ marginTop: '10px', marginLeft: '5%', marginBottom: '10px' }} >
                    {/* <Grid item xs={1}></Grid> */}
                    {/* <Grid item xs={2} >
                        <Typography component="h1" variant="h6" >
                            {'datepracticals'}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={8}></Grid>
                    {/* <Grid item xs={2} style={{}}>
                        <BootstrapLoginButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={this.handleSubmit}
                            className={styles.buttonBlock}
                        >
                            Login
                        </BootstrapLoginButton >
                    </Grid> */}
                </Grid>
                {/* <Divider /> */}
                <Grid container spacing={0} justify="center" direction="row">
                    {/* <Grid item>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                            className={classes.loginForm}
                        > */}
                    {/* <Paper
                        variant="elevation"
                        elevation={2}
                        className={styles.loginBackground}
                    > */}
                    <Grid item style={{ justifyContent: 'center', marginLeft: '5%' }}>
                        <Typography component="h1" variant="h5" style={{ color: 'D3D3D3' }}>
                            How is your dating profile?
                        </Typography>
                    </Grid>
                    <Grid item style={{ padding: 10, justifyContent: 'center', marginLeft: '30%', marginRight: '30%' }}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography component="h1" variant="h6">
                                    Upload photos of Tinder or Bumble or Hinge dating profile,
                                    find out if people want to go out with you or not.
                                </Typography>
                            </Grid>
                            <Grid item>

                            </Grid>
                            <Grid item>
                                <BootstrapButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.handleSubmit}
                                    className={styles.buttonBlock}
                                >
                                    Test your dating profile
                                </BootstrapButton >
                            </Grid>
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    No Account? Signup.
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    {/* </Paper> */}
                    {/* </Grid>
                    </Grid> */}
                </Grid>
            </div>
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <LandingPage {...props} navigate={navigate} />;
}
//export default withStyles(styles)(Login);