import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {

  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,

} from "@material-ui/core";
import Stack from '@mui/material/Stack';
import Rating from "@mui/material/Rating";
import Cookies from 'universal-cookie';

import * as Chance from 'chance';
import moment from 'moment';
import axios from 'axios';
import Heading from '../heading/Heading';
import HeadingLogin from '../heading/HeadingLogin';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

const cookies = new Cookies();

const reviewStyleNormal = {
  borderRadius: 35,
  backgroundColor: "white",
  color: '#FFC300',
  padding: "1px 6px",
  fontSize: "12px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

class BeforePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      teacherName: '',
      price: 10
    }
  }

  componentDidMount() {
    const loginPageLink = window.location.href.toString();
    const splitArray = loginPageLink.split("/beforePaymentSubscription/");
    const splitDetailsArray = splitArray ? splitArray[1].split("/") : [];
    const id = splitDetailsArray ? splitDetailsArray[0] : null;
    const teacherName = splitDetailsArray ? splitDetailsArray[1] : null;
    const price = splitDetailsArray ? splitDetailsArray[2] : null;
    const teacherEmail = splitDetailsArray ? splitDetailsArray[3] : null;
    const hourCost = splitDetailsArray ? splitDetailsArray[4] : null;
    this.retrieveTeacherImage(teacherEmail);

    this.setState({
      id,
      teacherName,
      price,
      teacherEmail,
      hourCost
    })
  }

  retrieveTeacherImage = (emailToRetrieve) => {
    console.log('retrieving retrieveTeacherImage');
    const data = {
      email: emailToRetrieve,
      accessToken: cookies.get("access-token"),
    };

    axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
      .then(res => this.handleTeacherImageResponse(res))
      .catch(error => console.log(error));
  }

  handleTeacherImageResponse = (res) => {
    console.log('handleFirstImageResponse hit', res);

    this.setState({
      ['teacherImageUrl']: URL.createObjectURL(res.data)
    })
  }

  render() {
    const { id, teacherName, price, teacherImageUrl, hourCost, teacherEmail } = this.state;

    return (
      <div>
        <HeadingLogin />
        <br />
        <br />
        <br />
        <Box sx={{
          width: "100%",
          height: 300,
          padding: "2%",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 4,

        }} >
          <Grid container>
            <Grid item xs={6} md={3} lg={3}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={{ position: 'relative' }}>
                    <img src={teacherImageUrl} style={
                      {
                        width: '100%',
                        height: 180,
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}></img>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={6} md={9} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span style={{ marginLeft: '1.5rem', marginBottom: '1.5 rem', textTransform: 'capitalize', }}>{teacherName}</span>
                  {/* <span style={{ marginLeft: '0.5rem', marginBottom: '1.8%', }}>
                    <img
                      alt="United States"
                      src={this.retrieveCountryLogo(index)}
                      style={{ width: 20, height: 14, marginTop: '1.5 rem' }} /></span> */}
                </Grid>
                <Grid item xs={12} lg={12} md={12} style={{ marginLeft: '1.5rem' }}>
                  <Grid container>
                    <Grid item xs={4} lg={5} style={{}}>
                      <Stack direction="row" spacing={2}>
                        <Button color={'primary'}
                          variant='contained' style={reviewStyleNormal} onClick={this.getReviews}>
                          <Grid container>
                            <Grid item xs={7}>
                              <Rating name="half-rating" defaultValue={5} precision={0.5} max={1} />
                            </Grid>
                            <Grid item xs={4} style={{ fontWeight: 'bold', color: '#FFC300', fontSize: 16, fontWeight: 'bold', }}>
                              5.0
                            </Grid>
                          </Grid>
                        </Button>
                        {!this.isMobile ?
                          <span style={this.isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '3%', marginTop: '6px', fontSize: 15 }}>${hourCost}/hour</span>
                          : null}
                      </Stack>
                    </Grid>
                    {this.isMobile ?
                      <Grid item xs={12} md={5} lg={5} style={this.isMobile ? { marginTop: '11%' } : { marginLeft: '3%', marginTop: '6px' }}>
                        <span style={this.isMobile ? { marginTop: '6%', fontSize: 15 } : { marginLeft: '4px', marginTop: '6px', fontSize: 15 }}>${hourCost}/hour</span>
                      </Grid>
                      : null}
                  </Grid>
                </Grid>
                {!this.isMobile ?
                  <Grid item xs={12} style={{ marginLeft: '1.5rem', marginTop: '6px' }}>
                    Going to cost ${price} for {price / hourCost} dates.
                  </Grid>
                  : null}
                {/* <Grid item xs={8} style={this.isMobile ? { marginLeft: '1.5rem', marginTop: '58%' } : rater.aboutYou ? { marginLeft: '1.5rem', marginTop: '15%' } :
                  { marginLeft: '1.5rem', marginTop: '18%' }}>

                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          {!this.isDesktop && !this.isMobile ?
            <br /> : null}
          <Grid container>
            <Grid item xs={10} md={9} lg={9}>
              <Button color='primary' variant="outlined" onClick={() => {
                this.props.navigate(`/paymentSubscription/${id}/${teacherName}/${price}/${teacherEmail}/${hourCost}/${teacherImageUrl}`, {
                });
              }} style={{
                width: this.isMobile ? '90%' : '95%', height: '40px', marginBottom: '15px',
                backgroundColor: '#FF00FF', color: 'white',
              }}>
                Continue checkout
              </Button>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Button color='primary' variant="outlined" onClick={() => {
                this.props.navigate(`/home`, {

                });
              }} style={{
                width: this.isMobile ? '90%' : '95%', height: '40px', marginBottom: '15px',
                backgroundColor: '#FF00FF', color: 'white',
              }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

export default function (props) {
  const location = useLocation();
  const navigate = useNavigate();

  return <BeforePayment {...props} location={location} navigate={navigate} />;
}

