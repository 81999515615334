import React from "react";
import { withTheme, withMediaQuery } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
//import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
//import axios from 'axios';
//import { ReactCountryDropdown } from 'react-country-dropdown';
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Slider from '@mui/material/Slider';
//import DatePicker from "react-datepicker";
//import DatePicker from 'react-date-picker';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
//import moment from 'moment';

//import UploadImages from '../upload/Upload';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

// import {


//     Grid,
//     Paper,
//     AppBar,
//     Typography,
//     Toolbar,

// } from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";


import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import { TroubleshootRounded } from "@mui/icons-material";

import { useTheme } from '@mui/material/styles';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import Heading from '../heading/Heading';

const cookies = new Cookies();

// const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
//     defaultMatches: true
// });

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const TenHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%',
    width: 20
}

const TenHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    //padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const teacherSignupNormal = {
    borderRadius: 35,
    backgroundColor: "#52BE80",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const priceMarks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 100,
        label: 100
    },
    {
        value: 150,
        label: 150
    }, {
        value: 200,
        label: 200
    }
]

const splitScreen = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}

const heading = {
    height: '10%',
}

const topPane = {
    height: '60%',
}

const backButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '8.5%',
}

const submitButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '9%',
}

const nextButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '10%',
}

class TeacherSignUpPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            username: "",
            password: "",
            authflag: 1,
            years: [],
            age: 2003,
            country: 'US',
            birthday: new Date(),
            userEmail: '',
            disablePicturesretrieval: false,
            openUpload: false,
            pictures: [],
            aboutYou: '',
            screenVal: 0,
            priceSlider: 1,
            selectedWeekDays: [],
            selectedTimes: [],
            selectedTimesArray: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        };


    }

    componentDidMount() {
        //const { children, classes, theme } = this.props;
        // this.isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        //     defaultMatches: true
        // });
        // this.isLaptop = useMediaQuery(theme.breakpoints.up('md'), {
        //     defaultMatches: true
        // });
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;
        const { handleIsloginOrSignUpPage } = this.props;
        handleIsloginOrSignUpPage(isloginOrSignUpPage);
        this.getAgeYears();
        this.getCountries();
        this.getInitialValues();
    }

    getInitialValues = () => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: this.state.email
        }, () => {
            // this.getInitialValues();
            // let weekStartDate = new Date(moment().format('MM/DD/YYYY'));
            // let weekEndDate = new Date(moment().add(7, 'days').format('MM/DD/YYYY'));

        })
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = currentDay.diff(today, 'days');
            this.setState({
                currentDayDiff
            })

            this.setState({
                availableTimes: this.getDates()
            })
        })
        //this.getBookedTimeSlots(moment());
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray();
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = () => {
        const amArray = this.getTime('AM');
        const pmArray = this.getTime('PM');
        const amPmArray = amArray.concat(pmArray);
        this.setState({
            amPmArray
        })
        return amPmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })

        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            //this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            //this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    handle24HourButton = () => {
        let { twentyFourHourClick } = this.state;
        twentyFourHourClick = !twentyFourHourClick;
        this.setState({
            twentyFourHourClick,
            //selectedTimes: []
        })

        if (twentyFourHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false
            })
        }
    }

    handle10HourButton = () => {
        let { tenHourClick } = this.state;
        tenHourClick = !tenHourClick;
        this.setState({
            tenHourClick,
            //selectedTimes: []
        })

        if (tenHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle5HourButton = () => {
        let { fiveHourClick } = this.state;
        fiveHourClick = !fiveHourClick;
        this.setState({
            fiveHourClick,
            //selectedTimes: []
        })

        if (fiveHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle3HourButton = () => {
        let { threeHourClick } = this.state;
        threeHourClick = !threeHourClick;
        this.setState({
            threeHourClick,
            //selectedTimes: []
        })

        if (threeHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle2HourButton = () => {
        let { twoHourClick, } = this.state;
        twoHourClick = !twoHourClick;
        this.setState({
            twoHourClick,
            //selectedTimes: []
        })

        if (twoHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
            //selectedTimes: []
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
            //selectedTimes: []
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        //let index = selectedWeekDays && selectedWeekDays.indexOf(selectedWeekDay);
        //let addDaysToday = moment(today).add(index, 'days');
        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        //let selectedTimesArray = this.state.selectedTimes;

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    bookDate = () => {
        let { username, password, lastname, videoLink, country, birthday, aboutYou, priceSlider, pictures,
            bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher, email, firstname, teacherEmail, selectedWeekDays } = this.state;
        bookDate = !bookDate;

        if (bookDate) {
            this.setState({
                cancelDate: false,
                dateBookingSuccessful: false,
            })
        }

        const teacherData = {
            firstname: firstname,
            lastname: lastname,
            password: password,
            rater: true,
            videoLink: videoLink,
            country: country,
            birthday: birthday,
            aboutYou: aboutYou,
            priceSlider: priceSlider,
            email: email,
            createdDate: moment()
        }

        axios.post(`${URL_PREFIX()}/datepracticals/signupTeacher`, teacherData)
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));


    }

    handleBookDateResponse = (res) => {
        let { username, password, lastname, videoLink, country, birthday, aboutYou, priceSlider, pictures,
            bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher, email, firstname, teacherEmail, selectedWeekDays } = this.state;
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
        this.props.navigate("/home", {
            state: {
                username: firstname,
                userEmail: email,
                isloginOrSignUpPage: false
            }
        });
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            // let weekStartDate = new Date(moment().format('MM/DD/YYYY'));
            // let weekEndDate = new Date(moment().add(7, 'days').format('MM/DD/YYYY'));
            this.getBookedTimeSlots(moment());
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    getAgeYears = () => {
        const { years } = this.state;
        for (let i = 1920; i < 2010; i++) {
            years.push(i);
        }
        this.setState({
            years
        })
    }

    getCountries = () => {
        let countries = countryList().getData();

        countries = countries.map((country, index) => {
            return country.value
        })

        this.setState({
            countries
        })
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    handleResponse = (res) => {
        console.log('handleResponse hit');
        //const { handleNameChange } = this.props;
        // const { data } = res;
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;
        const { navigate } = this.props;
        //handleNameChange(firstname);
        cookies.set('username', firstname);

        this.setState({
            firstname: firstname,
            password: password
        });

        cookies.set("access-token", res?.data?.authToken, {
            maxAge: 60 * 60 * 24 * 30 * 1000,
        });
        cookies.set('username', firstname);
        cookies.set('email', email);
        cookies.set('rater', res?.data?.rater);

        //save teacher available dates and times
        for (let i = 0; i < selectedWeekDays.length; i++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

                this.setState({
                    bookDate,
                    dateBookingMessage
                })

                const teacherData = {
                    teacherName: firstname,
                    teacherEmail: email,
                    createdDate: new Date(),
                    availableDate: bookedDate,
                    availableTimes: selectedTimes[i],
                    accessToken: cookies.get("access-token"),
                }

                axios.post(`${URL_PREFIX()}/datepracticals/saveTeacherAvailableDates`, teacherData)
                    .then(res => this.handleBookDateResponse(res))
                    .catch(error => console.log(error));
            }
        }

        // navigate("/navbar", {
        //     state: {
        //         username: firstname,
        //         userEmail: email,
        //         isloginOrSignUpPage: false
        //     }
        // });


    }

    handleSubmit = (event) => {
        const { navigate } = this.props;
        const { username, password, firstname, lastname, videoLink, country, birthday, aboutYou, priceSlider, email, pictures } = this.state;
        const data = {
            username: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            email: email,
            rater: true,
            videoLink: videoLink,
            country: country,
            birthday: birthday,
            aboutYou: aboutYou,
            priceSlider: priceSlider
        }

        // axios.post("`${URL_PREFIX()}/datepracticals/signupTeacher", data)
        //     .then(res => this.handleResponse(res))
        //     .catch(error => console.log(error));
        this.bookDate();

        if (pictures && pictures.length > 0) {
            this.upload(data);
        } else {
            this.handleResponse();
        }
    }

    handleAgeChange = (e) => {
        const age = e.target.value;

        this.setState({
            age
        })
    }

    handleCountrySelect = (country) => {
        this.setState({
            country
        })
    }

    handleBirthdayChange = (day) => {
        this.setState({
            birthday: day
        })
    }

    handleDisablePicturesRetrieval = (picRetrieve) => {
        this.setState({
            disablePicturesretrieval: picRetrieve
        })
    }

    handleOpenUpload = (openUpload) => {
        this.setState({
            uploadOpen: openUpload
        })
    }

    setUploadOpen = (toOpen) => {
        this.setState({
            openUpload: toOpen
        })
    }

    handlePictures = (pictures) => {
        this.setState({
            pictures: pictures
        })
    }

    upload = (data) => {
        const { pictures, email } = this.state;
        //setShowProgress(true);
        let filesUploaded = 0;
        const pictureLength = pictures.length;

        pictures.forEach((picture, index) => {
            const file = picture.data;

            // Create an object of formData
            let formData = new FormData();

            // Update the formData object hi
            formData.append('file', file);
            formData.append('email', email);

            axios.post(`${URL_PREFIX()}/datepracticals/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((res) => {
                    axios.post("`${URL_PREFIX()}/datepracticals/signupTeacher", data)
                        .then(res => this.handleResponse())
                        .catch(error => console.log(error));
                    filesUploaded += 1;
                    //deleteImage(null, index);
                    if (pictureLength === filesUploaded) {
                        //setShowProgress(false);
                        this.setState({
                            pictures: []
                        })
                        console.log(file + " uploaded successfully");
                        //setPictures([]);
                        // setTimeout(() => {
                        //     setUploadMessage(false);
                        // }, 10000)
                        //setUploadMessage(true);
                    }
                })
                .catch((error) => {
                    //console.error(error.response);
                    this.setState({ errormessage: error.response.statusText + " Please select the file" })
                    //setShowProgress(false);
                })
        });
    }

    handleBackButton = () => {
        let { screenVal } = this.state;

        if (screenVal > 0 && screenVal < 4) {
            screenVal -= 1;
        }

        this.setState({
            screenVal
        })
    }

    handleNextButton = () => {
        let { screenVal } = this.state;

        if (screenVal >= 0 && screenVal < 3) {
            screenVal += 1;
        }

        this.setState({
            screenVal
        })
    }

    handleAvailableSlotsDialog = () => {
        this.setState({
            openAvailableSlots: true,
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        })
    }

    handleCloseAvailableSlots = () => {
        this.setState({
            openAvailableSlots: false
        })
    }

    render() {
        const { years, age, country, countries, birthday, userEmail, openUpload, screenVal, email, selectedWeeklyDays, selectedWeeklyTimes, openAvailableSlots,
            review, openLogin, bioReview, open, weekArray, weekDayArrayMapping, availableTimes, fiveHourClick, threeHourClick, tenHourClick, selectedWeekDays, twentyFourHourClick,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            twoHourClick } = this.state;
        return (
            <>
                <div>
                    <Heading />

                    {/* <div style={heading}> */}
                    <Grid container spacing={0} justify="center" direction="row">
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>
                        <Grid item xs={8} md={8} lg={8} style={{
                            display: 'flex',
                            justifyContent: 'left', justifyContent: 'left',

                        }}>
                            <Grid container>
                                <Grid item xs={12} md={11} lg={10}>
                                    <Typography style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        // padding: '5px',
                                        marginTop: '0.4%',
                                        fontSize: '28px',
                                        fontWeight: 'bold'
                                    }}>Teacher sign up</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={this.isMobile ? { marginTop: '1%' } : { marginTop: '1%' }}>
                            <Grid item xs={1} sm={1} md={3} lg={4}>
                            </Grid>
                            <Grid item xs={8} md={8} lg={8} style={{
                                display: 'flex',
                                justifyContent: 'left', justifyContent: 'left',
                            }}>
                                <Grid container>
                                    <Grid item xs={12} md={11} lg={10}>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'left',

                                            marginTop: '0.4%',
                                            fontSize: '15px',
                                        }}><span>Already have an account?</span>
                                            <span style={{ marginLeft: '4px', marginRight: '4px' }}> </span>
                                            <span style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: '#0096FF' }} onClick={() => { this.props.navigate("/login") }}>Log in</span></Typography>
                                        <Typography style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'left',
                                            padding: '5px',
                                            marginTop: '0.4%',
                                            fontSize: '15px',
                                        }}></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* </div> */}

                        {/* <div style={topPane}> */}
                        {screenVal === 0 ?
                            <>


                                <Grid container style={this.isMobile ? { marginTop: '1%' } : { marginTop: '1%' }}>
                                    <Grid item xs={1} sm={1} md={3} lg={4}>
                                    </Grid>

                                    <Grid item xs={10} sm={10} md={5} lg={3}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={11} lg={10}>
                                                <InputLabel>First name</InputLabel>
                                                <TextField
                                                    type="firstname"
                                                    placeholder="First Name"
                                                    fullWidth
                                                    name="firstname"
                                                    variant="outlined"
                                                    value={this.state.firstname}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            [event.target.name]: event.target.value,
                                                        })
                                                    }
                                                    required
                                                    autoFocus
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </>
                            : null}
                        {screenVal === 0 ?
                            <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>

                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Last name</InputLabel>
                                            <TextField
                                                type="lastname"
                                                placeholder="Last Name"
                                                fullWidth
                                                name="lastname"
                                                variant="outlined"
                                                value={this.state.lastname}
                                                onChange={(event) =>
                                                    this.setState({
                                                        [event.target.name]: event.target.value,
                                                    })
                                                }
                                                required
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            : null}
                        {screenVal === 0 ?
                            <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>

                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Email</InputLabel>
                                            <TextField
                                                type="email"
                                                placeholder="Email"
                                                fullWidth
                                                name="email"
                                                variant="outlined"
                                                value={this.state.email}
                                                onChange={(event) =>
                                                    this.setState({
                                                        [event.target.name]: event.target.value,
                                                    })
                                                }
                                                required
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            : null}
                        {screenVal === 0 ?
                            <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>

                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Password</InputLabel>
                                            <TextField
                                                type="password"
                                                placeholder="Password"
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                value={this.state.password}
                                                onChange={(event) =>
                                                    this.setState({
                                                        [event.target.name]: event.target.value,
                                                    })
                                                }
                                                required
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            : null}
                        <br />
                        {/* {screenVal === 1 ?
                            <Grid container >
                                <Grid item xs={2} md={4} lg={5}>
                                </Grid>
                                <Grid item xs={8} md={8} lg={4}>
                                    <Grid container>
                                        <Grid item xs={3} md={2} lg={2} >
                                            <InputLabel id="demo-simple-select-label">Birth day:</InputLabel>
                                        </Grid>
                                        <Grid item xs={9} md={7} lg={4}>
                                            <DatePicker value={birthday} onChange={this.handleBirthdayChange} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} md={4} lg={5}>
                                </Grid>
                            </Grid>
                            : null} */}
                        {screenVal === 1 ?
                            <Grid container style={this.isMobile ? { marginTop: '1%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>
                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <ReactFlagsSelect
                                                selected={country}
                                                onSelect={this.handleCountrySelect}
                                                countries={countries}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} md={4} lg={5}>
                                </Grid>
                            </Grid>
                            : null}
                        {screenVal === 1 ?
                            <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>
                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Video link</InputLabel>
                                            <TextField
                                                type="videoLink"
                                                placeholder="Video Link"
                                                fullWidth
                                                name="videoLink"
                                                variant="outlined"
                                                value={this.state.videoLink}
                                                onChange={(event) =>
                                                    this.setState({
                                                        [event.target.name]: event.target.value,
                                                    })
                                                }
                                                required
                                                autoFocus
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                    alignItems: 'left',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            : null}
                        {screenVal === 1 ?
                            <>
                                <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                    <Grid item xs={1} sm={1} md={3} lg={4}>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={5} lg={3}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={11} lg={10}>
                                                <InputLabel>Upload image</InputLabel>
                                                <UploadImages email={email} handleDisablePicturesRetrieval={this.handleDisablePicturesRetrieval} openUpload={openUpload}
                                                    setUploadOpen={this.setUploadOpen} handlePictures={this.handlePictures} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} md={4} lg={5}>
                                    </Grid>
                                    <br />
                                </Grid>
                                <Grid container style={{ marginTop: '8px' }}>
                                    <Grid item xs={12} >
                                    </Grid>
                                </Grid>
                            </>
                            : null}
                        <br />
                        {screenVal === 2 ?
                            <Grid container style={this.isMobile ? { marginTop: '1%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>
                                <Grid item xs={10} sm={10} md={5} lg={3}>
                                    <Grid container>
                                        {/* <Grid item xs={3} md={2} lg={2} >
                                            <InputLabel id="demo-simple-select-label">Price:</InputLabel>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Price</InputLabel>
                                            <Slider defaultValue={1} min={1} max={200} aria-label="Small" marks={priceMarks}
                                                name='priceSlider' valueLabelDisplay="auto" onChange={(e) => {
                                                    this.setState({
                                                        [e.target.name]: e.target.value
                                                    })
                                                }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null}
                        <br />
                        {screenVal === 2 ?
                            <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                                <Grid item xs={1} sm={1} md={3} lg={4}>
                                </Grid>
                                <Grid item xs={10} sm={10} md={8} lg={7}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={11} lg={10}>
                                            <InputLabel>Write something about you</InputLabel>
                                            <TextField
                                                fullWidth
                                                placeholder="About you"
                                                multiline
                                                rows={7}
                                                sx={{
                                                    width: '100%',
                                                    height: '210px'
                                                }}
                                                variant="outlined"
                                                onChange={(e) => this.setState({
                                                    aboutYou: e.target.value
                                                })}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null}
                        {/* </div> */}

                        {screenVal === 3 ?
                            <Grid container style={this.isMobile ? { marginTop: '0%' } : { marginTop: '0%' }}>
                                <Grid item xs={1} sm={1} md={0} lg={0}>
                                </Grid>
                                <Grid item xs={11} sm={11} md={11} lg={11}>
                                    <Box sx={{
                                        width: "100%",
                                        height: '400px',
                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                        borderRadius: 4,
                                        marginTop: '0%',
                                        overflow: "hidden",
                                        overflowX: "scroll",
                                        overflowY: "scroll",
                                        padding: this.isMobile ? '2%' : '1%'
                                    }} >
                                        <Grid container style={this.isMobile ? { marginTop: '0%' } : { marginTop: '0%', height: 300 }}>

                                            <Grid item xs={12} md={12} lg={12} sm={12}>
                                                {/* <InputLabel id="demo-simple-select-label">Available slots:</InputLabel> */}
                                                <Grid container>
                                                    <Grid item xs={11}>
                                                        <Grid container>
                                                            <Grid item>
                                                                Book your available slots
                                                            </Grid>
                                                            {dateBookingSuccessful ?
                                                                <Grid item>
                                                                    <Alert severity="success" style={{ backgroundColor: '#A3E4D7', marginLeft: '100px' }}>
                                                                        Available slots booked successfully.
                                                                    </Alert>
                                                                </Grid>
                                                                : null}
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <Grid container>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12} lg={3} sm={12}>
                                                            <Stack direction="row" spacing={2} style={!dateBookingSuccessful ? { marginTop: '15px' } : null}>
                                                                <Button variant="outlined" onClick={this.bringPreviousWeek} disabled={currentDayDiff <= 0}>
                                                                    {"<"}
                                                                </Button>
                                                                <span style={{ marginLeft: '8px', fontWeight: 'bold', }}>
                                                                    {todayDate + " - " + weekAfterDate + ", " + weekAfterYear}
                                                                </span>
                                                                <Button variant="outlined" onClick={this.bringNextWeek} style={{ marginLeft: '8px' }}>
                                                                    {">"}
                                                                </Button>
                                                            </Stack>
                                                        </Grid>

                                                        <Grid item xs={12} md={12} lg={9} sm={12}>
                                                            <Grid container spacing={1} style={{ padding: '1%' }}>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={twentyFourHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle24HourButton}>
                                                                        {"24hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={tenHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle10HourButton}>
                                                                        {"10hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={fiveHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle5HourButton}>
                                                                        {"5 hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={threeHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle3HourButton}>
                                                                        {"3 hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={twoHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle2HourButton}>
                                                                        {"2 hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                                                        {"1 hour"}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item style={{ marginRight: '3px' }}>
                                                                    {/* <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                                                        {"30 min"}
                                                                    </Button> */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Stack direction="row" spacing={2}>
                                                    {availableTimes && availableTimes.map((i, index) => (
                                                        <>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Item>
                                                                        {weekArray[index]}
                                                                    </Item>
                                                                </Grid>
                                                                <br />
                                                                {i[weekArray[index]].map((time, ind) => (
                                                                    <>
                                                                        <span style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <Grid item xs={12}>
                                                                                <br />
                                                                                <Button variant="outlined" color='secondary' style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[index] && (selectedWeeklyTimes[index].indexOf(time) > -1
                                                                                    && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[index] && selectedWeeklyDays[index] === weekArray[index]) ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal}
                                                                                    onClick={this.handleTimeSelection} value={weekArray[index] + "-" + time + "-" + index}>
                                                                                    {time}
                                                                                </Button>
                                                                            </Grid>
                                                                        </span>
                                                                    </>
                                                                ))}
                                                            </Grid>
                                                        </>
                                                    ))
                                                    }
                                                </Stack>
                                            </Grid>
                                            {/* <Grid item xs={8} md={8} lg={6}>

                                                {!this.isMobile ?
                                                    <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{selectedWeeklyTimes && selectedWeeklyTimes.length > 0 ? `(${selectedWeeklyTimes.length} day(s) selected)` : ''} </span>
                                                    : null}
                                            </Grid>
                                            <Grid item xs={8} md={7} lg={4}>
                                                <Grid container>


                                                    {this.isMobile ?
                                                        <>
                                                            <br />
                                                            <Grid item xs={12}>
                                                                <span style={{ marginLeft: '20px', fontWeight: 'bold' }}>{selectedWeeklyTimes && selectedWeeklyTimes.length > 0 ? `(${selectedWeeklyTimes.length} day(s) selected)` : ''} </span>
                                                            </Grid>
                                                        </>
                                                        : null}
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            : null}
                    </Grid>

                    <div>
                        <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                            <Grid item xs={1} sm={1} md={3} lg={4}>
                            </Grid>
                            <Grid item xs={10} md={8} lg={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} md={3} lg={2} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            onClick={this.handleBackButton}
                                            disabled={screenVal === 0}
                                        >
                                            Back
                                        </Button >
                                    </Grid>
                                    {screenVal === 3 ?
                                        <Grid item xs={5} md={2} lg={1} sm={5}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={this.handleSubmit}
                                            // disabled={screenVal === 3}
                                            >
                                                Submit
                                            </Button >
                                        </Grid> : <Grid item xs={5} md={2} lg={1} sm={5}>

                                        </Grid>}
                                    {screenVal !== 3 ?
                                        <Grid item xs={3} md={2} lg={2} sm={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={this.handleNextButton}
                                                disabled={screenVal === 3}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'right',
                                                    alignItems: 'right',
                                                }}
                                            >
                                                Next
                                            </Button >
                                        </Grid>
                                        : <Grid item xs={3} md={2} lg={2} sm={3}>

                                        </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {
                            openAvailableSlots ?
                                <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    open={openAvailableSlots}
                                    onClose={this.handleCloseAvailableSlots}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ width: '100%' }}
                                    classes={{ paperFullWidth: { 'max-width': '80%' } }}
                                >
                                    <DialogTitle>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11}>
                                                <Grid container>
                                                    <Grid item>
                                                        Book your available slots
                                                    </Grid>
                                                    {dateBookingSuccessful ?
                                                        <Grid item>
                                                            <Alert severity="success" style={{ backgroundColor: '#A3E4D7', marginLeft: '100px' }}>
                                                                Available slots booked successfully.
                                                            </Alert>
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={this.handleCloseAvailableSlots}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} md={5} lg={5}>
                                                <Stack direction="row" spacing={2} style={!dateBookingSuccessful ? { marginTop: '15px' } : null}>
                                                    <Button variant="outlined" onClick={this.bringPreviousWeek} disabled={currentDayDiff <= 0}>
                                                        {"<"}
                                                    </Button>
                                                    <span style={{ marginLeft: '8px', fontWeight: 'bold', }}>
                                                        {todayDate + " - " + weekAfterDate + ", " + weekAfterYear}
                                                    </span>
                                                    <Button variant="outlined" onClick={this.bringNextWeek} style={{ marginLeft: '8px' }}>
                                                        {">"}
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={twentyFourHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle24HourButton}>
                                                    {"24hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={tenHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle10HourButton}>
                                                    {"10hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={fiveHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle5HourButton}>
                                                    {"5 hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={threeHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle3HourButton}>
                                                    {"3 hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={twoHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle2HourButton}>
                                                    {"2 hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                                    {"1 hour"}
                                                </Button>
                                            </Grid>
                                            <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                                {/* <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                                    {"30 min"}
                                                </Button> */}
                                            </Grid>
                                        </Grid>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Stack direction="row" spacing={2}>
                                            {availableTimes && availableTimes.map((i, index) => (
                                                <React.Fragment>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Item>
                                                                {weekArray[index]}
                                                            </Item>
                                                        </Grid>
                                                        <br />
                                                        {i[weekArray[index]].map((time, ind) => (
                                                            <>
                                                                <span style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Grid item xs={12}>
                                                                        <br />
                                                                        <Button variant="outlined" color='secondary' style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[index] && (selectedWeeklyTimes[index].indexOf(time) > -1
                                                                            && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[index] && selectedWeeklyDays[index] === weekArray[index]) ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal}
                                                                            onClick={this.handleTimeSelection} value={weekArray[index] + "-" + time + "-" + index}>
                                                                            {time}
                                                                        </Button>
                                                                    </Grid>
                                                                </span>
                                                            </>
                                                        ))}
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                            }
                                        </Stack>
                                    </DialogContent>

                                    <DialogActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={5}>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.handleCloseAvailableSlots}>Select</Button>
                                            </Grid>
                                            {/* <Grid item xs={1}>
                                                <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.bookDate}>Select</Button>
                                            </Grid>
                                            <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                                <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.cancelDate}>Cancel</Button>
                                            </Grid> */}
                                        </Grid>
                                    </DialogActions>

                                    {/* <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Calendar
                                            </Typography>

                                        </Box> */}
                                </Dialog>
                                : null
                        }
                    </div>

                </div >
            </>
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <TeacherSignUpPage {...props} navigate={navigate} />;
}
//export default withStyles(styles)(SignUp);