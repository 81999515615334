import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Heading extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: cookies.get('username'),
            isloginOrSignUpPage: false,
            environment: 'dev'
        }
    }

    componentDidMount() {
        const windowUrl = window.location.href;

        if (windowUrl && windowUrl.includes("datepracticals.com")) {
            this.setState({
                environment: 'prod'
            })
        }

        const loginPageLink = window.location.href.toString();
        const loginPageLinkArray = loginPageLink.split("//");
        const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup') ||
            loginPageLink.includes('raterSignup') || loginPageLink.includes('teacherSignup');

        this.setState({
            isloginOrSignUpPage,
            loginPageLinkArrayLength: loginPageLinkArray.length
        })

        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;
    }

    handleNameChange = (username) => {
        this.setState({
            username
        })
    }

    handleIsloginOrSignUpPage = (isloginOrSignUpPage) => {
        this.setState({
            isloginOrSignUpPage
        })
    }

    render() {
        const { username, isloginOrSignUpPage, environment, loginPageLinkArrayLength } = this.state;
        const { navigate } = this.props;

        return (
            <div style={{ marginLeft: '3%' }}>
                <Grid container spacing={1} justify="left" direction="row">
                    <Grid item xs={5} sm={4} md={6} lg={9} style={{
                        justifyContent: 'left', justifyContent: 'left',
                    }}>
                        <span style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'left',
                            padding: '0px',
                            marginLeft: '0%',
                            marginBottom: '5%',
                            fontSize: '28px',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                this.props.navigate("/home");
                            }}
                        >Tidy Belle
                        </span>
                    </Grid>
                    {/* {!this.isMobile ? */}
                    <Grid item xs={5} sm={2} md={2} lg={1} style={{
                        justifyContent: 'left', justifyContent: 'left',
                    }}>
                        <Button
                            color={'primary'}
                            variant='contained'
                            onClick={() => {
                                this.props.navigate("/login");
                            }}
                        >Log in
                        </Button>
                    </Grid>
                    {/* : null} */}
                    {!this.isMobile ?
                        <Grid item xs={2} sm={4} md={3} lg={2} style={{
                            justifyContent: 'right', justifyContent: 'right',
                        }}>
                            <Button
                                color={'primary'}
                                variant='contained'
                                onClick={() => {
                                    this.props.navigate("/dates");
                                }}
                            >Book instructors
                            </Button>
                        </Grid>
                        : null}




                </Grid>
            </div>
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <Heading {...props} location={location} navigate={navigate} />;
}
