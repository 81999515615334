import React from "react";
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import axios from 'axios';
//import cookie from 'react-cookies'
import Cookies from 'universal-cookie';
import cookie from 'react-cookie';
import { useCookies } from 'react-cookie';
import { withCookies } from 'react-cookie';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Heading from '../heading/Heading';

//const cookies = new Cookies();

const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const signupButton = {
    borderRadius: 35,
    backgroundColor: "white",
    color: 'blue',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginLeft: '15%',
    marginTop: '6%'
}

const teacherSignupButton = {
    borderRadius: 35,
    backgroundColor: "white",
    color: 'blue',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5%',
    marginTop: '6%'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const cookies = new Cookies();

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { username: "", password: "", authflag: 1 };
    }

    componentDidMount() {
        const { handleIsloginOrSignUpPage } = this.props;

        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        if (handleIsloginOrSignUpPage) {
            handleIsloginOrSignUpPage(isloginOrSignUpPage);
        }
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    handleResponse = (res) => {
        console.log('handleResponse hit');
        //const { cookies } = this.props;
        const { handleNameChange, env } = this.props;
        const { data } = res;
        const { firstname, email, rater } = data;
        const { navigate } = this.props;

        if (handleNameChange) {
            handleNameChange(firstname);
        }
        //cookies.set(=('cookie', data?.authToken);

        cookies.set("access-token", data?.authToken, {
            maxAge: 60 * 60 * 24 * 30 * 1000,
        });

        //console.log('cookie value', cookies.get("access-token"));

        // cookie.save(res.headers['access-token']);
        // cookie.set(res.headers['access-token']);
        cookies.set('username', firstname);
        cookies.set('email', email);
        cookies.set('rater', rater);

        if (data?.role === 'admin') {
            cookies.set('admin', true);
        } else {
            cookies.set('admin', false);
        }

        // this.setState({
        //     firstname: firstname,
        //     password: password
        // });

        // navigate("/home", {
        //     state: {
        //         username: firstname,
        //         email: email,
        //         isloginOrSignUpPage: false
        //     }
        // });
        navigate("/home", {
            state: {
                username: firstname,
                userEmail: email,
                isloginOrSignUpPage: false,
                env: env
            }
        });
        // if (!rater) {
        //     navigate("/home", {
        //         state: {
        //             username: firstname,
        //             userEmail: email,
        //             isloginOrSignUpPage: false
        //         }
        //     });
        // } else {
        //     navigate("/onlineDates", {
        //         state: {
        //             username: firstname,
        //             userEmail: email,
        //             rater: rater,
        //             isloginOrSignUpPage: false
        //         }
        //     });
        // }

    }

    handleSubmit = (event) => {
        const { navigate } = this.props;
        const { username, password } = this.state;
        const data = {
            // firstname: '',
            // lastname: '',
            // email: username,
            // password: password,
            // rater: false,
            // date: new Date()

            //username: username,
            password: password,
            firstname: '',
            lastname: '',
            email: username,
            rater: false,
            //accessToken: cookies.get("access-token"),
            // username: username,
            // password: password,
            // firstname: '',
            // lastname: '',
            // email: '',
            // rater: false,
            // date: new Date()
        }

        axios.post(`${URL_PREFIX()}/datepracticals/login`, data
        )
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleNameChange = (event) => {
        const name = event.target.value;
        this.setState({
            [event.target.name]: name
        })
    }

    handleSignup = () => {
        const { navigate } = this.props;
        navigate("/signup", {
            // state: {
            //     username: firstname,
            //     email: email,
            //     isloginOrSignUpPage: false
            // }
        });
    }

    handleTeacherSignup = () => {
        const { navigate } = this.props;
        navigate("/teacherSignup", {
            // state: {
            //     username: firstname,
            //     email: email,
            //     isloginOrSignUpPage: false
            // }
        });
    }

    render() {
        const { navigate } = this.props;
        return (
            <div>
                <Heading />
                <Grid container spacing={0} justify="center" direction="row">
                    <Grid container>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <Typography style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginTop: '0.4%',
                                        fontSize: '28px',
                                        fontWeight: 'bold'
                                    }}>Log in</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <Typography style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginTop: '0.4%',
                                        fontSize: '15px',
                                    }}><span onClick={() => { navigate("/signup") }} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0096FF' }}>Sign up</span>
                                        <span style={{ marginLeft: '10px', marginRight: '10px' }}> Or </span>
                                        <span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0096FF' }} onClick={() => { navigate("/teacherSignup") }}>Sign up as an Instructor</span>
                                    </Typography>
                                    <Typography style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        padding: '5px',
                                        marginTop: '0.4%',
                                        fontSize: '15px',
                                    }}></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '1%' }}>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>

                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <InputLabel>Email</InputLabel>
                                    <TextField
                                        type="email"
                                        placeholder="Email"
                                        fullWidth
                                        name="username"
                                        variant="outlined"
                                        value={this.state.username}
                                        onChange={(event) =>
                                            this.setState({
                                                [event.target.name]: event.target.value,
                                            })
                                        }
                                        required
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>

                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <InputLabel>Password</InputLabel>
                                    <TextField
                                        type="password"
                                        placeholder="Password"
                                        fullWidth
                                        name="password"
                                        variant="outlined"
                                        value={this.state.password}
                                        onChange={(event) =>
                                            this.setState({
                                                [event.target.name]: event.target.value,
                                            })
                                        }
                                        required
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={this.isMobile ? { marginTop: '3%' } : { marginTop: '1%' }}>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <Button variant="contained" disableElevation style={{ width: '100%', height: '50px' }} onClick={this.handleSubmit}>
                                        Log in
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} md={4} lg={5}>
                        </Grid>
                    </Grid>
                    {/* <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs={1} sm={1} md={3} lg={4}>
                        </Grid>

                        <Grid item xs={10} sm={10} md={5} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={11} lg={10}>
                                    <Button variant="contained"
                                        color="#42B2DF"
                                        type="submit"
                                        onClick={this.handleSubmit} style={{
                                            width: '31%',
                                            color: 'white',
                                            backgroundColor: '#FF00FF',
                                            marginTop: '1%',
                                        }}>Log in</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div >
        );
    }
}

export default function (props) {
    const navigate = useNavigate();
    const cookies = useCookies();

    return <Login {...props} navigate={navigate} cookies={cookies} />;
}
//export default withStyles(styles)(Login);