import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";
import io from 'socket.io-client';

import Chat from '../chat/Chat';


import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import LoginPageWithoutHeading from '../login/LoginPageWithoutHeading';
import calendar from '../dialog/calendar';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { hi } from 'date-fns/locale';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// import Chat from '../../Chat';
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';


const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

const socket = io.connect(`${URL_PREFIX()}`, { transports: ['websocket', 'polling', 'flashsocket'] });

class Messages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            review: {
                imageTitle: '',
                email: '',
                userEmail: '',
                isProfilePic: false,
                attractivenessSliderVal: 5,
                confidenceSliderVal: 5,
                charmingSliderVal: 5,
                responsibleSliderVal: 5,
                funSliderVal: 5,
                intelligentSliderVal: 5,
                willingToDateSliderVal: 5,
                picOrderSliderVal: 0,
                needtoLookAtCamera: false,
                toSmile: false,
                toDelete: false,
                tooMuchFiltering: false,
                needToLookAtCamera: false,
                isLightingBad: false,
                isBadSelfie: false,
                toRecommendOthers: false,
                phoneNoOrEmail: '',
                suggestions: '',
                pictureEffectivenessSliderVal: 0,
                looksTooYoung: false,
                getHairCut: false,
                trimYourBeard: false,
                niceGuyVibe: false,
                reduceWeight: false
            },
            bioReview: {
                imageTitle: '',
                email: '',
                userEmail: '',
                promptEffectivenessSliderVal: 0,
                showsPersonality: false,
                isShortBio: false,
                wantToDate: false,
                notReleatedAnswer: false,
                deleteThisPrompt: false,
                gramatticalMistakes: false,
                difficultToUnderstand: false,
                boringAnswer: false,
                tooLongAnswer: false,
                deletePolitics: false,
                deleteReligion: false,
                whatYouAreLookingFor: false,
                hobbiesAndInterests: false,
                racistVibe: false,
                goldDiggerVibe: false,
                looksTooOld: false,
                givingTooMuchInfo: false,
                workOnYourself: false,
                mentalHealthIssues: false,
                bioSuggestions: '',

            },
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showChat: false,
            room: '',
            username: '',
            showModelChat: false
        }
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        //this.props.setDefaultIndex(true);
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        this.setState({
            username: cookies.get('username'),
            email: cookies.get('email'),
            isRater: cookies.get('rater'),
            showChat: false,
            showModelChat: false
        }, () => {
            if (this.state.isRater) {
                this.getOnlineDateMessagesT();
            } else {
                this.getOnlineDateMessages();
            }
        })
    }

    getImageList = (emailToRetrieve) => {
        console.log('retrieving images list');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/pictures`, data)
            .then(res => this.handleImageResponse(res))
            .catch(error => console.log(error));
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value
        })
    }
    getOnlineDateMessages = () => {
        console.log('retrieving images list');
        const data = {
            userEmail: this.state.email,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDateMessages`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    getOnlineDateMessagesT = () => {
        console.log('retrieving getOnlineDateMessagesT list');
        const data = {
            teacherEmail: this.state.email,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDateMessagesForTeacher`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    getOnlineDateMessagesForTeacher = () => {
        console.log('retrieving images list');
        const data = {
            teacherEmail: this.state.email,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDateMessagesForTeacher`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    getOnlineDatesToTeach = () => {
        console.log('retrieving images list');
        const data = {
            teacherEmail: this.props?.location?.state?.userEmail,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDatesToTeach`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    handleOnlineDatesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((data) => {
            uniqueRaters.push(data);
        })

        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(this.state.isRater ? rater?.userEmail : rater?.teacherEmail, index)
            ));
            if (this.isDesktop || this.isLaptop) {
                this.goToChatRoom(uniqueRaters[0].room, uniqueRaters[0].userName, uniqueRaters[0].teacherName, uniqueRaters[0].userEmail, uniqueRaters[0].teacherEmail, 0, false, true);
            }
        });
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }


    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveImage`, data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }

    navigateToRoom = (index) => {
        const { navigate } = this.props;
        const { ratersArray } = this.state;
        const roomId = ratersArray ? ratersArray[index]?.roomId : null;
        navigate(`/room/${roomId}`, { state: { 'roomId': roomId } });
    }

    goToChatRoom = (roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages) => {
        const data = {
            userEmail: userEmail,
            teacherEmail: teacherEmail,
            accessToken: cookies.get("access-token"),
        }

        if (this.isMobile || this.isSmallerScreen) {
            fromTheBox = true;
        } else {
            fromTheMessages = true;
        }

        axios.post(`${URL_PREFIX()}/datepracticals/getMessages`, data)
            .then(res => this.handleresponseForMessages(roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages, res))
            .catch(error => console.log('error', error));
    }

    handleresponseForMessages = (roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox, fromTheMessages, res) => {
        socket.emit("join_message_room", roomId);
        this.setState({
            showChat: fromTheMessages,
            showModelChat: fromTheBox,
            room: roomId,
            username: userName,
            teacherName: teacherName,
            userEmail: userEmail,
            teacherEmail: teacherEmail,
            sentBy: cookies.get('email'),
            raterIndex: index,
            messageList: res.data
        })
    }

    handleRetrieveMessagesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);

    }

    handleChatClose = () => {
        this.setState({
            showModelChat: false
        })
    }

    setMessageList = (list) => {
        this.setState({
            messageList: list
        })
    }

    render() {
        const { email, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, rater, showChat, room, username, teacherName, userEmail, teacherEmail, sentBy, messagesList, showModelChat, raterIndex, messageList
        } = this.state;
        const { isProfilePic, attractivenessSliderVal, confidenceSliderVal, charmingSliderVal, responsibleSliderVal,
            funSliderVal, intelligentSliderVal, willingToDateSliderVal, picOrderSliderVal, needtoLookAtCamera, toSmile, toDelete,
            tooMuchFiltering, isLightingBad, isBadSelfie, phoneNoOrEmail, suggestions, pictureEffectivenessSliderVal, looksTooYoung, getHairCut, trimYourBeard, niceGuyVibe, reduceWeight } = review;

        const { promptEffectivenessSliderVal, showsPersonality, isShortBio, wantToDate, notReleatedAnswer, deleteThisPrompt, replaceThisPrompt, gramatticalMistakes,
            difficultToUnderstand, boringAnswer, tooLongAnswer, deletePolitics, deleteReligion, whatYouAreLookingFor, hobbiesAndInterests, racistVibe,
            goldDiggerVibe, looksTooOld, givingTooMuchInfo, workOnYourself, mentalHealthIssues, bioSuggestions,
            buildMuscles, museumsAndEvents, goToYogaClasses, goOut, goToFitnessGroupClasses, getACoach, goToBars, joinsalsaClasses,
            dontTalk, askQuestions, lightlyFlirt, workOnYourConfidence, goToLibraries, askPhoneNo, showFunSide, beAuthentic, approachMoreWomen, getAJob,
            workOnCareer, loveYourself, getATherapist, getMoreFriends, helpPeople, beVulnerable, connectWithPeople, celebrateYourLife, workOnSpiritualSide,
            workOnSelfEsteem, practiceMeditation
        } = bioReview;

        return (
            <div  >
                {<React.Fragment>
                    <Grid container>
                        <Grid item xs={12} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Messages
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {ratersArray && ratersArray.length > 0 && ratersArray.map((rater, index) => (
                                        <React.Fragment>
                                            <Box sx={{
                                                width: '100%',
                                                height: 80,
                                                padding: "14px 14px",
                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                borderRadius: 4,
                                                marginTop: '2%',
                                                cursor: 'pointer'
                                            }} onClick={() => this.goToChatRoom(rater.room, rater.userName, rater.teacherName, rater.userEmail, rater.teacherEmail, index, false, true)}>
                                                <Grid container>
                                                    <Grid item xs={1} >
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={this.retrieveImageUrl(index)} width="30px" height="40px" sx={{
                                                                width: 30,
                                                                height: 20,
                                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                borderRadius: 4,
                                                            }}></img>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Grid container style={{ marginLeft: '10px' }}>
                                                            <Grid item xs={1} >
                                                                <span style={{ textTransform: 'capitalize' }}>{this.state.isRater ? rater?.userName : rater?.teacherName}</span>
                                                                {/* {this.state.rater ? rater?.userName : rater?.teacherName} */}
                                                            </Grid>
                                                            <Grid item xs={10}></Grid>
                                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'left', textAlign: 'left' }}>
                                                                {'see the messages'}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </React.Fragment>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.isDesktop || this.isLaptop ?
                            showChat ?
                                <>
                                    < Grid item xs={0} sm={0} md={1} lg={1}>

                                    </Grid>
                                    <Grid item xs={0} sm={0} md={7} lg={7}>
                                        <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy} directMessage={true} messageList={messageList}
                                            setMessageList={this.setMessageList}
                                        />
                                    </Grid>
                                </>
                                : null
                            :
                            showModelChat ?
                                <Dialog
                                    fullWidth
                                    maxWidth="lg"
                                    open={showModelChat}
                                    onClose={this.handleChatClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ width: '100%' }}
                                    classes={{ paperFullWidth: { 'max-width': '100%' } }}
                                >
                                    <DialogTitle>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={3} >
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={this.retrieveImageUrl(raterIndex)} width="100%" height="40px" sx={{
                                                                width: 30,
                                                                height: 20,
                                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                borderRadius: 4,
                                                            }}></img>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {this.state.isRater ? username : teacherName}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton onClick={this.handleChatClose}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy} messageList={messageList} setMessageList={this.setMessageList}
                                        />
                                    </DialogTitle>
                                    {/* <DialogContent>
                                    <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy}
                                    />
                                </DialogContent> */}
                                    <DialogActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={5}>
                                            </Grid>
                                            <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                                <Button variant="outlined" onClick={this.handleChatClose}>Cancel</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                                : null
                        }
                    </Grid>
                </React.Fragment >
                }
            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <Messages {...props} location={location} navigate={navigate} />;
}
