import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import axios from 'axios';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function Chat(props) {
  const socket = props.socket;
  const username = props.username;
  const room = props.room;
  const teacherName = props.teacherName;
  const userEmail = props.userEmail;
  const teacherEmail = props.teacherEmail;
  const sentBy = props.sentBy;
  const directMessage = props.directMessage;
  const messagesList = props.messageList;

  const [currentMessage, setCurrentMessage] = useState("");
  //const [messageList, setMessageList] = useState(props.messagesList);

  const sendMessage = async () => {
    if (currentMessage !== "") {
      const messageData = {
        room: room,
        username: username,
        message: currentMessage,
        teacherName: teacherName,
        userEmail: userEmail,
        teacherEmail: teacherEmail,
        sentBy: sentBy,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
        accessToken: cookies.get("access-token"),
        directMessage: directMessage
      };

      await socket.emit("send_message", messageData);

      axios.post(`${URL_PREFIX()}/datepracticals/saveMessage`, messageData)
        .then(res => console.log('success'))
        .catch(error => console.log(error));

      props.setMessageList([...props.messageList, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    // const data = {
    //   userEmail: userEmail,
    //   teacherEmail: teacherEmail,
    //   accessToken: cookies.get("access-token"),
    // }
    // axios.post(`${URL_PREFIX()}/datepracticals/getMessages`, data)
    //   .then(res => { setMessageList(res.data) })
    //   .catch(error => console.log('error', error));

    socket.on("receive_message", (data) => {
      props.setMessageList([...props.messageList, data]);
    });
  }, [socket]);

  // handleSaveMessageResponse = (res) => {
  //   this.setState({
  //     saveMessage: true,
  //   })
  // }

  return (
    <div className="chat-window">
      <div className="chat-header">
        <p>Messages</p>
      </div>
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {props.messageList && props.messageList.length > 0 && props.messageList.map((messageContent) => {
            return (
              messageContent !== null && messageContent.message && messageContent.message.length > 0 ?
                <>
                  <div
                    className="message"
                    id={username === (messageContent.sentBy === userEmail ? username : teacherName) ? "you" : "other"}
                  >
                    <div>
                      <div className="message-content">
                        <p>{messageContent.message}</p>
                      </div>
                      <div className="message-meta">
                        <p id="time">{messageContent.time}</p>
                        <p id="author">{messageContent.sentBy === userEmail ? username : teacherName}</p>
                      </div>
                    </div>
                  </div>
                </>
                : null

            );
          })}
        </ScrollToBottom>
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={currentMessage}
          placeholder="Hey..."
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />
        <button
          type="text"
          value={currentMessage}
          placeholder="Enter"
          onClick={sendMessage}

        ><span style={{ marginBottom: '160px' }}>Enter</span></button>
        {/* <button onClick={sendMessage}><span style={{ marginBottom: '60px' }}>Enter</span></button> */}
      </div>
    </div>
  );
}

export default Chat;
