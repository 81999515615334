import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Cookies from 'universal-cookie';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Button

} from "@material-ui/core";


import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

const cookies = new Cookies();




export default class AdminDues extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.columns = [
            // { field: 'id', headerName: 'ID', width: 90 },
            {
                field: 'orderId',
                headerName: 'Order Id',
                width: 80,
                editable: true,
            },
            {
                field: 'userName',
                headerName: 'User Name',
                width: 100,
                editable: true,
            },
            {
                field: 'userEmail',
                headerName: 'User Email',
                width: 150,
                editable: true,
            },
            {
                field: 'reminderDateTime',
                headerName: 'Booked Date',
                type: 'number',
                width: 200,
                editable: true,
            },
            {
                field: 'paidToTeacher',
                headerName: 'Paid',
                width: 70,
                valueGetter: (value, row) => `${row.paidToTeacher ? 'Yes' : 'No'}`,
            },
            {
                field: 'price',
                headerName: 'Price',
                width: 60,
                valueGetter: (value, row) => `$${row.price}`,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Paid',
                flex: 0.2,
                getActions: (params) => [
                    <Button onClick={() => this.payDue(params.row._id, params.row.orderId)}>
                        Pay
                    </Button>,
                ],
            },
        ];
        this.getDues();
    }

    payDue = (id, orderId) => {
        const data = {
            orderId: orderId,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/payDue`, data)
            .then(res => this.getDues)
            .catch(error => console.log(error));
    }

    getDues = () => {
        console.log('retrieving earnings list');
        const data = {
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getDues`, data)
            .then(res => this.handleDuesResponse(res))
            .catch(error => console.log(error));
    }

    handleDuesResponse = (res) => {
        
        this.setState({
            rows: res.data
        })
    }

    render() {
        return (
            <div>
                {this.state?.rows ?
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={this.props.showEarningsForAdminsDialog}
                        onClose={() => this.props.setShowEarningsForAdminsDialog(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ width: '100%' }}
                        classes={{ paperFullWidth: { 'max-width': '80%' } }}
                    >
                        <DialogTitle>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item>
                                            Dues
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => this.props.setShowEarningsForAdminsDialog(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <>
                                <Box sx={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10]}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        getRowId={(row) => row._id}
                                    />
                                </Box>
                            </>
                        </DialogContent>
                    </Dialog>
                    : null}
            </div>
        );
    }
}