import React, { Component, useRef, useState } from 'react';
import axios from 'axios';
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,

} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CircularProgressPercentage from '../spinner/CircularProgressPercentage';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2
    },
    customBadge: {
        backgroundColor: "#D3D3D3",
        color: "black"
    }
});

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

function UploadImages(props) {
    const fileRef = useRef();
    const buttonRef = useRef();
    const tempArray = [];
    const [pictures, setPictures] = useState([{
        data: [],
        url: ''
    }]);
    const [showProgress, setShowProgress] = useState(false);
    const [uploadMessage, setUploadMessage] = useState(false);
    //const [uploadOpen, setUploadOpen] = useState(false);
    const [uploadSelected, setUploadSelected] = useState(false);
    const { email, classes } = props;
    // const { handleIsloginOrSignUpPage } = props;
    // handleIsloginOrSignUpPage(isloginOrSignUpPage);

    const uploadFile = (fileUpload) => {
        props.handleDisablePicturesRetrieval(true);
        setUploadMessage(false);
        const files = [...fileUpload.target.files];

        files.forEach(file => {
            tempArray.push({
                data: file,
                url: URL.createObjectURL(file)
            });
        });

        setPictures(tempArray);
        props.setUploadOpen(true);
    }

    const handleUploadClose = () => {
        setPictures([]);
        props.setUploadOpen(false);
    }

    function upload() {
        setUploadSelected(true);
        const { handlePictures } = props;
        handlePictures(pictures);
        props.setUploadOpen(false);
        // setShowProgress(true);
        // let filesUploaded = 0;
        // const pictureLength = pictures.length;

        // pictures.forEach((picture, index) => {
        //     const file = picture.data;

        //     // Create an object of formData
        //     let formData = new FormData();

        //     // Update the formData object
        //     formData.append('file', file);
        //     formData.append('email', email);

        //     const data = {
        //         formData: formData,
        //         email: email
        //     };


        //     axios.post("`${URL_PREFIX()}/datepracticals/upload", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        //         .then((res) => {
        //             filesUploaded += 1;
        //             //deleteImage(null, index);
        //             if (pictureLength === filesUploaded) {
        //                 setShowProgress(false);
        //                 setPictures([]);
        //                 // setTimeout(() => {
        //                 //     setUploadMessage(false);
        //                 // }, 10000)
        //                 setUploadMessage(true);
        //             }
        //         })
        //         .catch((error) => {
        //             //console.error(error.response);
        //             this.setState({ errormessage: error.response.statusText + " Please select the file" })
        //             setShowProgress(false);
        //         })
        // });
    }

    function deleteImage(e, index) {
        pictures.splice(index, 1);
        const newPictures = [...pictures];
        setPictures(newPictures);
    }

    return (
        <div>
            <Grid container>
                {/* <Grid item xs={12}>
                    <Typography style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                        // marginLeft: '30%',
                        marginTop: '0.5%',
                        fontSize: '23px',
                    }}>Please upload your dating profile photos</Typography>
                </Grid> */}

                {/* <CustomizedBadges /> */}
                <Grid item xs={7} md={5} lg={5}>
                    <input
                        ref={fileRef}
                        onChange={uploadFile}
                        type="file"
                        style={{ display: "none" }
                        }
                        multiple={true}
                        width='50px'
                    />
                    <button style={{
                        textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: '150px', height: '40px', fontSize: '18px', backgroundColor: 'white', cursor: 'pointer',
                        borderRadius: 50, borderWidth: 2, backgroundColor: '#52BE80', color: 'white', borderColor: 'blue',
                        boxShadow: 'none',
                        textTransform: 'none',
                        fontSize: 16,
                        border: '1px solid',
                        lineHeight: 1.5,
                        borderRadius: 50,
                        borderWidth: 2,
                        // backgroundColor: '#0063cc',
                        borderColor: '#0063cc',
                    }} onClick={() => (!pictures || pictures && pictures.length === 0) || (pictures.length > 0 && pictures[0].url === '') ? fileRef?.current?.click() : buttonRef?.current?.click()}>
                        {pictures.length === 0 ? <Typography>upload images</Typography> : (pictures && pictures.length > 0 && pictures[0].url === '') ?
                            <Typography>upload images</Typography> : <Typography>upload images</Typography>}</button>
                </Grid>
                <Grid item xs={5} md={5} lg={5} style={pictures.length !== 0 ? { marginTop: '7px' } : null}>

                </Grid>
            </Grid>
            <Grid item xs={5} md={5} lg={5} style={pictures.length !== 0 ? { marginTop: '7px' } : null}>
                <Typography>{uploadSelected ? <span style={{}}>({pictures.length} pics selected)</span> : null}</Typography>
            </Grid>
            {props.openUpload ?
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={props.openUpload}
                    onClose={handleUploadClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ width: '100%' }}
                    classes={{ paperFullWidth: { 'max-width': '50%' } }}
                >
                    <DialogTitle>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <Grid container>
                                    <Grid item>
                                        Pictures
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={handleUploadClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                    </DialogTitle>
                    <DialogContent>
                        <React.Fragment>
                            <div>
                                <br />
                                {uploadMessage &&
                                    <Alert severity="success">Pictures uploaded successfully. Please continue with your profile</Alert>
                                }
                                {pictures && pictures.length > 0 && pictures[0].url !== '' && pictures.map((pic, index) => (
                                    <span style={{ margin: "15px" }}>
                                        <Badge badgeContent={<span style={{ cursor: 'pointer', color: 'black' }} onClick={(e) => deleteImage(e, index)}>X</span>}
                                            classes={{ badge: classes.customBadge }}
                                            className={classes.margin}>
                                            <img src={pic.url} alt='image' width="280" height="280" />
                                        </Badge>
                                    </span>
                                ))}
                            </div>
                            {/* 
                            {
                                showProgress &&
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={showProgress}
                                //onClick={handleClose}
                                >
                                    <CircularProgress />
                                </Backdrop>
                            } */}
                        </React.Fragment>

                    </DialogContent>

                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    pictures && pictures.length > 0 && pictures[0].url !== '' ?
                                        <Grid container>
                                            <Grid item xs={12} style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                                <button
                                                    ref={buttonRef}
                                                    onChange={upload}
                                                    style={{
                                                        textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: '250px', height: '40px', fontSize: '18px', backgroundColor: 'white', cursor: 'pointer',
                                                        borderRadius: 50, borderWidth: 2, backgroundColor: '#52BE80', color: 'white', borderColor: 'blue',
                                                        boxShadow: 'none',
                                                        textTransform: 'none',
                                                        fontSize: 16,
                                                        padding: '6px 12px',
                                                        // marginLeft: '25%',
                                                        border: '1px solid',
                                                        lineHeight: 1.5,
                                                        borderRadius: 50,
                                                        borderWidth: 2,
                                                        // backgroundColor: '#0063cc',
                                                        borderColor: '#0063cc',
                                                        fontFamily: [
                                                            '-apple-system',
                                                            'BlinkMacSystemFont',
                                                            '"Segoe UI"',
                                                            'Roboto',
                                                            '"Helvetica Neue"',
                                                            'Arial',
                                                            'sans-serif',
                                                            '"Apple Color Emoji"',
                                                            '"Segoe UI Emoji"',
                                                            '"Segoe UI Symbol"',
                                                        ].join(','),
                                                        '&:hover': {
                                                            backgroundColor: '#0069d9',
                                                            borderColor: '#0062cc',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:active': {
                                                            boxShadow: 'none',
                                                            backgroundColor: '#0062cc',
                                                            borderColor: '#005cbf',
                                                        },
                                                        '&:focus': {
                                                            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                                                        },
                                                    }} onClick={upload}>select</button>
                                            </Grid>
                                        </Grid> : null
                                }
                            </Grid>
                            {/* <Grid item xs={1}>
                                            <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.postReview}>Post</Button>
                                        </Grid> */}
                            {/* <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                <Button variant="outlined" style={oneHourOr30MinTimeSelectStyleEnable} onClick={handleUploadClose}>Close</Button>
                            </Grid> */}
                        </Grid>
                    </DialogActions>

                    {/* <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Calendar
                                            </Typography>

                                        </Box> */}
                </Dialog>
                : null}

            {/* <CircularProgressPercentage showProgress={showProgress} /> */}



            <br />
            <br />
        </div >
    )
}

export default withStyles(styles)(UploadImages);

