import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

import OnlineDates from '../onlineDates/OnlineDates';
import AvailableSlots from '../availableSlots/AvailableSlots';


//import Cookies from 'universal-cookie';

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import * as Chance from 'chance';

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
// import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import io from 'socket.io-client';

import Chat from '../chat/Chat';

const cookies = new Cookies();
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';

const socket = io.connect(`${URL_PREFIX()}`, { transports: ['websocket', 'polling', 'flashsocket'] });
//const cookies = new Cookies();

const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

const chance = new Chance();

class SubscriptionPlans extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            review: {
                imageTitle: '',
                email: '',
                userEmail: '',
                isProfilePic: false,
                attractivenessSliderVal: 5,
                confidenceSliderVal: 5,
                charmingSliderVal: 5,
                responsibleSliderVal: 5,
                funSliderVal: 5,
                intelligentSliderVal: 5,
                willingToDateSliderVal: 5,
                picOrderSliderVal: 0,
                needtoLookAtCamera: false,
                toSmile: false,
                toDelete: false,
                tooMuchFiltering: false,
                needToLookAtCamera: false,
                isLightingBad: false,
                isBadSelfie: false,
                toRecommendOthers: false,
                phoneNoOrEmail: '',
                suggestions: '',
                pictureEffectivenessSliderVal: 0,
                looksTooYoung: false,
                getHairCut: false,
                trimYourBeard: false,
                niceGuyVibe: false,
                reduceWeight: false
            },
            bioReview: {
                imageTitle: '',
                email: '',
                userEmail: '',
                promptEffectivenessSliderVal: 0,
                showsPersonality: false,
                isShortBio: false,
                wantToDate: false,
                notReleatedAnswer: false,
                deleteThisPrompt: false,
                gramatticalMistakes: false,
                difficultToUnderstand: false,
                boringAnswer: false,
                tooLongAnswer: false,
                deletePolitics: false,
                deleteReligion: false,
                whatYouAreLookingFor: false,
                hobbiesAndInterests: false,
                racistVibe: false,
                goldDiggerVibe: false,
                looksTooOld: false,
                givingTooMuchInfo: false,
                workOnYourself: false,
                mentalHealthIssues: false,
                bioSuggestions: '',

            },
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            selectedWeekDays: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: [],
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showInstructorsProfile: false
        }
        this.weeksArray = [1, 2, 3, 4];
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        //var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // if (this.videoRef.current) {
        //     this.videoRef.current.srcObject = stream;
        // }
        //this.props.setDefaultIndex(true);
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        const { state } = this.props.location;
        const { handleIsloginOrSignUpPage, navigate } = this.props;

        if (state) {
            if (state && state.username) {
                const { username, email, env } = state;
                this.setState({
                    username,
                    email,
                    env
                }, () => {
                    //this.retrieveImages(email);
                    //this.getImageList(email);
                })
                console.log(`username is ${username}`);
                console.log(`username is ${email}`);
            } else {
                this.setState({
                    username: cookies.get("username"),
                    email: cookies.get("email"),
                })
            }
            //handleIsloginOrSignUpPage(cookies.get("username") !== null);
        } else {
            this.setState({
                openLogin: true
            })
            //navigate("/login");
        }
        // this.getTeachers();
        // this.getOnlineDates();
        //this.getInitialValues();
    }

    getOnlineDates = () => {
        console.log('retrieving images list');
        const data = {
            userEmail: this.props?.location?.state?.userEmail,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDates`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    handleOnlineDatesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((data) => {
            uniqueRaters.push(data);
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            datesArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveDateFirstImage(rater.teacherEmail, index)
            ));
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    getInitialValues = () => {
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        })

        const currentDay = moment();
        const currentDayDiff = currentDay.diff(today, 'days');
        this.setState({
            currentDayDiff
        })
    }

    getImageList = (emailToRetrieve) => {
        console.log('retrieving images list');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/pictures`, data)
            .then(res => this.handleImageResponse(res))
            .catch(error => console.log(error));
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value
        })
    }

    getRaters = () => {
        console.log('retrieving images list');
        const data = {
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getRaters`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    getTeachers = () => {
        console.log('retrieving images list');
        const data = {
            //accessToken: cookies.get("access-token"),
            accessToken: cookies.get("access-token"),
            //email: emailToRetrieve
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getTeachers`, data)
            .then(res => this.handleRatersResponse(res))
            .catch(error => console.log(error));
    }

    handleRatersResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((rater) => {
            if (uniqueratersEmail.indexOf(rater.email) === -1) {
                uniqueratersEmail.push(rater.email);
                uniqueRaters.push(rater)
            }
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(rater.email, index)
            ));
        });
        //this.getImageList(email);

        // this.setState({
        //     ratersArray: res.data[0]
        // })

        // const mail = imageList?.[0]?.email;
        // const imageTitle = imageList?.[0]?.title;


        // this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveDateFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleDateFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleDateFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'dateimageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }

    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveImage`, data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveReview`, data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    retrieveBioReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveBioReview`, data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleNextButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber < imageList.length - 1) {
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: false
                });
            }
            imageNumber += 1;
            this.setState({
                imageNumber
            });

            if (imageNumber === imageList.length - 1) {
                this.setState({
                    removeNextButton: true
                });
            }
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeNextButton: true
            });
        }
    };

    handleBackButton = () => {
        let { imageNumber, email, imageList } = this.state;
        if (imageNumber >= 0) {
            imageNumber -= 1;
            if (imageNumber === 0) {
                this.setState({
                    removeBackButton: true
                });
            }
            if (imageNumber < imageList.length - 1) {
                this.setState({
                    removeNextButton: false
                });
            }
            this.setState({
                imageNumber
            });
            this.retrieveImage(email, imageNumber);
        } else {
            this.setState({
                removeBackButton: true
            });
        }
    };

    handleDisablePicturesRetrieval = (picRetrieve) => {
        this.setState({
            disablePicturesretrieval: picRetrieve
        })
    }

    handleChange = (key, event) => {
        this.setState({
            [key]: event.target.value
        });
    };

    handleReviewChange = (key, event) => {
        const review = { ...this.state.review };
        review[key] = event.target.value;

        this.setState({
            review
        });
    };

    handleBioReviewChange = (key, event) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = event.target.value;

        this.setState({
            bioReview
        });
    };

    valueText = (value) => value;

    changeSliderVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewPhoneNoChange = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    handleReviewSubmitButton = () => {
        const { imageList, imageNumber, review, email } = this.state;
        const imageTitle = imageList[imageNumber];
        review.imageTitle = imageTitle?.title;
        review.email = email;
        review.userEmail = imageTitle?.email;

        const data = {
            review: review,
            accessToken: cookies.get("access-token"),
        };
        console.log('review Json is ', JSON.stringify(review));

        axios.post(`${URL_PREFIX()}/datepracticals/postReview`, data)
            .then(res => this.handleReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponse = (res) => {
        console.log('after review submit', res);
        const { review } = this.state;
        const { userEmail, imageTitle } = review;

        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveReview`, data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    handleReviewSubmitResponseRes = (res) => {
        console.log('after review submit', res);
        this.setState({
            review: res.data
        })
    }

    handleBioReviewSubmitButton = () => {
        const { imageList, imageNumber, bioReview, email } = this.state;
        const imageTitle = imageList[imageNumber];
        bioReview.imageTitle = imageTitle?.title;
        bioReview.email = email;
        bioReview.userEmail = imageTitle?.email;

        const data = {
            bioReview: bioReview,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/postBioReview`, data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    // handleBioReviewSubmitRes = (res) => {
    //     console.log('after review submit', res);
    //     const { bioReview } = this.state;
    //     const { userEmail, imageTitle } = bioReview;

    //     const data = {
    //         userEmail: userEmail,
    //         imageTitle: imageTitle
    //     };

    //     axios.post("`${URL_PREFIX()}/datepracticals/retrieveBioReview", data)
    //         .then(res => this.handleBioReviewSubmitResponse(res))
    //         .catch(error => console.log(error));       
    // }

    handleBioReviewSubmitResponse = (res) => {
        console.log('after bio review submit', res);

        this.setState({
            bioReview: res.data
        })
    }

    bioChipClick = (e, key) => {
        const bioReview = { ... this.state.bioReview };
        let bioReviewKey = !bioReview[key];
        bioReview[key] = bioReviewKey;

        this.setState({
            bioReview: bioReview
        })
    }

    reviewChipClick = (e, key) => {
        const review = { ... this.state.review };
        let reviewKey = !review[key];
        review[key] = reviewKey;

        this.setState({
            review: review
        })
    }

    changeBioSliderVal = (key, val) => {
        const bioReview = { ...this.state.bioReview };
        bioReview[key] = val;

        this.setState({
            bioReview
        });
    }

    changePicSliderPicVal = (key, val) => {
        const review = { ...this.state.review };
        review[key] = val;

        this.setState({
            review
        });
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray();
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = () => {
        const amArray = this.getTime('AM');
        const pmArray = this.getTime('PM');
        const amPmArray = amArray.concat(pmArray);
        this.setState({
            amPmArray
        })
        return amPmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })


        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            this.getAvailableTimeSlots(this.state.today);
            this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
            selectedTimes: []
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
            selectedTimes: []
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false
            })
        }
    }

    // handleTimeSelection = (e) => {
    //     this.setState({
    //         bookDate: false
    //     })
    //     const timeValArray = e.target.value.split("-");
    //     const selectedWeekDay = timeValArray[0];
    //     const time = timeValArray[1];
    //     let { oneHourClick, amPmArray, selectedTimes } = this.state;
    //     let timeIndex = amPmArray.indexOf(time);

    //     let selectedTimesArray = [];

    //     selectedTimesArray.push(time);
    //     if (oneHourClick) {
    //         selectedTimesArray.push(amPmArray[timeIndex + 1]);
    //     }

    //     this.setState({
    //         selectedTimes: selectedTimesArray,
    //         selectedWeekDay: selectedWeekDay
    //     })
    // }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    handleBookDateStudentLengthResponse = (res) => {
        const orderId = res?.data.bookDatesLength;
        this.handleBookDates(orderId);
    }

    bookDateForStudent = () => {
        console.log('bookDateForStudent hit');
        //this.props.setBookDates(false);
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate, existingOrderId } = this.state;
        const { navigate } = this.props;

        if (selectedTimes && selectedTimes.length > 1) {
            axios.get(`${URL_PREFIX()}/datepracticals/bookDatesLength`)
                .then(res => this.handleBookDateStudentLengthResponse(res))
                .catch(error => console.log(error));
        } else {
            this.handleBookDates();
        }
    }

    handleBookDates = (orderId) => {
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate, selectedTeacher } = this.state;
        let actualCount = 0;
        let count = 0;

        for (let i = 0; i < selectedWeekDays.length; i++) {
            // for (let j = 0; j < selectedTimes.length; j++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                count++;
            }
        }

        for (let i = 0; i < selectedWeekDays.length; i++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                actualCount++;
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));
                const selectedTimeFirst = selectedTimes ? selectedTimes[i][0] : null;
                let bookedDateTime = new Date(bookedDate);

                if (selectedTimeFirst) {
                    const selectedTimeFirstLength = selectedTimeFirst.length;
                    const noOfHours = 0;
                    const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
                    const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
                    let selectedHour = selectedTimeFirstHoursMinutesArray[0];
                    const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

                    if (isAmOrPm === 'PM') {
                        selectedHour = Number(selectedHour) + 12;
                    }
                    bookedDateTime.setHours(selectedHour);
                    bookedDateTime.setMinutes(Number(selectedMinutes));
                }

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[i][0];

                const reminderDateTime = this.getReminderDateTime(bookedDate, selectedTimes[i]);

                this.setState({
                    bookDate,
                    dateBookingMessage
                })

                const bookingDateAndTime = {
                    userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
                    teacherName: selectedTeacher.firstname,
                    userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
                    teacherEmail: selectedTeacher.email,
                    bookedDate: bookedDate,
                    bookedDateTime: bookedDateTime,
                    reminderDateTime: reminderDateTime,
                    bookedTimes: selectedTimes[i],
                    createdDate: moment(),
                    roomId: chance.guid(),
                    price: selectedTeacher ? selectedTeacher.priceSlider : 10,
                    accessToken: cookies.get("access-token"),
                    orderId: orderId && orderId > 0 ? orderId : null
                }

                axios.post(`${URL_PREFIX()}/datepracticals/bookDate`, bookingDateAndTime)
                    .then(res => this.handleBookDateStudentResponse(res, count, actualCount))
                    .catch(error => console.log(error));
            }
        }
    }

    handleBookDateStudentResponse = (res, count, actualCount) => {
        // if (res.data.orderId) {
        //     this.setState({
        //         existingOrderId: res.data.orderId
        //     })
        // }
        if (count === actualCount) {
            const id = res?.data?.orderId ? res.data.orderId : null;
            const teacherName = res?.data?.teacherName ? res.data.teacherName : null;
            const price = res?.data?.price ? res.data.price * actualCount : null;
            const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;

            this.props.navigate(`/beforePayment/${id}/${teacherName}/${price}/${teacherEmail}`, {

            });
            this.setState({
                dateBookingSuccessful: true,
                bookDate: true
            })
        }
    }

    bookDate = () => {
        let { bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;
        bookDate = !bookDate;

        if (bookDate) {
            this.setState({
                cancelDate: false,
                dateBookingSuccessful: false,
            })
        }

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY hh:mm:ss'));
        const selectedTimeFirst = selectedTimes ? selectedTimes[0] : null;
        let bookedDateTime = new Date(bookedDate);

        if (selectedTimeFirst) {
            const selectedTimeFirstLength = selectedTimeFirst.length;
            const noOfHours = 0;
            const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
            const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
            let selectedHour = selectedTimeFirstHoursMinutesArray[0];
            const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

            if (isAmOrPm === 'PM') {
                selectedHour = Number(selectedHour) + 12;
            }
            bookedDateTime.setHours(selectedHour);
            bookedDateTime.setMinutes(Number(selectedMinutes));
            //saving date in EST, as it's adding 5 hours to the time
            // const myTimezone = "America/Toronto";
            // var myDatetimeFormat = "MM/DD/YYYY hh:mm:ss a z";
            // bookedDateTime = moment(bookedDateTime.getMilliseconds()).tz(myTimezone).format(myDatetimeFormat);
            //bookedDateTime.setTime(bookedDateTime.getTime() - bookedDateTime.getTimezoneOffset() * 60 * 1000);
        }

        //let todayDate = new Date(moment(today).format('MM/DD/YYYY'));
        let dateArray = bookedDateNoFormat.toString().split(" ");
        let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

        const reminderDateTime = this.getReminderDateTime(bookedDate, selectedTimes);

        this.setState({
            bookDate,
            dateBookingMessage
        })

        const bookingDateAndTime = {
            userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
            teacherName: selectedTeacher.firstname,
            userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
            teacherEmail: selectedTeacher.email,
            bookedDate: bookedDate,
            bookedDateTime: bookedDateTime,
            reminderDateTime: reminderDateTime,
            bookedTimes: selectedTimes,
            createdDate: moment(),
            roomId: chance.guid(),
            price: selectedTeacher ? selectedTeacher.priceSlider : 10,
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookDate`, bookingDateAndTime)
            .then(res => this.handleBookDateResponse(res))
            .catch(error => console.log(error));
    }

    checkCurrentTimeBefore = (availableTime) => {
        let { selectedTimes, today, weekArray, dateBookingSuccessful, selectedTeacher } = this.state;

        const timeValArray = availableTime.split("-");
        const selectedWeekDay = timeValArray[0];
        const time = timeValArray[1];

        let index = weekArray.indexOf(selectedWeekDay);
        let bookedDateNoFormat = moment(today).add(index, 'days');
        let bookedDate = new Date(moment(today).add(index, 'days').format('MM/DD/YYYY hh:mm:ss'));
        const selectedTimeFirst = time;
        let bookedDateTime = new Date(bookedDate);

        if (selectedTimeFirst) {
            const selectedTimeFirstLength = selectedTimeFirst.length;
            const noOfHours = 0;
            const isAmOrPm = selectedTimeFirst.substring(selectedTimeFirstLength - 2, selectedTimeFirstLength);
            const selectedTimeFirstHoursMinutesArray = selectedTimeFirst.split(":");
            let selectedHour = selectedTimeFirstHoursMinutesArray[0];
            const selectedMinutes = selectedTimeFirstHoursMinutesArray[1].substring(0, 2);

            if (isAmOrPm === 'PM') {
                selectedHour = Number(selectedHour) + 12;
            } else {
                if (selectedHour === "12") {
                    selectedHour = "0";
                }
            }
            bookedDateTime.setHours(selectedHour);
            bookedDateTime.setMinutes(Number(selectedMinutes));
            bookedDateTime.setSeconds(0);

            const isBefore = new Date().getTime() > bookedDateTime.getTime();
            return isBefore;
        }
    }

    getReminderDateTime = (bookedDate, selectedTimes) => {
        const bookedDateArray = bookedDate?.toString().split(" ");
        let reminderDate = bookedDateArray ? bookedDateArray[1] + " " + bookedDateArray[2] : '';
        const selectedTimesZeroArray = selectedTimes[0]?.split(":");
        const selectedTimesOneArray = selectedTimes[1]?.split(":");
        const timeString = selectedTimes[0] + " - " + (selectedTimesOneArray[0] === '12' ? 1 : Number(selectedTimesZeroArray[0]) + 1) + ":" + selectedTimesZeroArray[1];
        reminderDate = reminderDate + " " + timeString;
        return reminderDate;
    }

    handleBookDateResponse = (res) => {
        const id = res?.data?.orderId ? res.data.orderId : null;
        const teacherName = res?.data?.teacherName ? res.data.teacherName : null;
        const price = res?.data?.price ? res.data.price : null;
        const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;

        this.props.navigate(`/beforePayment/${id}/${teacherName}/${price}/${teacherEmail}`, {

        });
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            this.getAvailableTimeSlots(moment());
            this.getBookedTimeSlots(moment());
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleReviewOpen = () => {
        this.setState({
            reviewOpen: true,
            reviewSliderVal: 1,
            reviewPostingSuccessful: false
        })
    }

    handleReviewClose = () => {
        this.setState({
            reviewOpen: false
        })
    }

    postReview = () => {
        let { reviewText, reviewSliderVal } = this.state;
        console.log('review text ', reviewText);

        const review = {
            userName: 'sarat',
            teacherName: 'celina',
            userEmail: 'sarat.43@gmail.com',
            teacherEmail: 'celina.lemir@gmail.com',
            reviewText: reviewText,
            createdDate: moment(),
            rating: reviewSliderVal,
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/reviewPost`, review)
            .then(res => this.handlePostReviewResponse(res))
            .catch(error => console.log(error));
    }

    valuetext = (value) => {
        // this.setState({
        //     reviewSliderVal: value
        // })
        return value;
    }

    handlePostReviewResponse = (res) => {
        this.setState({
            reviewPostingSuccessful: true
        })
    }

    handleReviewSliderChange = (e) => {
        this.setState({
            reviewSliderVal: e.target.value
        })
    }

    getReviews = () => {
        const data = {
            teacherEmail: "celina.lemir@gmail.com",
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/getReviews`, data)
            .then(res => this.handleReviewsResponse(res))
            .catch(error => console.log(error));
    }

    handleReviewsResponse = (res) => {
        this.setState({
            reviewsArray: res.data,
            reviewsOpen: true
        })
    }

    handleReviewsClose = () => {
        this.setState({
            reviewsOpen: false
        })
    }

    handleVideoOpen = (index) => {
        const { selectedIndex, ratersArray } = this.state;
        this.setState({
            videoOpen: true,
            videoUrl: ratersArray[index].videoLink
        })
    }

    handleVideoClose = () => {
        this.setState({
            videoOpen: false
        })
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }

    retrieveDateImageUrl = (index) => {
        return this.state['dateimageUrl' + index];
    }

    retrieveCountryLogo = (index) => {
        const { ratersArray } = this.state;

        return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + ratersArray[index].country + ".svg";
    }

    retrieveVideoUrl = () => {
        const { selectedIndex, ratersArray } = this.state;

        this.setState({
            videoUrl: ratersArray[selectedIndex].videoLink
        })
        //return ratersArray[selectedIndex].videoLink;
    }

    getAvailableTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/availableTimeSlots`, data)
            .then(res => this.handleAvailableTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    handleAvailableTimeSlotResponse = (res, weekStartDate) => {
        let availableTimeSlotDict = {};
        const availableTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            availableTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                availableTimeSlots && availableTimeSlots.length > 0 && availableTimeSlots.map(slot => {
                    if (moment(slot.availableDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!availableTimeSlotDict[i]) {
                            availableTimeSlotDict[i] = slot.availableTimes;
                        } else {
                            for (let j = 0; j < slot.availableTimes.length; j++) {
                                availableTimeSlotDict[i].push(slot.availableTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            availableTimeSlotDict
        })
    }

    getOnlineDateMonth = (bookedDate) => {
        const date = new Date(bookedDate);
        let month = date.toLocaleString('default', { month: 'long' });
        return month.substring(0, 3);
        //return 'hi';
    }

    getOnlineDateDate = (bookedDate) => {
        const date = new Date(bookedDate);
        //return 'hi';
        return date.getDate();
    }

    getOnlineDateDay = (bookedDate) => {
        const date = new Date(bookedDate);
        const weekday = date.toLocaleString('default', { weekday: 'long' });
        //return 'hi';
        return weekday;
    }

    goToChatRoom = (roomId, userName, teacherName, userEmail, teacherEmail, index, fromTheBox) => {
        socket.emit("join_message_room", roomId);
        this.setState({
            showModelChat: fromTheBox,
            room: chance.guid(),
            username: userName,
            teacherName: teacherName,
            userEmail: userEmail,
            teacherEmail: teacherEmail,
            sentBy: this.state.username === userName ? userEmail : teacherEmail,
            raterIndex: index,
        })
    }

    handleChatClose = () => {
        this.setState({
            showModelChat: false
        })
    }

    setShowInstructorDialog = () => {
        this.setState({
            showInstructorsProfile: false
        })
    }

    handleCloseSubscriptionPlansDialog = () => {
        this.props.setshowSubscriptionPlansDialog(false);
    }

    continueToCheckout = (e) => {
        const price = e.target.value;
        const { rater } = this.props;

        const bookingDateAndTime = {
            userName: this.props?.location?.state?.username ? this.props.location.state.username : cookies.get('username'),
            teacherName: rater?.firstname,
            userEmail: this.props?.location?.state?.userEmail ? this.props.location.state.userEmail : cookies.get('email'),
            teacherEmail: rater?.email,
            price: price,
            hourCost: rater?.priceSlider,
            bookedDate: moment(),
            accessToken: cookies.get("access-token")
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookSubscription`, bookingDateAndTime)
            .then(res => this.handleBookSubsciptionResponse(res))
            .catch(error => console.log(error));
    }

    handleBookSubsciptionResponse = (res) => {
        const id = res?.data?.orderId ? res.data.orderId : null;
        const userName = res?.data?.teacherName ? res.data.teacherName : null;
        const price = res?.data?.price ? res.data.price : null;
        const teacherEmail = res?.data?.teacherEmail ? res.data.teacherEmail : null;
        const hourCost = res?.data?.hourCost ? res.data.hourCost : null;

        this.props.navigate(`/beforePaymentSubscription/${id}/${userName}/${price}/${teacherEmail}/${hourCost}`, {
        });
    }

    render() {
        const { email, username, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, datesArray, availableTimeSlotDict, showAvailableDatesDialog, showModelChat, room, teacherName, userEmail, teacherEmail, sentBy, raterIndex,
            showInstructorsProfile, selectedWeeklyTimes, selectedWeeklyDays, selectedIndex
        } = this.state;
        const { navigate, showSubscriptionPlansDialog, rater, imageSrc } = this.props;
        const { isProfilePic, attractivenessSliderVal, confidenceSliderVal, charmingSliderVal, responsibleSliderVal,
            funSliderVal, intelligentSliderVal, willingToDateSliderVal, picOrderSliderVal, needtoLookAtCamera, toSmile, toDelete,
            tooMuchFiltering, isLightingBad, isBadSelfie, phoneNoOrEmail, suggestions, pictureEffectivenessSliderVal, looksTooYoung, getHairCut, trimYourBeard, niceGuyVibe, reduceWeight } = review;

        const { promptEffectivenessSliderVal, showsPersonality, isShortBio, wantToDate, notReleatedAnswer, deleteThisPrompt, replaceThisPrompt, gramatticalMistakes,
            difficultToUnderstand, boringAnswer, tooLongAnswer, deletePolitics, deleteReligion, whatYouAreLookingFor, hobbiesAndInterests, racistVibe,
            goldDiggerVibe, looksTooOld, givingTooMuchInfo, workOnYourself, mentalHealthIssues, bioSuggestions,
            buildMuscles, museumsAndEvents, goToYogaClasses, goOut, goToFitnessGroupClasses, getACoach, goToBars, joinsalsaClasses,
            dontTalk, askQuestions, lightlyFlirt, workOnYourConfidence, goToLibraries, askPhoneNo, showFunSide, beAuthentic, approachMoreWomen, getAJob,
            workOnCareer, loveYourself, getATherapist, getMoreFriends, helpPeople, beVulnerable, connectWithPeople, celebrateYourLife, workOnSpiritualSide,
            workOnSelfEsteem, practiceMeditation
        } = bioReview;

        return (
            <div >
                <React.Fragment>
                    <Dialog fullWidth
                        maxWidth="lg"
                        open={showSubscriptionPlansDialog}
                        onClose={this.handleCloseSubscriptionPlansDialog}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ width: '100%' }}
                        classes={{ paperFullWidth: { 'max-width': '100%' } }}>

                        <DialogTitle>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item>
                                            Choose your plan
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={this.handleCloseSubscriptionPlansDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        <DialogContent>
                            <>

                                {this.weeksArray && this.weeksArray.length > 0 && this.weeksArray.map((week, index) => (
                                    <>
                                        <Grid container >

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <React.Fragment>
                                                    <Box sx={{
                                                        width: "100%",
                                                        height: this.isMobile ? 90 : 80,
                                                        padding: "1%",
                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderRadius: 4,
                                                        marginTop: '5px'
                                                    }} onClick={() => this.setState({ selectedIndex: index })}>
                                                        <Grid container>
                                                            <Grid item xs={2} sm={1} md={1} lg={1}>
                                                                <img src={imageSrc} style={
                                                                    {
                                                                        width: '90%',
                                                                        height: 50,
                                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                        borderRadius: 4,
                                                                        cursor: 'pointer',
                                                                        marginRight: '10px'
                                                                    }} ></img>
                                                            </Grid>
                                                            <Grid item xs={8} sm={5} md={5} lg={5}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <div>
                                                                            {week}/Week
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} style={{ marginTop: '5px' }}>
                                                                        <div>
                                                                            ${rater.priceSlider * week * 4} for 4 weeks
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                <Button color='primary' variant="outlined" onClick={this.continueToCheckout} value={rater.priceSlider * week * 4} style={{
                                                                    width: this.isMobile ? '95%' : '98%', height: this.isMobile ? '30px' : '50px', marginBottom: '5px',
                                                                    backgroundColor: '#FF00FF', color: 'white', marginLeft: '0.5rem', marginRight: '0.5rem'
                                                                }}>
                                                                    Continue to checkout
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid >
                                        {this.isMobile ? <br /> : null}
                                    </>
                                ))
                                }
                                <Grid container>
                                    <Grid item xs={12}>
                                    </Grid>
                                </Grid>
                            </>
                        </DialogContent>

                    </Dialog>
                </React.Fragment >





            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <SubscriptionPlans {...props} location={location} navigate={navigate} />;
}
