import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

import {

    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,

} from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";

import { connect } from 'react-redux';

import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPageWithoutHeading from '../login/LoginPageWithoutHeading';
import calendar from '../dialog/calendar';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import Navbar from '../navbar/Navbar';
import { hi } from 'date-fns/locale';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Room from '../room/Room';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const loginPageLink = window.location.href.toString();
const isloginOrSignUp = loginPageLink.includes('login') || loginPageLink.includes('signup');
const attractivenessSliderValKey = 'attractivenessSliderVal';
const confidenceSliderValKey = 'confidenceSliderVal';


const singleBox = {
    border: 3,
    borderColor: "black",
    borderStyle: "dashed",
    borderRadius: 16
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styles = theme => ({
    dialogCustomizedWidth: {
        'max-width': '80%'
    }
});

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const reviewStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    color: '#FFC300',
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const marks = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    }, {
        value: 5,
        label: 5
    }
]

const videoStyle = {
    width: '550px',

}

class OnlineDates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            imageUrl: null,
            imageList: null,
            imageNumber: 0,
            removeBackButton: true,
            removeNextButton: false,
            disablePicturesretrieval: false,
            openLogin: false,
            selectedTab: 0,
            review: {
                imageTitle: '',
                email: '',
                userEmail: '',
                isProfilePic: false,
                attractivenessSliderVal: 5,
                confidenceSliderVal: 5,
                charmingSliderVal: 5,
                responsibleSliderVal: 5,
                funSliderVal: 5,
                intelligentSliderVal: 5,
                willingToDateSliderVal: 5,
                picOrderSliderVal: 0,
                needtoLookAtCamera: false,
                toSmile: false,
                toDelete: false,
                tooMuchFiltering: false,
                needToLookAtCamera: false,
                isLightingBad: false,
                isBadSelfie: false,
                toRecommendOthers: false,
                phoneNoOrEmail: '',
                suggestions: '',
                pictureEffectivenessSliderVal: 0,
                looksTooYoung: false,
                getHairCut: false,
                trimYourBeard: false,
                niceGuyVibe: false,
                reduceWeight: false
            },
            bioReview: {
                imageTitle: '',
                email: '',
                userEmail: '',
                promptEffectivenessSliderVal: 0,
                showsPersonality: false,
                isShortBio: false,
                wantToDate: false,
                notReleatedAnswer: false,
                deleteThisPrompt: false,
                gramatticalMistakes: false,
                difficultToUnderstand: false,
                boringAnswer: false,
                tooLongAnswer: false,
                deletePolitics: false,
                deleteReligion: false,
                whatYouAreLookingFor: false,
                hobbiesAndInterests: false,
                racistVibe: false,
                goldDiggerVibe: false,
                looksTooOld: false,
                givingTooMuchInfo: false,
                workOnYourself: false,
                mentalHealthIssues: false,
                bioSuggestions: '',

            },
            open: false,
            oneHourClick: true,
            min30Click: false,
            bookDate: false,
            cancelDate: false,
            availableTimes: [],
            weekArray: [],
            weekDayArrayping: {},
            todayDate: '',
            weekAfterDate: '',
            weekAfterYear: '',
            weekAfter: null,
            timeZone: null,
            todayYear: null,
            currentDayDiff: 0,
            amPmArray: [],
            selectedTimes: [],
            selectedWeekDay: '',
            dateBookingSuccessful: false,
            reviewOpen: false,
            reviewText: '',
            reviewSliderVal: 1,
            reviewPostingSuccessful: false,
            reviewsArray: [],
            reviewsOpen: false,
            videoOpen: false,
            ratersArray: [],
            selectedoIndex: 0,
            videoUrl: '',
            bookDate: new Date(),
            bookedTimes: [],
            bookedTimeSlots: [],
            showVideo: false
        }
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;

        this.setState({
            username: cookies.get('username'),
            email: cookies.get('email'),
            isRater: cookies.get('rater')
        }, () => {
            if (this.state.isRater) {
                this.getOnlineDatesToTeach();
            } else {
                this.getOnlineDates();
            }
        })
    }

    getImageList = (emailToRetrieve) => {
        console.log('retrieving images list');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/pictures`, data)
            .then(res => this.handleImageResponse(res))
            .catch(error => console.log(error));
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value
        })
    }

    getOnlineDates = () => {
        console.log('retrieving images list');
        const data = {
            userEmail: this.state.email,
            accessToken: cookies.get("access-token"),
            isProd: !window.location.href.toString().includes('localhost')
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDates`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    getOnlineDatesToTeach = () => {
        console.log('retrieving images list');
        const data = {
            teacherEmail: this.state.email,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/getOnlineDatesToTeach`, data)
            .then(res => this.handleOnlineDatesResponse(res))
            .catch(error => console.log(error));
    }

    handleOnlineDatesResponse = (res) => {
        //const { email } = this.state;
        console.log('handleRetrieversResponse hit', res);
        //getting unique teachers
        //const ratersList = [... new Set(res.data.map(item => item.email))];
        const uniqueratersEmail = [];
        const uniqueRaters = [];

        res.data.map((data) => {
            uniqueRaters.push(data);
        })
        //console.log('imageList ', JSON.stringify(ratersList));
        this.setState({
            ratersArray: uniqueRaters
        }, () => {
            //console.log(ratersList);
            uniqueRaters.map((rater, index) => (
                this.retrieveFirstImage(this.state.isRater ? rater?.userEmail : rater?.teacherEmail, index)
                //this.retrieveFirstImage(rater.teacherEmail, index)
            ));
        });
    }

    retrieveFirstImage = (emailToRetrieve, index) => {
        const url = emailToRetrieve + 'url';
        // const { imageList } = this.state;
        // const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving retrieveFirstImage');
        const data = {
            email: emailToRetrieve,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveFirstImage`, data, { responseType: 'blob' })
            .then(res => this.handleFirstImageResponse(res, index))
            .catch(error => console.log(error));
    }

    handleFirstImageResponse = (res, index) => {
        console.log('handleFirstImageResponse hit', res);
        const imageUrl = 'imageUrl' + index;
        //const image = res.data;
        //const url = URL.createObjectURL(image);
        //return url;
        this.setState({
            [imageUrl]: URL.createObjectURL(res.data)
        })
    }


    retrieveImage = (emailToRetrieve, imageNumber) => {
        const { imageList } = this.state;
        const fileName = imageList && imageList[imageNumber]?.title;

        console.log('retrieving images');
        const data = {
            email: emailToRetrieve,
            fileName: fileName,
            accessToken: cookies.get("access-token"),
        };

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveImage`, data, { responseType: 'blob' })
            .then(res => this.handleResponse(res))
            .catch(error => console.log(error));
    }

    handleResponse = (res) => {
        console.log('handleResponse hit', res);
        const image = res.data;
        const url = URL.createObjectURL(image);
        return url;


        // this.setState({
        //     imageUrl: url
        // })
    }

    handleImageResponse = (res) => {
        const { email } = this.state;
        console.log('handleImageResponse hit', res);
        const imageList = res.data;
        console.log('imageList ', JSON.stringify(imageList));
        this.setState({
            imageList
        });
        this.retrieveImage(email, 0);
        //const mail = imageList?.[0]?.email;
        //const imageTitle = imageList?.[0]?.title;
        //this.retrieveReviews(mail, imageTitle);
        //this.retrieveBioReviews(mail, imageTitle);
    }

    retrieveReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveReview`, data)
            .then(res => this.handleReviewSubmitResponseRes(res))
            .catch(error => console.log(error));
    }

    retrieveBioReviews(userEmail, imageTitle) {
        const data = {
            userEmail: userEmail,
            imageTitle: imageTitle,
            accessToken: cookies.get("access-token"),
        };
        console.log('userEmail ', userEmail);
        console.log('imageTitle ', imageTitle);

        axios.post(`${URL_PREFIX()}/datepracticals/retrieveBioReview`, data)
            .then(res => this.handleBioReviewSubmitResponse(res))
            .catch(error => console.log(error));
    }

    handleChange = (key, event) => {
        this.setState({
            [key]: event.target.value
        });
    };

    handleVideoOpen = (index) => {
        const { selectedIndex, ratersArray } = this.state;
        this.setState({
            videoOpen: true,
            videoUrl: ratersArray[index].videoLink
        })
    }

    handleVideoClose = () => {
        this.setState({
            videoOpen: false
        })
    }

    retrieveImageUrl = (index) => {
        return this.state['imageUrl' + index];
    }

    retrieveCountryLogo = (index) => {
        const { ratersArray } = this.state;

        return "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + ratersArray[index].country + ".svg";
    }

    retrieveVideoUrl = () => {
        const { selectedIndex, ratersArray } = this.state;

        this.setState({
            videoUrl: ratersArray[selectedIndex].videoLink
        })
        //return ratersArray[selectedIndex].videoLink;
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY')),
            accessToken: cookies.get("access-token"),
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    getOnlineDateMonth = (bookedDate) => {
        const date = new Date(bookedDate);
        let month = date.toLocaleString('default', { month: 'long' });
        return month.substring(0, 3);
        //return 'hi';
    }

    getOnlineDateDate = (bookedDate) => {
        const date = new Date(bookedDate);
        //return 'hi';
        return date.getDate();
    }

    getOnlineDateDay = (bookedDate) => {
        const date = new Date(bookedDate);
        const weekday = date.toLocaleString('default', { weekday: 'long' });
        //return 'hi';
        return weekday;
    }

    navigateToRoom = (index) => {
        const { navigate } = this.props;
        const { ratersArray, username } = this.state;
        const roomId = ratersArray ? ratersArray[index]?.roomId : null;

        // this.setState({
        //     showVideo: true,
        //     room: roomId
        // })
        navigate(`/room/${roomId}`, { state: { 'roomId': roomId, username: username } });
    }

    handleVideoClose = () => {
        this.setState({
            showVideo: false
        })
    }

    render() {
        const { email, imageUrl, removeNextButton, removeBackButton, disablePicturesretrieval, review, openLogin, bioReview, videoRef, open, weekArray, weekDayArrayMapping, availableTimes,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            setSelectedTab, selectedTab, rater, room, showVideo
        } = this.state;
        const { isProfilePic, attractivenessSliderVal, confidenceSliderVal, charmingSliderVal, responsibleSliderVal,
            funSliderVal, intelligentSliderVal, willingToDateSliderVal, picOrderSliderVal, needtoLookAtCamera, toSmile, toDelete,
            tooMuchFiltering, isLightingBad, isBadSelfie, phoneNoOrEmail, suggestions, pictureEffectivenessSliderVal, looksTooYoung, getHairCut, trimYourBeard, niceGuyVibe, reduceWeight } = review;

        const { promptEffectivenessSliderVal, showsPersonality, isShortBio, wantToDate, notReleatedAnswer, deleteThisPrompt, replaceThisPrompt, gramatticalMistakes,
            difficultToUnderstand, boringAnswer, tooLongAnswer, deletePolitics, deleteReligion, whatYouAreLookingFor, hobbiesAndInterests, racistVibe,
            goldDiggerVibe, looksTooOld, givingTooMuchInfo, workOnYourself, mentalHealthIssues, bioSuggestions,
            buildMuscles, museumsAndEvents, goToYogaClasses, goOut, goToFitnessGroupClasses, getACoach, goToBars, joinsalsaClasses,
            dontTalk, askQuestions, lightlyFlirt, workOnYourConfidence, goToLibraries, askPhoneNo, showFunSide, beAuthentic, approachMoreWomen, getAJob,
            workOnCareer, loveYourself, getATherapist, getMoreFriends, helpPeople, beVulnerable, connectWithPeople, celebrateYourLife, workOnSpiritualSide,
            workOnSelfEsteem, practiceMeditation
        } = bioReview;

        return (
            <div  >
                {<React.Fragment>
                    <Grid item xs={0} sm={1} md={2} lg={2}>

                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Button color='primary' variant="outlined" onClick={() => { this.props.setAvailableSlots(true) }} value={JSON.stringify(rater)} style={{
                            width: this.isMobile ? '95%' : '98%', height: '50px', marginBottom: '5px',
                            backgroundColor: '#FF00FF', color: 'white', marginLeft: '0.5rem', marginRight: '0.5rem', fontWeight: 'bold', fontSize: '30px', marginBottom: '10px'
                        }}>
                            + Schedule
                        </Button>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Upcoming online dates
                        </Grid>
                    </Grid>
                    <Grid container>
                        {ratersArray && ratersArray.length > 0 && ratersArray.map((rater, index) => (
                            <React.Fragment>
                                <Grid item xs={12} sm={12} md={8} lg={6}>
                                    <Box sx={{
                                        width: '100%',
                                        height: 80,
                                        padding: "14px 14px",
                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                        borderRadius: 4,
                                        marginTop: index < 3 ? '2%' : '2%'
                                    }} onClick={() => this.setState({ selectedIndex: index })}>
                                        <Grid container>
                                            <Grid item xs={9} sm={8} md={9} lg={8}>
                                                <Grid container>
                                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={this.retrieveImageUrl(index)} width="30px" height="40px" sx={{
                                                                width: 30,
                                                                height: 20,
                                                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                borderRadius: 4,
                                                            }}></img>
                                                        </div>
                                                    </Grid>
                                                    {this.isMobile ?
                                                        <Grid item xs={1} sm={0} md={0} lg={0}>

                                                        </Grid> : null}
                                                    <Grid item xs={2} sm={2} md={1} lg={2}>
                                                        <Grid container style={!this.isMobile ? { marginLeft: '1.5rem' } : null}>
                                                            <Grid item xs={12}>
                                                                <span style={{ fontSize: 16 }} > {this.getOnlineDateMonth(rater.bookedDate)}</span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <span style={{ marginLeft: '10px', fontSize: 16 }}>{this.getOnlineDateDate(rater.bookedDate)}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {this.isMobile ?
                                                        <Grid item xs={0} sm={1} md={1} lg={0}>

                                                        </Grid> : <Grid item xs={0} sm={0} md={0} lg={0}>

                                                        </Grid>}
                                                    <Grid item xs={5} sm={5} md={6} lg={5}>
                                                        <Grid container style={this.isMobile ? { marginLeft: '1.5rem' } : null}>
                                                            <Grid item xs={12}>
                                                                <span style={{ fontSize: 16 }} >{this.isMobile ? this.getOnlineDateDay(rater.bookedDate).substring(0, 3) : this.getOnlineDateDay(rater.bookedDate)}{','} {rater.bookedTimes[0]}</span>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <span style={{ textTransform: 'capitalize', fontSize: 14 }}>{this.state.isRater ? rater?.userName : rater?.teacherName}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {!this.isMobile ?
                                                <Grid item xs={0} sm={1} md={0} lg={1}>


                                                </Grid> : null}
                                            <Grid item xs={2} sm={3} md={3} lg={3}>
                                                <Button style={{ marginLeft: '5px', color: "black", backgroundColor: '#D3D3D3', width: '90%' }} variant="contained"
                                                    onClick={() => { this.navigateToRoom(index) }}>
                                                    Join
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Grid>
                                <Grid item xs={0} sm={0} md={6} lg={8}>
                                </Grid>
                                {
                                    showVideo ?
                                        <Dialog
                                            fullWidth
                                            maxWidth="lg"
                                            open={showVideo}
                                            onClose={this.handleVideoClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                            sx={{ width: '100%' }}
                                            classes={{ paperFullWidth: { 'max-width': '100%' } }}
                                        >
                                            <DialogTitle>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={11}>
                                                        {/* <Grid container spacing={2}>
                                                            <Grid item xs={1} >
                                                                <div style={{ position: 'relative' }}>
                                                                    <img src={this.retrieveImageUrl(raterIndex)} width="100%" height="40px" sx={{
                                                                        width: 30,
                                                                        height: 20,
                                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                                        borderRadius: 4,
                                                                    }}></img>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                {teacherName}
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>
                                                    <Grid item xs={1} style={{
                                                        display: 'flex',
                                                        justifyContent: 'right',
                                                        alignItems: 'right',
                                                    }}>
                                                        <IconButton onClick={this.handleVideoClose}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Room room={room} username={cookies.get('username')} />
                                            </DialogTitle>
                                            {/* <DialogContent>
                            <Chat socket={socket} username={username} room={room} teacherName={teacherName} userEmail={userEmail} teacherEmail={teacherEmail} sentBy={sentBy}
                            />
                        </DialogContent> */}
                                            <DialogActions>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                    </Grid>
                                                    <Grid item xs={1} style={{ marginLeft: '0.3em' }}>
                                                        <Button variant="outlined" onClick={this.handleChatClose}>Cancel</Button>
                                                    </Grid>
                                                </Grid>
                                            </DialogActions>
                                        </Dialog>
                                        : null
                                }
                            </React.Fragment>

                        ))

                        }
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>

                </React.Fragment >
                }
            </div >
        )
    }
}

export default function (props) {
    const location = useLocation();
    const navigate = useNavigate();

    return <OnlineDates {...props} location={location} navigate={navigate} />;
}
