import React from "react";
import { withTheme, withMediaQuery } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core'
import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
} from "@mui/material";
//import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
//import axios from 'axios';
//import { ReactCountryDropdown } from 'react-country-dropdown';
import countryList from 'react-select-country-list'
import ReactFlagsSelect from "react-flags-select";
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Slider from '@mui/material/Slider';
//import DatePicker from "react-datepicker";
//import DatePicker from 'react-date-picker';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
//import moment from 'moment';

//import UploadImages from '../upload/Upload';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-number-input';
import ReactCountryFlag from "react-country-flag";

// import {


//     Grid,
//     Paper,
//     AppBar,
//     Typography,
//     Toolbar,

// } from "@material-ui/core";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";


import ContinuousSlider from '../slider/slider';
import UploadImages from '../upload/Upload';
import LoginPage from '../login/LoginPage';
import calendar from '../dialog/calendar';
//import celina from '../../../src/assets/images/celina.PNG';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ReactPlayer from 'react-player';
import { TroubleshootRounded } from "@mui/icons-material";

import { useTheme } from '@mui/material/styles';
import { IS_PROD, NODE_ENV, DEV_URL, PROD_URL, URL_PREFIX, IS_MOBILE, IS_LAPTOP, IS_DESKTOP } from '../../const/const';
import Cookies from 'universal-cookie';
import Heading from '../heading/Heading';

const cookies = new Cookies();

// const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
//     defaultMatches: true
// });

const weekDayArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const TenHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%',
    width: 20
}

const TenHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const oneHourClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10%',
    marginTop: '8%'
}

const min30ClickButtonStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const min30ClickButtonStyleDisable = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15%',
    marginTop: '8%'
}

const oneHourOr30MinTimeSelectStyleEnable = {
    borderRadius: 35,
    backgroundColor: "#1976d2",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const oneHourOr30MinTimeSelectStyleNormal = {
    borderRadius: 35,
    backgroundColor: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '30%'
}

const styles = {
    loginform:
    {
        justifyContent: 'center',
        minHeight: '90vh'
    },
    buttonBlock: {
        width: '40%',
        justifyContent: 'center',
        size: 'small'
    },
    loginBackground:
    {
        justifyContent: 'center',
        minHeight: '30vh',
        padding: '50px'
    },
    datepracticals: {
        padding: '20px',
        marginTop: '60px',
        marginLeft: '30vh',
        backGroundColor: 'red'
    }
};

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    //padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    // backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const BootstrapLoginButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    marginLeft: '25%',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: 50,
    borderWidth: 2,
    color: 'black',
    backgroundColor: 'white',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const teacherSignupNormal = {
    borderRadius: 35,
    backgroundColor: "#52BE80",
    color: "white",
    padding: "1px 6px",
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
}

const loginPageLink = window.location.href.toString();
const isloginOrSignUpPage = loginPageLink.includes('login') || loginPageLink.includes('signup');

const priceMarks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 20,
        label: 20
    },
    {
        value: 100,
        label: 100
    },
    {
        value: 150,
        label: 150
    }, {
        value: 200,
        label: 200
    }
]

const splitScreen = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}

const heading = {
    height: '10%',
}

const topPane = {
    height: '60%',
}

const backButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '8.5%',
}

const submitButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '9%',
}

const nextButtonPane = {
    position: 'absolute',
    marginTop: '120px',
    //marginBottom: '35%',
    marginLeft: '10%',
}

class TeacherAddDates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            username: "",
            password: "",
            authflag: 1,
            years: [],
            age: 2003,
            country: 'US',
            birthday: new Date(),
            userEmail: '',
            disablePicturesretrieval: false,
            openUpload: false,
            pictures: [],
            aboutYou: '',
            screenVal: 0,
            priceSlider: 1,
            selectedWeekDays: [],
            selectedTimes: [],
            selectedTimesArray: [],
            selectedWeeklyDays: [],
            selectedWeeklyTimes: []
        };


    }

    componentDidMount() {
        this.isMobile = window.innerWidth <= 600;
        this.isSmallerScreen = window.innerWidth >= 600 && window.innerWidth <= 900;
        this.isLaptop = window.innerWidth >= 900 && window.innerWidth <= 1300;
        this.isDesktop = window.innerWidth >= 1300;
        const { handleIsloginOrSignUpPage } = this.props;
        this.getInitialValues();
    }

    getInitialValues = () => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: this.state.email
        }, () => {
            // this.getInitialValues();
            // let weekStartDate = new Date(moment().format('MM/DD/YYYY'));
            // let weekEndDate = new Date(moment().add(7, 'days').format('MM/DD/YYYY'));

        })
        let today = moment();
        let todayArray = today.toString().split(" ");
        let todayDay = todayArray[0];
        let todayDate = todayArray[1] + " " + todayArray[2];
        let todayYear = todayArray[3];
        let weekAfter = moment().add(7, 'days');
        let weekAfterArray = weekAfter.toString().split(" ");
        let weekAfterDay = weekAfterArray[0];
        let weekAfterDate = weekAfterArray[1] + " " + weekAfterArray[2];
        let weekAfterYear = weekAfterArray[3];
        const split = new Date().toString().split(" ");
        const timeZone = "(" + split[5] + ", " + split[6].substring(1) + " " + split[7] + " " + split[8].substring(0, split[8].length - 1) + ")";

        this.setState({
            today,
            todayDay,
            todayDate,
            weekAfterDate,
            weekAfterYear,
            weekAfter,
            timeZone,
            todayYear,
            anotherToday: today
        })

        let index = weekDayArray.indexOf(todayDay);
        let weekArray = [];

        for (let i = index; i < 7; i++) {
            weekArray.push(weekDayArray[i]);
        }

        for (let i = 0; i < index; i++) {
            weekArray.push(weekDayArray[i]);
        }

        this.setState({
            weekArray: weekArray,
        }, () => {
            const currentDay = moment();
            const currentDayDiff = currentDay.diff(today, 'days');
            this.setState({
                currentDayDiff
            })

            this.setState({
                availableTimes: this.getDates()
            })
        })
        //this.getBookedTimeSlots(moment());
    }

    getDates = () => {
        const { weekArray } = this.state;
        const weekDayArrayMapping = [];
        for (let i = 0; i < 7; i++) {
            const map = {};
            map[weekArray[i]] = this.getamPmArray();
            weekDayArrayMapping.push(map);
        }
        console.log('dates ' + JSON.stringify(weekDayArrayMapping));
        return weekDayArrayMapping;
    }

    getTime = (amOrpm) => {
        const amOrpmArray = [];
        amOrpmArray.push(12 + ':00' + amOrpm);
        amOrpmArray.push(12 + ':30' + amOrpm);
        for (let i = 1; i < 12; i++) {
            amOrpmArray.push(i + ':00' + amOrpm);
            amOrpmArray.push(i + ':30' + amOrpm);
        }
        return amOrpmArray;
    }

    getamPmArray = () => {
        const amArray = this.getTime('AM');
        const pmArray = this.getTime('PM');
        const amPmArray = amArray.concat(pmArray);
        this.setState({
            amPmArray
        })
        return amPmArray;
    }

    bringNextWeek = () => {
        let { weekAfterDate, weekAfter, today, anotherToday } = this.state;
        let weekAfterClone = Object.assign(weekAfter);

        this.setState({
            todayDate: weekAfterDate
        })

        let nextWeekAfter = Object.assign(moment(weekAfter).add(7, 'days'));

        let nextWeekAfterArray = nextWeekAfter.toString().split(" ");
        //let nextWeekAfterDay = nextWeekAfterArray[0];
        let nextWeekAfterDate = nextWeekAfterArray[1] + " " + nextWeekAfterArray[2];
        let nextWeekAfterYear = nextWeekAfterArray[3];

        this.setState({
            weekAfterDate: nextWeekAfterDate,
            weekAfterYear: nextWeekAfterYear,
            today: weekAfterClone,
            weekAfter: nextWeekAfter,
        }, () => {
            // let weekStartDate = Object.assign(new Date(weekAfter.format('MM/DD/YYYY')));
            // let weekEndDate = Object.assign(new Date(weekAfter.add(7, 'days').format('MM/DD/YYYY')));
            //this.getBookedTimeSlots(weekStartDate, weekEndDate);
            //this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = weekAfter.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    bringPreviousWeek = () => {
        let { todayDate, today, todayYear, anotherToday } = this.state;
        let beforeWeek = null;
        let todayClone = Object.assign(today);
        //need to fix today wrong val
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            this.setState({
                today: moment(today).add(-7, 'days')
            })
            todayClone = Object.assign(moment(today).add(-7, 'days'));
        }

        //let todayClone = Object.assign(today);
        //today = moment(today).add(-7, 'days');
        if (this.state.weekAfter.toString() === this.state.today.toString()) {
            beforeWeek = Object.assign(moment(today).add(-14, 'days'));
        } else {
            beforeWeek = Object.assign(moment(today).add(-7, 'days'));
        }

        let beforeWeekArray = beforeWeek.toString().split(" ");
        let beforeWeekDate = beforeWeekArray[1] + " " + beforeWeekArray[2];

        this.setState({
            todayDate: beforeWeekDate,
            weekAfterDate: todayDate,
            weekAfterYear: todayYear,
            weekAfter: todayClone,
            today: beforeWeek,
        }, () => {
            //this.getBookedTimeSlots(this.state.today);
            const currentDay = moment();
            const currentDayDiff = beforeWeek.diff(currentDay, 'days');
            this.setState({
                currentDayDiff
            })
            this.setWeeklyTimesAndDays();
        })
    }

    handle24HourButton = () => {
        let { twentyFourHourClick } = this.state;
        twentyFourHourClick = !twentyFourHourClick;
        this.setState({
            twentyFourHourClick,
            //selectedTimes: []
        })

        if (twentyFourHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false
            })
        }
    }

    handle10HourButton = () => {
        let { tenHourClick } = this.state;
        tenHourClick = !tenHourClick;
        this.setState({
            tenHourClick,
            //selectedTimes: []
        })

        if (tenHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle5HourButton = () => {
        let { fiveHourClick } = this.state;
        fiveHourClick = !fiveHourClick;
        this.setState({
            fiveHourClick,
            //selectedTimes: []
        })

        if (fiveHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle3HourButton = () => {
        let { threeHourClick } = this.state;
        threeHourClick = !threeHourClick;
        this.setState({
            threeHourClick,
            //selectedTimes: []
        })

        if (threeHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                twoHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle2HourButton = () => {
        let { twoHourClick, } = this.state;
        twoHourClick = !twoHourClick;
        this.setState({
            twoHourClick,
            //selectedTimes: []
        })

        if (twoHourClick) {
            this.setState({
                min30Click: false,
                oneHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle1HourButton = () => {
        let { oneHourClick } = this.state;
        oneHourClick = !oneHourClick;
        this.setState({
            oneHourClick,
            //selectedTimes: []
        })

        if (oneHourClick) {
            this.setState({
                min30Click: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    handle30MinButton = () => {
        let { min30Click } = this.state;
        min30Click = !min30Click;
        this.setState({
            min30Click,
            //selectedTimes: []
        })

        if (min30Click) {
            this.setState({
                oneHourClick: false,
                twoHourClick: false,
                threeHourClick: false,
                fiveHourClick: false,
                tenHourClick: false,
                twentyFourHourClick: false
            })
        }
    }

    setWeeklyTimesAndDays = () => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        const currentDay = moment();
        let currentDayDiff = today.diff(currentDay, 'days');
        currentDayDiff = currentDayDiff === 0 ? currentDayDiff : currentDayDiff + 1;

        this.setState({
            selectedWeeklyDays: selectedWeekDays.slice(currentDayDiff, currentDayDiff + 7),
            selectedWeeklyTimes: selectedTimes.slice(currentDayDiff, currentDayDiff + 7),
        })
    }

    handleTimeSelection = (e) => {
        let { selectedWeekDays, selectedTimes, selectedTimesArray, today, selectedWeeklyDays, selectedWeeklyTimes } = this.state;

        this.setState({
            bookDate: false
        })
        const timeValArray = e.target.value.split("-");
        const selectedWeekDay = timeValArray[0];

        //let index = selectedWeekDays && selectedWeekDays.indexOf(selectedWeekDay);
        //let addDaysToday = moment(today).add(index, 'days');
        let index = parseInt(timeValArray[2]);

        const currentDay = moment();
        const currentDayDiff = today.diff(currentDay, 'days');

        if (currentDayDiff >= 6) {
            index = currentDayDiff + index + 1;
        }

        if (selectedTimes[index]) {
            selectedTimesArray = selectedTimes[index];
        } else {
            selectedTimesArray = [];
            selectedWeekDays[index] = selectedWeekDay;
        }

        const time = timeValArray[1];
        let { oneHourClick, amPmArray, twoHourClick, threeHourClick, fiveHourClick, tenHourClick, twentyFourHourClick } = this.state;
        let timeIndex = amPmArray.indexOf(time);

        //let selectedTimesArray = this.state.selectedTimes;

        selectedTimesArray.push(time);
        if (oneHourClick) {
            selectedTimesArray.push(amPmArray[timeIndex + 1]);
        } else if (twoHourClick) {
            for (let i = 1; i < 4; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (threeHourClick) {
            for (let i = 1; i < 6; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (fiveHourClick) {
            for (let i = 1; i < 10; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (tenHourClick) {
            for (let i = 1; i < 20; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else if (twentyFourHourClick) {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        } else {
            for (let i = 1; i < 48; i++) {
                selectedTimesArray.push(amPmArray[timeIndex + i]);
            }
        }

        selectedTimes[index] = selectedTimesArray;
        selectedWeeklyTimes[index % 7] = selectedTimesArray;
        selectedWeeklyDays[index % 7] = selectedWeekDay;

        this.setState({
            selectedTimes,
            selectedWeekDays,
            selectedWeeklyTimes,
            selectedWeeklyDays,
        })
    }

    bookDate = () => {
        let { bookDate, selectedTimes, today, weekArray, selectedWeekDays } = this.state;
        bookDate = !bookDate;

        if (bookDate) {
            this.setState({
                cancelDate: false,
                dateBookingSuccessful: false,
            })
        }

        //save teacher available dates and times
        for (let i = 0; i < selectedWeekDays.length; i++) {
            if (selectedTimes && selectedTimes[i] && selectedTimes[i].length > 0) {
                const currentDay = moment();
                const currentDayDiff = today.diff(currentDay, 'days');

                let index = weekArray.indexOf(selectedWeekDays[i]);
                let bookedDateNoFormat = moment(today).add(index, 'days');
                let bookedDate = new Date(currentDay.add(i, 'days').format('MM/DD/YYYY'));

                let dateArray = bookedDateNoFormat.toString().split(" ");
                let dateBookingMessage = dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + " at " + selectedTimes[0];

                this.setState({
                    bookDate,
                    dateBookingMessage
                })

                const teacherData = {
                    teacherName: cookies.get("name"),
                    teacherEmail: cookies.get("email"),
                    createdDate: new Date(),
                    availableDate: bookedDate,
                    availableTimes: selectedTimes[i],
                    accessToken: cookies.get("access-token"),
                }

                axios.post(`${URL_PREFIX()}/datepracticals/saveTeacherAvailableDates`, teacherData)
                    .then(res => this.handleBookDateResponse(res))
                    .catch(error => console.log(error));
            }
        }
    }

    handleBookDateResponse = (res) => {
        let { username, password, lastname, videoLink, country, birthday, aboutYou, priceSlider, pictures,
            bookDate, cancelDate, selectedTimes, selectedWeekDay, today, weekArray, dateBookingSuccessful, selectedTeacher, email, firstname, teacherEmail, selectedWeekDays } = this.state;
        this.setState({
            dateBookingSuccessful: true,
            bookDate: true
        })
        // this.props.navigate("/home", {
        //     state: {
        //         username: firstname,
        //         userEmail: email,
        //         isloginOrSignUpPage: false
        //     }
        // });
    }

    cancelDate = () => {
        let { bookDate, cancelDate, open, dateBookingSuccessful, selectedTimes } = this.state;

        cancelDate = !cancelDate;
        open = false;

        if (cancelDate) {
            this.setState({
                bookDate: false,
                dateBookingSuccessful: false,
                selectedTimes: []
            })
        }

        this.setState({
            cancelDate,
            open
        })
    }

    handleOpen = (e) => {
        this.setState({
            open: true,
            dateBookingSuccessful: false,
            selectedTimes: [],
            bookDate: false,
            cancelDate: false,
            selectedTeacher: JSON.parse(e.target.value)
        }, () => {
            this.getInitialValues();
            // let weekStartDate = new Date(moment().format('MM/DD/YYYY'));
            // let weekEndDate = new Date(moment().add(7, 'days').format('MM/DD/YYYY'));
            this.getBookedTimeSlots(moment());
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    getBookedTimeSlots = (weekStartDate) => {
        // if (index >= 0) {
        let { selectedTeacher } = this.state;

        const data = {
            email: selectedTeacher.email,
            //bookedDate: bookedDate,
            weekStartDate: new Date(moment(weekStartDate).format('MM/DD/YYYY')),
            weekEndDate: new Date(moment(weekStartDate).add(7, 'days').format('MM/DD/YYYY'))
        }

        axios.post(`${URL_PREFIX()}/datepracticals/bookedTimeSlots`, data)
            .then(res => this.handleBookedTimeSlotResponse(res, weekStartDate))
            .catch(error => console.log(error));
        //}
        //return value
    }

    handleBookedTimeSlotResponse = (res, weekStartDate) => {
        let bookedTimeSlotDict = {};
        const bookedTimeSlots = res.data;
        let loopDay = weekStartDate;
        this.setState({
            bookedTimeSlots
        }, () => {
            for (let i = 0; i < 7; i++) {
                bookedTimeSlots && bookedTimeSlots.length > 0 && bookedTimeSlots.map(slot => {
                    if (moment(slot.bookedDate).format('MM/DD/YYYY').toString() === moment(loopDay).format('MM/DD/YYYY').toString()) {
                        if (!bookedTimeSlotDict[i]) {
                            bookedTimeSlotDict[i] = slot.bookedTimes;
                        } else {
                            for (let j = 0; j < slot.bookedTimes.length; j++) {
                                bookedTimeSlotDict[i].push(slot.bookedTimes[j]);
                            }
                        }
                    }
                })
                loopDay = moment(loopDay).add(1, 'days').format('MM/DD/YYYY');
            }
        })

        this.setState({
            bookedTimeSlotDict
        }, () => {
            this.setState({
                availableTimes: this.getDates()
            })
        })
    }

    getAgeYears = () => {
        const { years } = this.state;
        for (let i = 1920; i < 2010; i++) {
            years.push(i);
        }
        this.setState({
            years
        })
    }

    handleChange = (event) => {
        this.setState({ username: event.state.username, password: event.state.password });
    }

    handleResponse = (res) => {
        console.log('handleResponse hit');
        //const { handleNameChange } = this.props;
        // const { data } = res;
        const { firstname, password, email, selectedWeekDays, selectedTimes, today, weekArray, bookDate } = this.state;
        const { navigate } = this.props;
        //handleNameChange(firstname);
        cookies.set('username', firstname);

        this.setState({
            firstname: firstname,
            password: password
        });

        cookies.set("access-token", res?.data?.authToken, {
            maxAge: 60 * 60 * 24 * 30 * 1000,
        });
        cookies.set('username', firstname);
        cookies.set('email', email);
        cookies.set('rater', res?.data?.rater);
    }

    handleCloseAvailableSlots = () => {
        const { setAvailableSlots } = this.props;
        setAvailableSlots(false);
    }

    render() {
        const { years, age, country, countries, birthday, userEmail, openUpload, screenVal, email, selectedWeeklyDays, selectedWeeklyTimes,
            review, openLogin, bioReview, open, weekArray, weekDayArrayMapping, availableTimes, fiveHourClick, threeHourClick, tenHourClick, selectedWeekDays, twentyFourHourClick,
            today, todayDate, weekAfterDate, weekAfterYear, timeZone, currentDayDiff, oneHourClick, min30Click, selectedTimes, selectedWeekDay, bookDate, cancelDate, dateBookingSuccessful,
            reviewOpen, reviewSliderVal, reviewPostingSuccessful, reviewsOpen, reviewsArray, videoOpen, ratersList, ratersArray, videoUrl, dateBookingMessage, bookedTimeSlots, bookedTimeSlotDict,
            twoHourClick } = this.state;
        const { openAvailableSlots } = this.props;
        return (
            <div>
                <Grid container spacing={0} justify="center" direction="row">
                    <Grid container style={this.isMobile ? { marginTop: '0%' } : { marginTop: '0%' }}>
                        <Grid item xs={1} sm={1} md={0} lg={0}>
                        </Grid>
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={openAvailableSlots}
                            onClose={this.handleCloseAvailableSlots}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{ width: '100%' }}
                            classes={{ paperFullWidth: { 'max-width': '80%' } }}
                        >
                            <DialogTitle>
                                <Grid container spacing={1}>
                                    <Grid item xs={11}>
                                        <Grid container>
                                            <Grid item>
                                                Book your available slots
                                            </Grid>
                                            {dateBookingSuccessful ?
                                                <Grid item>
                                                    <Alert severity="success" style={{ backgroundColor: '#A3E4D7', marginLeft: '100px' }}>
                                                        Available slots booked successfully.
                                                    </Alert>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={this.handleCloseAvailableSlots}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={5} lg={5}>
                                        <Stack direction="row" spacing={2} style={!dateBookingSuccessful ? { marginTop: '15px' } : null}>
                                            <Button variant="outlined" onClick={this.bringPreviousWeek} disabled={currentDayDiff <= 0}>
                                                {"<"}
                                            </Button>
                                            <span style={{ marginLeft: '8px', fontWeight: 'bold', }}>
                                                {todayDate + " - " + weekAfterDate + ", " + weekAfterYear}
                                            </span>
                                            <Button variant="outlined" onClick={this.bringNextWeek} style={{ marginLeft: '8px' }}>
                                                {">"}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={twentyFourHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle24HourButton}>
                                            {"24hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={tenHourClick ? TenHourClickButtonStyleEnable : TenHourClickButtonStyleDisable} onClick={this.handle10HourButton}>
                                            {"10hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={fiveHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle5HourButton}>
                                            {"5 hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={threeHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle3HourButton}>
                                            {"3 hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={twoHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle2HourButton}>
                                            {"2 hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        <Button variant="outlined" style={oneHourClick ? oneHourClickButtonStyleEnable : oneHourClickButtonStyleDisable} onClick={this.handle1HourButton}>
                                            {"1 hour"}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ marginTop: '2%', marginRight: '3px' }}>
                                        {/* <Button variant="outlined" style={min30Click ? min30ClickButtonStyleEnable : min30ClickButtonStyleDisable} onClick={this.handle30MinButton}>
                                                    {"30 min"}
                                                </Button> */}
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Stack direction="row" spacing={2}>
                                    {availableTimes && availableTimes.map((i, index) => (
                                        <React.Fragment>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        {weekArray[index]}
                                                    </Item>
                                                </Grid>
                                                <br />
                                                {i[weekArray[index]].map((time, ind) => (
                                                    <>
                                                        <span style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Grid item xs={12}>
                                                                <br />
                                                                <Button variant="outlined" color='secondary' style={selectedWeeklyTimes && selectedWeeklyTimes.length > 0 && selectedWeeklyTimes[index] && (selectedWeeklyTimes[index].indexOf(time) > -1
                                                                    && selectedWeeklyDays && selectedWeeklyDays.length > 0 && selectedWeeklyDays[index] && selectedWeeklyDays[index] === weekArray[index]) ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal}
                                                                    onClick={this.handleTimeSelection} value={weekArray[index] + "-" + time + "-" + index}>
                                                                    {time}
                                                                </Button>
                                                            </Grid>
                                                        </span>
                                                    </>
                                                ))}
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    }
                                </Stack>
                            </DialogContent>

                            <DialogActions>
                                <Grid container spacing={1}>
                                    <Grid item xs={4} sm={5} md={5} lg={5}>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={1} lg={1}>
                                        <Button variant="outlined" style={bookDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.bookDate}>Book</Button>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={1} lg={1} style={{ marginLeft: '0.3em' }}>
                                        <Button variant="outlined" style={cancelDate ? oneHourOr30MinTimeSelectStyleEnable : oneHourOr30MinTimeSelectStyleNormal} onClick={this.cancelDate}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </div >
        );
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <TeacherAddDates {...props} navigate={navigate} />;
}
//export default withStyles(styles)(SignUp);